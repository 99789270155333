const userOptions = [
  {
    id: 'Profile',
    title: 'Profile',
    path: '/profile',
  },

  {
    id: 'Transactions',
    title: 'Transactions',
    path: '/transactions',
  },
  {
    id: 'Resources',
    title: 'Support',
    path: '/resources',
  },
  {
    id: 'Settings',
    title: 'Settings',
    path: '/settings',
  },
];
export default userOptions;
