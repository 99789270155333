import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams, withRouter } from 'react-router-dom';
import userOptions from './tabViewData';
import styles from './tabView.module.scss';

import ProfileComponent from '../../components/profile/profile.component';
import ResourcesComponent from '../../components/resources/resources.component';
import TransactionsComponent from '../../components/transactions/transactions.component';
import SettingsComponent from '../../components/settings/settings.component';
import { isMobile } from '../../utils';

const TabViewPage = ({ location }) => {
  const params = useParams();

  const TabViewPageData = {
    profile: <ProfileComponent />,
    settings: <SettingsComponent />,
    resources: <ResourcesComponent />,
    transactions: <TransactionsComponent />,
  };
  return (
    <>
      <div className="container">
        {!isMobile && (
          <div className="row">
            <ul className={`col-md-12 ${styles['user-menu']}`}>
              {userOptions.map((e) => (
                <li className={styles['user-item']} key={e.id}>
                  {e.path !== '/resources' ? (
                    <NavLink
                      to={e.path}
                      activeStyle={{ fontWeight: 'bold' }}
                      className={styles['user-links']}>
                      {e.title}
                    </NavLink>
                  ) : (
                    <a
                      href="https://gamitar.freshdesk.com/support/home"
                      rel="noreferrer"
                      target="_blank"
                      className={styles['user-links']}>
                      {e.title}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {TabViewPageData[location.pathname.substring(1)]}
    </>
  );
};
TabViewPage.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default withRouter(TabViewPage);
