/* eslint-disable */

import React from 'react';
import GamitarLogo from '../../assets/bluepurple-evivve-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Profilepic from '../../assets/user/profile.png';

import styles from './certificationDetails.module.scss';
import { isMobile } from '../../utils';

const CertificationDetailsComponent = ({ username, userAvatar, certifyDate }) => {
  return (
    <>
      <div className={`row ${styles['certification-details']}`}>
        <div className="col-md-3 col-sm-12">
          <div style={{ display: 'flex' }}>
            <img
              style={{ objectFit: 'contain' }}
              className={styles['user-image']}
              src={userAvatar ? userAvatar : Profilepic}
            />
            {/* <img className={styles['tick-icon']} src={CircleIcon} /> */}
            <FontAwesomeIcon icon={faCircleCheck} className={styles['tick-icon']} />
          </div>
        </div>
        <div className="col-md-9 col-sm-12">
          <h5 className={styles['heading-username']}>Completed by {username}</h5>
          <div className={styles['small-text']}>
            <p>{certifyDate}</p>
            <p className={styles['certificate-date']}>4 week | 3-5 hours/week</p>
          </div>
          <p className={styles['certificate-desc']}>
            This account is verified. Gamitar certifies this individual's successful completion of
            Multiplayer Online Game-Based Learning (MOGL) : Evivve Certification.
          </p>
        </div>
      </div>
      <div className={`row ${styles['entrolnow-section']}`}>
        <div className="col-md-3 col-sm-12">
          <img
            className={styles['navigation-bar-logo']}
            style={{ width: '100%',objectFit:"contain" }}
            src={GamitarLogo}
            alt="gamitar-logo"
          />
        </div>
        <div className="col-md-9 col-sm-12 d-flex ">
          <div className="row">
            <div className={`col-md-8 text-center ${styles['enroll-text']}`}>
              <p>Multiplayer Online Game-Based</p>
              <p className={styles['learning-text']}>Learning: Evivve</p>
            </div>
            <div
              style={{
                display: isMobile && 'flex',
                justifyContent: isMobile && 'center',
              }}
              className={`col-md-4  ${styles['enroll-text']}`}>
              <button
                onClick={() => window.open('https://www.gamitar.com/mogl-evivve-certification')}
                className={`btn ${styles['enroll-now-btn']}`}>
                Enroll Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles['skils-section']}>
        <p>SKILLS YOU WILL GAIN</p>
        <div className="row container">
          <div className="col-md-4 col-sm-12">
            <p className={styles['skill-name']}>MOGL Facilitation</p>
          </div>

          <div className="col-md-4 col-sm-12">
            <p className={styles['skill-name']}>Virtual Facilitation</p>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className={styles['skill-name']}>Game-Based Learning</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificationDetailsComponent;
