/* eslint-disable max-len */
/* eslint-disable */
import { useState, useCallback, useEffect } from 'react';


export const UserTypeEnum = Object.freeze({
  ADMIN: 'ADMIN',
  USER: 'USER',
});

export const GameAccessEnum = Object.freeze({
  FULL: 8,
  HALF: 3,
});

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const handler = useCallback(
    () =>
      setTimeout(() => {
        setDebouncedValue(value);
      }, delay),
    [delay, value]
  );

  useEffect(() => {
    handler();

    return () => {
      clearTimeout(handler());
    };
  }, [handler]);

  return debouncedValue;
}

export const DateFunction = (currentDate, Gstdate) => {
  const Conert = (str) => {
    const date = new Date(str);
    const mnth = `0 ${date.getMonth() + 1}`.slice(-2);
    const day = `0 ${date.getDate()}`.slice(-2);
    return [date.getFullYear(), mnth, day].join(`-`);
  };
  const finalDate = Conert(currentDate);
  const firstDate = Conert(Gstdate);
  const oneDay = 24 * 60 * 60 * 1000;
  const difDays = Math.round(Math.abs((new Date(finalDate) - new Date(firstDate)) / oneDay));
  return difDays;
};

export const validateEmail = (email) => {
  const inputType = typeof email;
  const newEmail = inputType === 'string' ? email : email.email;
  return String(newEmail)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const stateLists = [
  {
    label: 'Andhra Pradesh',
    value: 'Andhra Pradesh',
  },
  {
    label: 'Arunachal Pradesh',
    value: 'Arunachal Pradesh',
  },
  {
    label: 'Assam',
    value: 'Assam',
  },
  {
    label: 'Bihar',
    value: 'Bihar',
  },
  {
    label: 'Chhattisgarh',
    value: 'Chhattisgarh',
  },
  {
    label: 'Goa',
    value: 'Goa',
  },
  {
    label: 'Gujarat',
    value: 'Gujarat',
  },
  {
    label: 'Haryana',
    value: 'Haryana',
  },
  {
    label: 'Himachal Pradesh',
    value: 'Himachal Pradesh',
  },
  {
    label: 'Jharkhand',
    value: 'Jharkhand',
  },
  {
    label: 'Karnataka',
    value: 'Karnataka',
  },
  {
    label: 'Kerala',
    value: 'Kerala',
  },
  {
    label: 'Madhya Pradesh',
    value: 'Madhya Pradesh',
  },
  {
    label: 'Maharashtra',
    value: 'Maharashtra',
  },
  {
    label: 'Manipur',
    value: 'Manipur',
  },
  {
    label: 'Meghalaya',
    value: 'Meghalaya',
  },
  {
    label: 'Mizoram',
    value: 'Mizoram',
  },
  {
    label: 'Nagaland',
    value: 'Nagaland',
  },
  {
    label: 'Odisha',
    value: 'Odisha',
  },
  {
    label: 'Punjab',
    value: 'Punjab',
  },
  {
    label: 'Rajasthan',
    value: 'Rajasthan',
  },
  {
    label: 'Sikkim',
    value: 'Sikkim',
  },
  {
    label: 'Tamil Nadu',
    value: 'Tamil Nadu',
  },
  {
    label: 'Telangana',
    value: 'Telangana',
  },
  {
    label: 'Tripura',
    value: 'Tripura',
  },
  {
    label: 'Uttar Pradesh',
    value: 'Uttar Pradesh',
  },
  {
    label: 'Uttarakhand',
    value: 'Uttarakhand',
  },
  {
    label: 'West Bengal',
    value: 'West Bengal',
  },
  {
    label: 'Andaman and Nicobar Islands',
    value: 'Andaman and Nicobar Islands',
  },
  {
    label: 'Chandigarh',
    value: 'Chandigarh',
  },
  {
    label: 'Dadra & Nagar Haveli and Daman & Diu',
    value: 'Dadra & Nagar Haveli and Daman & Diu',
  },
  {
    label: 'Delhi',
    value: 'Delhi',
  },
  {
    label: 'Jammu and Kashmir',
    value: 'Jammu and Kashmir',
  },
  {
    label: 'Lakshadweep',
    value: 'Lakshadweep',
  },
  {
    label: 'Puducherry',
    value: 'Puducherry',
  },
  {
    label: 'Ladakh',
    value: 'Ladakh',
  },
];
export let weekObject = {
  'Week 1': 0,
  'Week 2': 0,
  'Week 3': 0,
  'Week 4': 0,
  'Week 5': 0,
  'Week 6': 0,
  'Week 7': 0,
  'Week 8': 0,
  'Week 9': 0,
  'Week 10': 0,
  'Week 11': 0,
  'Week 12': 0,
  'Week 13': 0,
  'Week 14': 0,
  'Week 15': 0,
  'Week 16': 0,
  'Week 17': 0,
  'Week 18': 0,
  'Week 19': 0,
  'Week 20': 0,
  'Week 21': 0,
  'Week 22': 0,
  'Week 23': 0,
  'Week 24': 0,
  'Week 25': 0,
  'Week 26': 0,
  'Week 27': 0,
  'Week 28': 0,
  'Week 29': 0,
  'Week 30': 0,
  'Week 31': 0,
  'Week 32': 0,
  'Week 33': 0,
  'Week 34': 0,
  'Week 35': 0,
  'Week 36': 0,
  'Week 37': 0,
  'Week 38': 0,
  'Week 39': 0,
  'Week 40': 0,
  'Week 41': 0,
  'Week 42': 0,
  'Week 43': 0,
  'Week 44': 0,
  'Week 45': 0,
  'Week 46': 0,
  'Week 47': 0,
  'Week 48': 0,
  'Week 49': 0,
  'Week 50': 0,
  'Week 51': 0,
};
export let monthObject = {
  January: 0,
  Feburary: 0,
  March: 0,
  April: 0,
  May: 0,
  June: 0,
  July: 0,
  August: 0,
  September: 0,
  October: 0,
  November: 0,
  December: 0,
};
export const isMobile = window.innerWidth < 556;
