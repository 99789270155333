import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from '../../utils';

const AddProgramDetails = ({ h5, h6, input, p, pl, toggle, pr, warning }) => {
  return (
    <>
      <h5 className="card-title font-weight-bold">{h5}</h5>
      <h6 className="card-subtitle pt-3 mb-2 font-weight-bold">{h6}</h6>
      <div className="row">
        {input && <div className="col-12 col-md-8">{input}</div>}
        {p && <div className="col-12 col-md-4">{p}</div>}
        {pl && <div className="col-sm-12 col-md-3 mt-4">{pl}</div>}
        {toggle && (
          <div
            className="col-md col-sm-4 "
            style={
              isMobile
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                    marginLeft: -70,
                  }
                : {}
            }>
            {toggle}
          </div>
        )}
        {pr && (
          <div className="col-sm-12 col-md-3 mt-4" style={isMobile ? { paddingTop: 60 } : {}}>
            {pr}
          </div>
        )}
        {warning && <div className="col-12 col-md-4">{warning}</div>}
      </div>
    </>
  );
};

AddProgramDetails.propTypes = {
  h5: PropTypes.string,
  h6: PropTypes.string,
  input: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  p: PropTypes.string,
  toggle: PropTypes.oneOfType([PropTypes.node]),
  pl: PropTypes.oneOfType([PropTypes.node]),
  pr: PropTypes.oneOfType([PropTypes.node]),
  warning: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};
AddProgramDetails.defaultProps = {
  h5: '',
  h6: '',
  input: '',
  p: '',
  toggle: '',
  pl: '',
  pr: '',
  warning: '',
};

export default AddProgramDetails;
