import addNewProgram from '../../assets/navbar/addition.png';
import allMyPrograms from '../../assets/navbar/programs.png';
import resources from '../../assets/navbar/resources.png';
import transactions from '../../assets/navbar/transactions.png';
import settings from '../../assets/navbar/settings.png';
import signout from '../../assets/navbar/signout.png';
import userProfile from '../../assets/navbar/userProfile.png';
import Roadmap from '../../assets/navbar/roadmap.png';
import community from '../../assets/navbar/community.png';

export const dropdownArray = [
  {
    id: 'Add new program',
    title: 'Create Program',
    path: '/design/add/new',
    cName: 'dropdn-link-program',
    src: addNewProgram,
  },
  {
    id: 'All my programs',
    title: 'All Programs',
    path: '/my-programs',
    cName: 'dropdn-link-program',
    src: allMyPrograms,
  },
];

export const userDropdownArray = [
  {
    id: 'My Profile',
    title: 'My Profile',
    path: '/profile',
    cName: 'dropdn-link',
    src: userProfile,
  },

  {
    id: 'Roadmap',
    title: 'Evivve Roadmap',
    path: '/roadmap',
    cName: 'dropdn-link',
    src: Roadmap,
  },
  {
    id: 'Community',
    title: 'Community',
    path: '/community',
    cName: 'dropdn-link',
    src: community,
  },
  {
    id: 'Transactions',
    title: 'Transactions',
    path: '/transactions',
    cName: 'dropdn-link',
    src: transactions,
  },
  {
    id: 'Resources',
    title: 'Support',
    path: '/resources',
    cName: 'dropdn-link',
    src: resources,
  },
  { id: 'Settings', title: 'Settings', path: '/settings', cName: 'dropdn-link', src: settings },

  { id: 'Sign Out', title: 'Sign Out', path: '/signout', cName: 'dropdn-link', src: signout },
];

export const navLinksArray = [
  { name: 'Who We Are', url: 'https://evivve.com/the-designer' },
  { name: 'What We Do', url: 'https://evivve.com/the-company' },
  { name: 'Blog', url: 'https://evivve.com/evivvo-pedia' },
  { name: 'Support', url: 'https://gamitar.freshdesk.com/support/home' },

];
export const navbarData = [
  {
    id: 'My Program',
    heading: 'My Program',
    content: dropdownArray,
  },
  {
    id: 'user',
    img: userProfile,
    heading: '',
    content: userDropdownArray,
  },
];
