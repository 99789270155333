import React from 'react';
import styles from './resources.module.scss';
import TitleComponent from '../title/title.component';
import CardComponent from '../card/card.component';
import SearchResources from '../../assets/user/search.png';

const ResourcesComponent = () => {
  return (
    <>
      <div className={`w-75 pt-3 ${styles.title}`}>
        <TitleComponent title="Home/ Resources" />
      </div>
      <div className="container">
        <CardComponent>
          <>
            <div className="container">
              <div className="row">
                <div className={`col-11 ${styles.input}`}>
                  <img src={SearchResources} alt="search-icon" />

                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail"
                    placeholder="Search Resources"
                  />
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-2 pt-2">
                  <h6>Filter with tags: </h6>
                </div>
                <div className="col-md-3">
                  <button type="submit" className={`btn ${styles['filter-tags']}`}>
                    Technology
                  </button>
                </div>
                <div className="col-md-3">
                  <button type="submit" className={`btn ${styles['filter-tags']}`}>
                    Facilitation
                  </button>
                </div>
                <div className="col-md-3">
                  <button type="submit" className={`btn ${styles['filter-tags']}`}>
                    Marketing
                  </button>
                </div>
              </div>

              <hr className="mt-2 mb-3" />
              <p>Recently Added Resources</p>
              <h6>
                <strong>Title of the Resource</strong>
              </h6>
              <p>Description of the resource</p>
            </div>
          </>
        </CardComponent>
      </div>
    </>
  );
};
export default ResourcesComponent;
