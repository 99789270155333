import React, { useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import PropTypes from 'prop-types';
// import styles from './image.component.scss';
// import styles from './image.component.scss';
import styles from '../../pages/auth/auth.module.scss';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropper = ({ imgSrc, handleModal, handlePicUpload, modalIsOpen }) => {
  console.log('Dd', styles.modal);
  const [Crop, setCrop] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imgRef, setImgRef] = useState(null);

  const [aspect, setAspect] = useState(1);
  const [image, setImage] = useState(null);

  const makeClientCrop = async (crop) => {
    const getCroppedImg = (sourceImage, fileName) => {
      const canvas = document.createElement('canvas');
      const scaleX = sourceImage.naturalWidth / sourceImage.width;
      const scaleY = sourceImage.naturalHeight / sourceImage.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        sourceImage,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      try {
        return new Promise((resolve) => {
          canvas.toBlob((file) => {
            console.log('blob');
            resolve(file);
          }, 'image/jpeg');
        });
      } catch (error) {
        console.log(error);
        return null;
      }
    };
    if ((image, crop.width && crop.height)) {
      const croppedImg = await getCroppedImg(document.getElementById('pic'), 'newFile.jpeg');
      console.log('malam', croppedImg);
      setCroppedImageUrl(croppedImg);
    }
  };

  return (
    <div>
      <div className={styles.modal} style={{}}>
        {imgSrc && (
          <ReactCrop
            crop={Crop}
            ruleOfThirds
            onChange={(cropData) => setCrop(cropData)}
            onComplete={(crops) => (image ? makeClientCrop(crops) : console.log('wait'))}
            aspect={aspect}>
            <img
              id="pic"
              alt="Crop me"
              src={imgSrc}
              onLoad={(img) => {
                console.log('darshan', img);
                setImage(img);
              }}
            />
          </ReactCrop>
        )}
        <button
          type="button"
          disabled={!croppedImageUrl}
          className="btn btn-secondary "
          onClick={() => {
            handlePicUpload(croppedImageUrl);
          }}>
          Crop Image
        </button>
      </div>
    </div>
  );
};

ImageCropper.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,

  handlePicUpload: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default ImageCropper;
