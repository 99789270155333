/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AddProgramDetails from '../../components/addProgramDetails/addProgramDetails.component';
import CardComponent from '../../components/card/card.component';
import CardFooterComponent from '../../components/container-buttons/cardFooter.component';
import Counter from '../../components/counter/counter';
import TitleComponent from '../../components/title/title.component';
import Loader from '../../components/Loader';
import { useGetProgramsList } from '../../services';
import { useProgramStore, useUserStore } from '../../store';
import { GameAccessEnum } from '../../utils';
import styles from './design.module.scss';

const DesignPage = () => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [gameAccess, setGameAccess] = useState(false);
  const [num, setNum] = useState(1);
  const [emailRequired, setEmailRequired] = useState(false);
  const [required, setRequired] = useState(false);

  const query = useGetProgramsList();

  const setProgram = useProgramStore((s) => s.setProgram);
  const program = useProgramStore((s) => s.program);
  const allProgramsList = useProgramStore((s) => s.allPrograms);
  const { id, action } = useParams();
  // if (!query.isLoading) {
  const programToEdit = allProgramsList.filter((prog) => prog.id === id);

  // }
  console.log("action",action)
  useEffect(() => {
    if (action === 'edit') {
      if (programToEdit[0]?.name) {
        setName(programToEdit[0].name);
      }

      if (programToEdit[0]?.description) {
        setDescription(programToEdit[0].description);
      }

      if (programToEdit[0]?.fac_count > 1) {
        setNum(programToEdit[0].fac_count);
      }

      if (programToEdit[0]?.game_access) {
        setGameAccess(programToEdit[0].game_access === GameAccessEnum.FULL ? true : false);
      }
    } else {
      if (program.name) {
        setName(program.name);
      }

      if (program.description) {
        setDescription(program.description);
      }

      if (program.fac_count > 1) {
        setNum(program.fac_count);
      }

      if (program.game_access) {
        setGameAccess(program.game_access === GameAccessEnum.FULL ? true : false);
      }
    }
  }, []);
  useEffect(() => {
    if (action === 'edit') {
      console.log("darshan")
      if (programToEdit[0]?.name) {
        setName(programToEdit[0].name);
      }

      if (programToEdit[0]?.description) {
        setDescription(programToEdit[0].description);
      }

      if (programToEdit[0]?.fac_count > 1) {
        setNum(programToEdit[0].fac_count);
      }

      if (programToEdit[0]?.game_access) {
        setGameAccess(programToEdit[0].game_access === GameAccessEnum.FULL ? true : false);
      }
    }
  }, [program, allProgramsList]);

  const incNum = () => {
    setNum(num + 1);
  };

  const decNum = () => {
    if (num > 1) {
      setNum(num - 1);
    } else {
      setNum(1);
    }
  };

  const onPreviousClick = () => {
    history.push('/');
  };

  const onNextClick = () => {
    let validate = true;
    if (name === '') {
      validate = false;
      setEmailRequired(true);
    }
    if (description === '') {
      validate = false;
      setRequired(true);
    }
    if (validate) {
      setProgram({
        name,
        description,
        game_access: gameAccess ? GameAccessEnum.FULL : GameAccessEnum.HALF,
        fac_count: num,
        facilitators: [
          {
            value: useUserStore.getState().user.id,
            label: useUserStore.getState().user.email,
          },
        ],
      });
      if (action === 'edit') {
        history.push(`/schedule/edit/${id}`);
      } else {
        history.push('/schedule/add/new');
      }
    }
  };

  return (
    <>
      {query.isLoading && <Loader />}
      <div className={`w-75 pt-3 ${styles.title}`}>
        <TitleComponent
          title="Home/ New program"
          heading={action === 'edit' ? "Edit" : "Create"}
          subtitle={`${action === 'edit' ? "Edit" : "Add"} details about your program`}
        />
      </div>
      <div className={`container ${styles['box-shadow']}`}>
        <CardComponent>
          {console.log("name",name)}
          <>
            <AddProgramDetails
              h5="Program Details"
              h6="Give your program a name"
              input={
                <div>
                  <input
                    type="text"
                    className={`form-control
                     ${emailRequired ? 'border-danger form-control is-invalid' : ''}`}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setEmailRequired(false);
                      }
                      setName(e.target.value);
                    }}
                    value={name}
                  />
                  <div className="invalid-feedback">This field cannot be blank</div>
                </div>
              }
              p="Don’t worry, you can change it anytime later"
            />
            <div className="border" />
            <AddProgramDetails
              h6="Description of the program"
              input={
                <div>
                  <textarea
                    type="text"
                    className={`form-control
                    ${required ? 'border-danger form-control is-invalid' : ''}`}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setRequired(false);
                      }
                      setDescription(e.target.value);
                    }}
                    value={description}
                    id="exampleFormControlTextarea1"
                    rows="3"
                  />
                  <div className="invalid-feedback">This field cannot be blank</div>
                </div>
              }
              p="Describe this program in 140 characters, something like: A program designed for
              Deloitte’s senior management with a focus on Dealing with VUCA. Try to be brief
              but relevant."
            />
            <div className="border" />
            <h6 className="mt-3">
              <strong>WARNING:</strong>
              You cannot change the game access type once you create the program.
            </h6>

            {action === 'edit' ? null : (
              <>
                <AddProgramDetails
                  h6="What kind of game access do you need for this program?"
                  pl={
                    <>
                      <div className="text-center">
                        <strong> HALF-DAY</strong>
                      </div>
                      <div className="text-center">3 hour access</div>
                      <div className="text-center">Good for one round</div>
                    </>
                  }
                  toggle={
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        onChange={() => setGameAccess(!gameAccess)}
                        value={gameAccess}
                        className=" custom-control-input"
                        id="customSwitch8"
                        checked={gameAccess}
                      />
                      <label
                        className={`custom-control-label ${styles['custom-control-label']}`}
                        htmlFor="customSwitch8"
                      />
                    </div>
                  }
                  pr={
                    <>
                      <div className="text-center">
                        <strong>FULL-DAY</strong>
                      </div>
                      <div className="text-center">8 hour access</div>
                      <div className="text-center">Good for 2-3 rounds</div>
                    </>
                  }
                  warning={
                    <>
                      <h6>
                        In creating this Program, you will spend a credit (Half-Day or Full-Day) 
                        depending on the type of access you need. You will need as many credits as 
                        the number of facilitators that will need game access for this program.
                        <br /> <br />
                        An account can run one game at a time. If you want to run multiple games
                        simultaneously, you’ll need accesses on multiple accounts.
                      </h6>
                    </>
                  }
                />
                <AddProgramDetails
                  h6="How many facilitators will need game access
              for this program?"
                />
                <Counter num={num} decNum={decNum} incNum={incNum} />
              </>
            )}

            <div>
              <CardFooterComponent
                previous="Cancel"
                next="Continue"
                onPreviousClick={onPreviousClick}
                onNextClick={onNextClick}
              />
            </div>
          </>
        </CardComponent>
      </div>
    </>
  );
};
DesignPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default DesignPage;
