import React from 'react';

const NotFoundPage = () => {
  return (
    <div> 
      
      <h1>Page not Found</h1>
     
    </div>
);
};

export default NotFoundPage;
