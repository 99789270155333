/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { useSaveUserCertificatImage } from '../../services';
import CertificateTemplate from '../../assets/MOGL_Evivve_Certificates_Template.pdf';
import LinkedInLogo from '../../assets/pngkey.com-linkedin-logo-png-143268.png';

import styles from './pdfCertificate.module.scss';

const PDFJS = window.pdfjsLib;

const PDFCertificateComponent = ({
  userid,
  username,
  certifyDate,
  certificationCode,
  certificate_image_url,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfDoc, setPdfDoc] = useState(CertificateTemplate);
  const [pdfHeight, setPdfHeight] = useState();
  const [pdfhWidth, setPdfWidth] = useState();
  const [shareAbleUrl, setShareAbleUrl] = useState(CertificateTemplate);

  const saveCertificate = useSaveUserCertificatImage();

  useEffect(async () => {
    const existingPdfBytes = await fetch(CertificateTemplate).then((res) => res.arrayBuffer());
    const pdfDocument = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDocument.embedFont(StandardFonts.Helvetica);

    const pages = pdfDocument.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();
    firstPage.drawText(username, {
      x: 45,
      y: height / 9 + 300,
      size: 25,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(certifyDate, {
      x: 45,
      y: height / 6 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
    firstPage.drawText(`Verify at: https://app.evivve.com/certification/${certificationCode}`, {
      x: 366,
      y: height - 482,
      size: 11,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });

    const pdfBytes = await pdfDocument.save();

    setPdfDoc(pdfBytes);
    const readingPDF = await PDFJS.getDocument(pdfBytes);

    var page = await readingPDF.getPage(1);
    var viewport = page.getViewport(1);

    var render_context = {
      canvasContext: document.querySelector('#pdf-canvas').getContext('2d'),
      viewport: viewport,
    };
    setPdfWidth(viewport.width);
    setPdfHeight(viewport.height);
    await page.render(render_context);
    try {
      if (!certificate_image_url) {
        var canvas = document.getElementById('pdf-canvas');
        var img = canvas.toDataURL('image/png');
        let imageFile = await dataUrlToFile(img, 'imageFile');
        const imageDetails = {
          imageFile,
          userid,
        };
        saveCertificate.mutate(imageDetails);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png', accepted: true });
  }

  const handleDownload = () => {
    const file = new Blob([pdfDoc], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    setShareAbleUrl(fileURL);
    window.open(fileURL, '_blank');
  };

  return (
    <>
      <div>
        <canvas id="pdf-canvas" width={pdfhWidth} height={pdfHeight} style={{ display: 'none' }} />
        <Document file={{ data: pdfDoc }} className={styles['pdfStyles']}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <div className={styles['download-section']}>
        <button onClick={handleDownload} className={`btn ${styles['download-button']}`}>
          Download Certificate
        </button>

        <div
          className={styles['share-section']}
          onClick={() =>
            window.open(
              `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`
            )
          }>
          <p>Share on</p>
          <img src={LinkedInLogo} className={styles['linked-in-logo']} height="26px" width="90px" />
        </div>
      </div>
    </>
  );
};
export default PDFCertificateComponent;
