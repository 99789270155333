/* eslint-disable */
import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { isMobile, stateLists } from '../../utils';
import axiosClient from '../../utils/axiosClient';
import { GetGstDetails } from '../../services';
import { useCreditStore } from '../../store';

const GstModal = ({
  openModal,
  setOpenModal,
  showBuyCredit,
  setShowBuyCredit,
  radioInput,
  setRadioInput,
  setStateList,
  stateList,
  gstNum,
  setGstNum,
  check,
  setCheck,
  date,
  setDate,
}) => {
  const getGst = GetGstDetails();
  const gstDetails = async () => {
    getGst.mutate({
      invoice_type: radioInput,
      gst_no: gstNum,
      state_list: radioInput === 'gst' ? stateList.value : '',
      flag: check,
      gstFlagDate: date,
    });
  };
  const setInvoiceType = useCreditStore((g) => g.setInvoiceType);
  const handleChange = (e) => {
    setRadioInput(e.target.value);
    setInvoiceType('');
  };
  const handleSelectChange = (e) => {
    setStateList(e);
  };
  const handleTextChange = (e) => {
    setGstNum(e.target.value);
  };
  const handleCheckChange = (e) => {
    setCheck(!check);
    setDate(new Date(Date.now()).toString());
  };
  // if (!check) setDate(new Date(Date.now()).toString());
  return (
    <>
      <div
        style={
          openModal
            ? {
                position: 'absolute',
                backgroundColor: '#fff6ea',
                backdropFilter: 'grayscale(0.5) opacity(0.8)',
                boxShadow: 'rgb(0 0 0 / 60%) 0px 2px 12px',
                borderRadius: '10px',
                width: 'fit-content',
                padding: '2rem',
                margin: 10,
                top: '30%',
                ...(!isMobile && {
                  transform: 'translate(-50% ,-50%',
                  width: '900px',
                  left: '50%',
                  top: '50%',
                }),
              }
            : { display: 'none' }
        }>
        <label>
          <input
            type="radio"
            name="letter"
            value="gst"
            // checked={value === 'a'}
            onChange={(e) => handleChange(e)}
            style={{ marginRight: '1rem' }}
          />
          I'm making this purchase in India and I would like a GST invoice for this purchase.
        </label>
        <label>
          <input
            type="radio"
            name="letter"
            value="non-gst"
            // checked={value === 'b'}
            onChange={(e) => handleChange(e)}
            style={{ marginRight: '1rem' }}
          />
          I'm making this purchase outside India and I will not be claiming GST for this
          transaction.
        </label>
        <label>
          <input
            type="checkbox"
            name="checkbox"
            value={check}
            checked={check === true}
            onChange={(e) => handleCheckChange(e)}
            style={{ marginRight: '1rem' }}
          />
          Save this choice for later
        </label>
        {radioInput === 'gst' ? (
          <>
            {' '}
            <div className="col-6" style={{ margin: '0.5rem 0' }}>
              <Select
                placeholder="Select State"
                value={stateList}
                options={stateLists}
                onChange={(e) => handleSelectChange(e)}
                style={{ display: 'block', marginBottom: '3.5rem' }}
              />
            </div>
            <div className="col-6" style={{ margin: '1.1rem 0' }}>
              <input
                type="text"
                name="text"
                className="form-control"
                value={gstNum}
                // checked={value === 'b'}
                placeholder="Enter GST Number"
                onChange={(e) => handleTextChange(e)}
                style={{
                  backgroundColor: 'white',
                  border: '0.5px solid grey',
                  borderRadius: '3px',
                }}
              />
            </div>
          </>
        ) : (
          ''
        )}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginRight: '1rem' }}
            onClick={() => {
              setShowBuyCredit(!showBuyCredit);
              setOpenModal(false);
              gstDetails();
            }}>
            Continue
          </button>
          <button type="button" className="btn btn-secondary" onClick={() => setOpenModal(false)}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};
GstModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  showBuyCredit: PropTypes.bool.isRequired,
  setShowBuyCredit: PropTypes.func.isRequired,
  radioInput: PropTypes.string.isRequired,
  setRadioInput: PropTypes.func.isRequired,
  stateList: PropTypes.string.isRequired,
  setStateList: PropTypes.func.isRequired,
  gstNum: PropTypes.string.isRequired,
  setGstNum: PropTypes.func.isRequired,
  setCheck: PropTypes.func.isRequired,
  check: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
};
export default GstModal;
