/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CreateProgram from '../../assets/home/createprogram.png';
import EditProgram from '../../assets/program/edit.png';
import EditFacilitator from '../../assets/program/editFacilitator.png';
import ProgramInvite from '../../assets/program/programInvite.png';
import GameSettings from '../../assets/program/settings.png';
import Ready from '../../assets/program/ready.png';
import Loader from '../../components/Loader';
import TitleComponent from '../../components/title/title.component';
import { useGetProgramsList } from '../../services';
import { useProgramStore, useUserStore } from '../../store';
import { GameAccessEnum, isMobile } from '../../utils';
import timeZonesList from '../../utils/timezone.json';
import hostGame from '../../assets/hostGame.png';
import styles from './program.module.scss';
import WarnIcon from '../../assets/program/info.png';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

const MyProgramsPage = () => {
  const history = useHistory();

  const options = [
    { value: 'name', label: 'Program name' },
    { value: 'time', label: 'Date' },
    // { value: 'date', label: 'Date' },
    { value: 'access', label: 'Access Type' },
    { value: 'status', label: 'Status' },
  ];
  const [newProgramList, setNewProgramList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(options[1].value);

  const dummyProgramList = [];
  const programsList = useProgramStore((s) => s.programsList);
  const query = useGetProgramsList();
  const user = useUserStore((s) => s.user);
  useEffect(() => {}, [dummyProgramList]);

  const isGameSettingClicked = () => {
    localStorage.setItem('gameSettingClicked', true);
  };

  const betweenTime = ({ date, time, timeZone, gameAccess }) => {
    const tz = timeZonesList.filter((d) => d.text === timeZone)[0]?.utc[0];

    const cd = dayjs().tz(tz, true);

    const beforeThirtyMinutes = dayjs(`${date} ${time}`, 'YYYY-MM-DD hh:mm A')
      .tz(tz, true)
      .subtract(30, 'm');

    const afterThirtyMinutes = dayjs(`${date} ${time}`, 'YYYY-MM-DD hh:mm A')
      .tz(tz, true)
      .add(gameAccess, 'h')
      .add(30, 'm');

    return dayjs(cd).isBetween(beforeThirtyMinutes, afterThirtyMinutes);
  };

  const isSessionPassed = ({ date, time, timeZone, gameAccess }) => {
    const tz = timeZonesList.filter((d) => d.text === timeZone)[0]?.utc[0];

    const cd = dayjs().tz(tz, true);

    const endTimeWithThirtyMinutes = dayjs(`${date} ${time}`, 'YYYY-MM-DD hh:mm A')
      .tz(tz, true)
      .add(gameAccess, 'h')
      .add(30, 'm');
    return dayjs(cd).isAfter(endTimeWithThirtyMinutes);
  };
  const beforeSession = ({ date, time, timeZone, gameAccess }) => {
    const tz = timeZonesList.filter((d) => d.text === timeZone)[0]?.utc[0];

    const cd = dayjs().tz(tz, true);

    const beforeThirtyMinutes = dayjs(`${date} ${time}`, 'YYYY-MM-DD hh:mm A')
      .tz(tz, true)
      .subtract(30, 'm');

    return dayjs(cd).isBefore(beforeThirtyMinutes);
  };
  const handleEditFacilitator = (p) => {
    p = { ...p, facilitators: [] };

    useProgramStore.getState().setProgram(p);
    history.push('/invite/edit');
  };

  const handleProgramStatusAndButtons = (allP, i) => {
    const isIsSessionPassed = isSessionPassed({
      date: allP.date,
      time: allP.time,
      timeZone: allP.timezone,
      gameAccess: allP.game_access,
    });

    const isbeforeSession = beforeSession({
      date: allP.date,
      time: allP.time,
      timeZone: allP.timezone,
      gameAccess: allP.game_access,
    });

    const isbetweenTime = betweenTime({
      date: allP.date,
      time: allP.time,
      timeZone: allP.timezone,
      gameAccess: allP.game_access,
    });

    console.log('isbetweenTimeisbetweenTimeisbetweenTime', isbetweenTime, allP.date);

    let programStatus;
    let viewReportButton = null;
    let btn1;
    let btn2;
    let btn3;
    let btn4;

    if (isIsSessionPassed) {
      programStatus = (
        <td className="pl-5">
          Completed!
          <span className="p-1" />
        </td>
      );
      viewReportButton = (
        <div className="tooltips" style={{ display: 'none' }}>
          <span className="p-1" />
          <Link to="/">
            <button type="button" className={`btn ${styles['edit-border']}`}>
              View Report
            </button>
          </Link>
        </div>
      );
    }

    if (isbeforeSession) {
      programStatus = (
        <td className="pl-5" style={{ position: 'relative', top: -7 }}>
          Upcoming
          <div className="tooltips">
            <img
              className="float-right"
              src={WarnIcon}
              alt="complete-icon"
              style={{ width: '20px', marginLeft: 10, position: 'relative', top: 5 }}
            />
            <div style={{ width: 230, borderRadius: 4 }} className="tooltiptext">
              The host button will appear (only for facilitators) 30 minutes before the session
            </div>
          </div>
          <span className="p-1" />
        </td>
      );
      btn1 = (
        <div className="tooltips">
          <span className="p-1" />
          <Link to={`/design/edit/${allP.id}`}>
            <button type="button" className={`btn ${styles['edit-border']}`}>
              <img src={EditProgram} alt="edit-program" style={{ width: '26px', height: '22px' }} />
            </button>
          </Link>
          <span className="tooltiptext">Edit Program</span>
        </div>
      );
      btn2 = (
        <div className="tooltips">
          <span className="p-1" />
          <Link to="/program-invite">
            <button type="button" className={`btn ${styles['edit-border']}`}>
              <img
                src={ProgramInvite}
                alt="program-invite"
                style={{ width: '26px', height: '22px' }}
              />
            </button>
          </Link>
          <span className="tooltiptext">Program Invite</span>
        </div>
      );
      btn3 = (
        <div className="tooltips">
          <span className="p-1" />
          <Link to="/settings">
            <button
              type="button"
              className={`btn ${styles['edit-border']}`}
              onClick={isGameSettingClicked}>
              <img
                src={GameSettings}
                alt="game-settings"
                style={{ width: '26px', height: '22px' }}
              />
            </button>
          </Link>
          <span className="tooltiptext">Game Settings</span>
        </div>
      );
      btn4 = (
        <div className="tooltips">
          <span className="p-1" />
          {/* <Link onClick={() => handleEditFacilitator(allP)}> */}
          <button
            onClick={() => handleEditFacilitator(allP)}
            type="button"
            className={`btn ${styles['edit-border']}`}>
            <img
              src={EditFacilitator}
              alt="edit-facilitator"
              style={{ width: '26px', height: '22px' }}
            />
          </button>
          {/* </Link> */}

          <span className="tooltiptext">Edit Facilitator</span>
        </div>
      );
    }
    if (isbetweenTime) {
      programStatus = (
        <td className="pl-5">
          Ready
          <span className="p-1" />
          <img src={Ready} alt="ready-icon" style={{ width: '22px', height: '22px' }} />
        </td>
      );

      // btn1 = user.is_Certified ? (
      btn1 = (
        <div className="tooltips">
          <span className="p-1" />
          <button
            // Opens Evivve extension
            onClick={() => {
              if (document.body.getAttribute('evivve_extension_exist') === 'yes') {
                window.postMessage({
                  url: `${process.env.REACT_APP_GAME_APP}?code=${
                    allP.randomCode
                  }&id=${localStorage.getItem('userId')}
				  &program_id=${allP.id}`,
                  type: 'FROM_PAGE',
                  my_space: 'evivve_sample_extension',
                });
              } else {
                alert(
                  'You need the Evivve extension to host the game. Please unblock the pop-up and try again. If you have already installed the extension, reload this page'
                );
                window.open(
                  'https://chrome.google.com/webstore/detail/evivve-the-leadership-gam/gnlmlciclhcodcgikaoedgjeeggaoipc',
                  '_blank'
                );
              }
            }}
            type="button"
            className={`btn ${styles['edit-border']}`}>
            <img src={hostGame} alt="host-game" style={{ width: '26px', height: '22px' }} />
          </button>
          <span className="tooltiptext">Host Game</span>
        </div>
      );

      btn2 = (
        <div className="tooltips">
          <span className="p-1" />
          <Link to="/program-invite">
            <button type="button" className={`btn ${styles['edit-border']}`}>
              <img
                src={ProgramInvite}
                alt="program-invite"
                style={{ width: '26px', height: '22px' }}
              />
            </button>
          </Link>
          <span className="tooltiptext">Program Invite</span>
        </div>
      );
      btn3 = (
        <div className="tooltips">
          <span className="p-1" />
          <Link to="/settings">
            <button
              type="button"
              className={`btn ${styles['edit-border']}`}
              onClick={isGameSettingClicked}>
              <img
                src={GameSettings}
                alt="game-settings"
                style={{ width: '26px', height: '22px' }}
              />
            </button>
          </Link>
          <span className="tooltiptext">Game Settings</span>
        </div>
      );
      // btn4 = (
      //   <div className="tooltips">
      //     <span className="p-1" />
      //     {/* <Link onClick={() => handleEditFacilitator(allP)}> */}
      //     <button
      //       onClick={() => handleEditFacilitator(allP)}
      //       type="button"
      //       className={`btn ${styles['edit-border']}`}>
      //       <img src={EditFacilitator} alt="edit-facilitator" />
      //     </button>
      //     {/* </Link> */}

      //     <span className="tooltiptext">Edit-Facilitator</span>
      //   </div>
      // );
    }

    const programNewObject = {
      JSXContent: (
        <>
          <td>{allP.name}</td>
          <td>
            {allP.time} {allP.timezone.slice(0, 11)}
          </td>
          <td>{dayjs(allP.date).format('DD-MMM-YYYY')}</td>
          <td>{allP.game_access === GameAccessEnum.FULL ? 'FULL DAY' : 'HALF DAY'}</td>
          <td>{programStatus}</td>
          <td>
            {allP.actions.viewReportButton && (
              <div className="tooltips" style={{ display: 'none' }}>
                <span className="p-1" />
                <Link to="/">
                  <button type="button" className={`btn ${styles['edit-border']}`}>
                    View Report
                  </button>
                </Link>
              </div>
            )}
            {allP.actions.editProgram && (
              <div className="tooltips">
                <span className="p-1" />
                <Link to={`/design/edit/${allP.id}`}>
                  <button type="button" className={`btn ${styles['edit-border']}`}>
                    <img
                      src={EditProgram}
                      alt="edit-program"
                      style={{ width: '26px', height: '22px' }}
                    />
                  </button>
                </Link>
                <span className="tooltiptext">Edit Program</span>
              </div>
            )}
            {allP.actions.programInvite && (
              <div className="tooltips">
                <span className="p-1" />
                <Link to="/program-invite">
                  <button type="button" className={`btn ${styles['edit-border']}`}>
                    <img
                      src={ProgramInvite}
                      alt="program-invite"
                      style={{ width: '26px', height: '22px' }}
                    />
                  </button>
                </Link>
                <span className="tooltiptext">Program Invite</span>
              </div>
            )}
            {allP.actions.gameSettings && (
              <div className="tooltips">
                <span className="p-1" />
                <Link to="/settings">
                  <button
                    type="button"
                    className={`btn ${styles['edit-border']}`}
                    onClick={isGameSettingClicked}>
                    <img
                      src={GameSettings}
                      alt="game-settings"
                      style={{ width: '26px', height: '22px' }}
                    />
                  </button>
                </Link>
                <span className="tooltiptext">Game Settings</span>
              </div>
            )}
            {allP.actions.editfacilitator && (
              <div className="tooltips">
                <span className="p-1" />
                {/* <Link onClick={() => handleEditFacilitator(allP)}> */}
                <button
                  onClick={() => handleEditFacilitator(allP)}
                  type="button"
                  className={`btn ${styles['edit-border']}`}>
                  <img
                    src={EditFacilitator}
                    alt="edit-facilitator"
                    style={{ width: '26px', height: '22px' }}
                  />
                </button>
                {/* </Link> */}

                <span className="tooltiptext">Edit Facilitator</span>
              </div>
            )}
            {}
            {allP.actions.hostGame && (
              <div className="tooltips">
                <span className="p-1" />
                {/* {user.is_Certified ? ( */}
                <button
                  // Opens Evivve extension
                  onClick={() => {
                    if (document.body.getAttribute('evivve_extension_exist') === 'yes') {
                      window.postMessage({
                        url: `${process.env.REACT_APP_GAME_APP}?code=${
                          allP.randomCode
                        }&id=${localStorage.getItem('userId')}
						&program_id=${allP.id}`,
                        type: 'FROM_PAGE',
                        my_space: 'evivve_sample_extension',
                      });
                    } else {
                      alert(
                        'You need the Evivve extension to host the game. Please unblock the pop-up and try again. If you have already installed the extension, reload this page'
                      );
                      window.open(
                        'https://chrome.google.com/webstore/detail/evivve-the-leadership-gam/gnlmlciclhcodcgikaoedgjeeggaoipc',
                        '_blank'
                      );
                    }
                  }}
                  type="button"
                  className={`btn ${styles['edit-border']}`}>
                  <img src={hostGame} alt="host-game" style={{ width: '26px', height: '22px' }} />
                </button>
                {/* ) : null} */}
                <span className="tooltiptext">Host Game</span>
              </div>
            )}
          </td>
        </>
      ),
      programData: allP,
    };

    dummyProgramList.push(programNewObject);

    if (programsList.length - 1 === i) setNewProgramList(dummyProgramList);

    return (
      <>
        <td>{allP.name}</td>
        <td>
          {allP.time} {allP.timezone.slice(0, 11)}
        </td>
        <td>{dayjs(allP.date).format('DD-MMM-YYYY')}</td>
        <td>{allP.game_access === GameAccessEnum.FULL ? 'FULL DAY' : 'HALF DAY'}</td>
        <td>{programStatus}</td>
        <td>
          {viewReportButton && viewReportButton}
          {btn1}
          {btn2}
          {btn3}
          {btn4}
        </td>
      </>
    );
  };

  useEffect(() => {
    if (programsList.length > 0) {
      programsList.map((p, i) => handleProgramStatusAndButtons(p, i));
    }
  }, [programsList]);

  const handleChange = (selected) => {
    setSelectedOption(selected);

    const dummyArray = newProgramList;

    if (selected.value === 'name') {
      dummyArray.sort(function (a, b) {
        if (a.programData.name < b.programData.name) {
          return -1;
        }
        if (a.programData.name > b.programData.name) {
          return 1;
        }
        return 0;
      });

      setNewProgramList(dummyArray);
    }

    if (selected.value === 'access') {
      dummyArray.sort(function (a, b) {
        if (a.programData.game_access < b.programData.game_access) {
          return -1;
        }
        if (a.programData.game_access > b.programData.game_access) {
          return 1;
        }
        return 0;
      });

      setNewProgramList(dummyArray);
    }

    if (selected.value === 'time') {
      programsList.map((p, i) => handleProgramStatusAndButtons(p, i));
    }

    if (selected.value === 'status') {
      dummyArray.sort(function (a, b) {
        if (
          a.JSXContent.props.children[4].props.children.props.children[0] <
          b.JSXContent.props.children[4].props.children.props.children[0]
        ) {
          return -1;
        }
        if (
          a.JSXContent.props.children[4].props.children.props.children[0] >
          b.JSXContent.props.children[4].props.children.props.children[0]
        ) {
          return 1;
        }
        return 0;
      });

      setNewProgramList(dummyArray);
    }
  };

  const onDemoHost = () => {
    if (document.body.getAttribute('evivve_extension_exist') === 'yes') {
      window.postMessage({
        url: `${process.env.REACT_APP_GAME_APP}?code=demoHost&id=${localStorage.getItem('userId')}`,
        type: 'FROM_PAGE',
        my_space: 'evivve_sample_extension',
      });
      // window.open(`${process.env.REACT_APP_GAME_APP}?code=demoHost&id=${localStorage.getItem("userId")}`, "popup", 'height=1600,width=1800,resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes')
    } else {
      alert(
        'You need the Evivve extension to host the game. Please unblock the pop-up and try again. If you have already installed the extension, reload this page'
      );
      window.open(
        'https://chrome.google.com/webstore/detail/evivve-the-leadership-gam/gnlmlciclhcodcgikaoedgjeeggaoipc',
        '_blank'
      );
    }
  };

  return (
    <>
      {query.isLoading && <Loader />}
      <div className="container">
        <div className="row">
          <div className={`col-12 pb-0 pt-4 ${styles.title}`}>
            <TitleComponent
              title="Home"
              heading="My Programs"
              subtitle="A quick list of all your programs"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xm-1 col-md-1 mt-4 p-2 text-left"> Sort by </div>

          <div className="col-xm-3 col-md-3 mt-md-4 pr-0 pl-2 pr-3">
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={options}
              placeholder="Date"

              // className={`btn btn-secondary mr-3 ${styles['program-button']}`}
            />
          </div>
          <div
            className={`col-sm-8 col-xm-12 mt-4 pb-0 pr-0 text-sm-right  ${styles.buttons_wrapper} pr-3 `}
            style={
              isMobile
                ? {
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflowX: 'auto',
                  }
                : {}
            }>
            <Link to="/design/add/new">
              <button
                type="button"
                className={`btn btn-secondary mr-3 ${styles['program-button']}`}>
                <img
                  src={CreateProgram}
                  alt="Create-Program"
                  className="mr-2"
                  style={{ width: '15px', verticalAlign: 'middle' }}
                />
                Create Program
              </button>
            </Link>
            <button
              onClick={onDemoHost}
              type="button"
              className={`btn btn-primary mr-3 ${styles['program-button']}`}>
              Host Demo
            </button>

            {user.userrType === 'ADMIN' && (
              <Link to="/certificates">
                <button type="button" className={`btn btn-primary ${styles['program-button']}`}>
                  Admin Panel
                </button>
              </Link>
            )}
            {!user.is_Certified && (
              <button
                onClick={() => {
                  history.push('/');
                }}
                type="button"
                className={`btn btn-primary mr-3 ml-3 ${styles['program-button']}`}>
                Facilitator Mode
              </button>
            )}
          </div>
          <div
            style={
              isMobile
                ? {
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflowX: 'scroll',
                  }
                : {
                    width: '100%',
                  }
            }>
            <table className={`table table-sm ${styles['table-bg']}`}>
              <thead className="thread">
                <tr>
                  <th scope="col">Program Name</th>
                  <th scope="col"> Time</th>
                  <th scope="col">Date</th>
                  <th scope="col">Access</th>
                  <th className="pl-5" scope="col">
                    Status
                  </th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {newProgramList.map((p) => (
                  <tr>{p.JSXContent}</tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

MyProgramsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default MyProgramsPage;
