import React from 'react';

const CommunityPage = () => {
  return (
    
    <div>
      <iframe
        title="screen"
        scrolling="no"
        src="https://play.workadventu.re/_/4druupb1wng/gamitarlearning.github.io/evivvecommunity/maps/map.json"
        width="100%"
        height={`${window.innerHeight-60}px`}
        frameBorder="0"
        allow="camera; microphone; display-capture; autoplay; clipboard-write;"
      />
    </div>
  );
};
export default CommunityPage;


