import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import AuthLogin from './login.auth';
import styles from './auth.module.scss';
import AuthSignUp from './signup.login';
import { useUserLogin, useUserRegister } from '../../services';
import Loader from '../../components/Loader';
import { UserTypeEnum } from '../../utils';

const AuthFormContainer = () => {
  // const name = useSelector((state) => state.program.name);
  const login = useUserLogin();
  const register = useUserRegister();

  const passwordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const [passwordValidate, setPasswordValidate] = useState(false);

  const [isLoginForm, setIsLoginForm] = useState(true);
  const [required, setRequired] = useState(false);
  const [required1, setRequired1] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  // const [certificationCode, setCertificationCode] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectTimezone, setTimezone] = useState('');

  const onNextClick = () => {
    if (isLoginForm && (email === '' || password === '')) {
      setRequired(true);
    } else if (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      password === '' ||
      retypePassword === '' ||
      selectTimezone === ''
    ) {
      setRequired1(true);
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (isLoginForm && (email === '' || password === '')) {
      setRequired(true);
      return;
    }

    if (
      !isLoginForm &&
      (firstName === '' ||
        lastName === '' ||
        email === '' ||
        password === '' ||
        retypePassword === '' ||
        selectTimezone === '')
      // ||
      // certificationCode === ''
    ) {
      setRequired1(true);
      return;
    }

    if (!isLoginForm) {
      const enteredPassword = passwordInputRef.current.value;
      const confirmPassword = confirmPasswordInputRef.current.value;
      if (enteredPassword === confirmPassword) {
        setPasswordValidate(false);
        register.mutate({
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email: email.trim(),
          password,
          confirm_password: confirmPassword,
          timezone: selectTimezone,
          // certificationCode: certificationCode.trim(),
          user_type: UserTypeEnum.USER,
        });
      } else if (enteredPassword !== confirmPassword) {
        setPasswordValidate(true);
      }
    } else {
      login.mutate({ email, password });
    }
  };

  return (
    <>
      {(login.isLoading || register.isLoading) && <Loader />}
      <div className={styles['form-container']}>
        <form className={styles.form} onSubmit={onFormSubmit}>
          <h3 className="m-4">{isLoginForm ? 'Login' : 'Get Certified'}</h3>
          {isLoginForm ? (
            <AuthLogin
              email={email}
              password={password}
              setEmail={setEmail}
              setPassword={setPassword}
              required={required}
            />
          ) : (
            <AuthSignUp
              email={email}
              password={password}
              setEmail={setEmail}
              setPassword={setPassword}
              firstName={firstName}
              lastName={lastName}
              setFirstName={setFirstName}
              setLastName={setLastName}
              selectTimezone={selectTimezone}
              // certificationCode={certificationCode}
              setTimezone={setTimezone}
              retypePassword={retypePassword}
              setRetypePassword={setRetypePassword}
              required1={required1}
              // setCertificationCode={setCertificationCode}
              passwordInputRef={passwordInputRef}
              confirmPasswordInputRef={confirmPasswordInputRef}
              passwordValidate={passwordValidate}
            />
          )}
          <button
            type="submit"
            // onClick={onNextClick}
            className={`btn  btn-secondary ${styles['submit-btn']} pr-5 pl-5 ${
              isLoginForm ? 'mt-5' : 'mt-2'
            }`}>
            {isLoginForm ? 'LOGIN' : 'SIGN UP'}
          </button>
        </form>
        <div>
          <p className={styles.link} onClick={() => setIsLoginForm(true)}>
            {isLoginForm ? (
              <>
                New Here?
                <a
                    href={`${process.env.REACT_APP_URL}/evivve3/auth/sign-up`}
                    style={{color: 'white'}}
                >
                  <strong> Sign Up</strong>
                </a>
              </>
            ) : (
              <>
                Already have an account?
                <strong> Log In</strong>
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default AuthFormContainer;
