/* eslint-disable no-alert */
/* eslint-disable react/jsx-one-expression-per-line */
/*eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BuyCredits from '../../assets/credits/credits.png';
import { useBuyCredits, useGetCreditsByUserId } from '../../services';
import { useCreditStore } from '../../store';
import Counter from '../counter/counter';
import styles from './buyCredits.module.scss';
import { isMobile } from '../../utils';
import WarnIcon from '../../assets/program/info.png';


const BuyCreditComponent = ({ halfCr, fullCr, radioInput, stateList, gstNum }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [baseAmount, setBaseAmount] = useState();
  const [isHalfDaySelected, setCredit] = useState(true);
  const buy = useBuyCredits();
  const [count, setCount] = useState(1);
  const InoiceType = useCreditStore((g) => g.inoiceType);


  const incNum = () => {
    if (count === 10) {
      return;
    }
    if (count < 5) {
      setCount((n) => n + 1);
      return;
    }

    setCount((n) => (n === 1 ? n + 4 : n + 5));
  };

  const decNum = () => {
    if (count === 1) {
      return;
    }
    if (count <= 5) {
      setCount((n) => n - 1);
      return;
    }
    setCount((n) => (n === 5 ? n - 4 : n - 5));
  };

  let subTotal;
  let convertedAmount;
  let halfDayCredits = 0;
  let fullDayCredits = 0;

  switch (count) {
    case 1:
      subTotal = isHalfDaySelected ? count * 125.0 : count * 250.0;
      if (subTotal === 125) {
        halfDayCredits = count;
      } else if (subTotal === 250) {
        fullDayCredits = count;
      }
      break;
    case 2:
      subTotal = isHalfDaySelected ? count * 125.0 : count * 250.0;
      if (subTotal === 250) {
        halfDayCredits = count;
      } else if (subTotal === 500) {
        fullDayCredits = count;
      }
      break;
    case 3:
      subTotal = isHalfDaySelected ? count * 125.0 : count * 250.0;
      if (subTotal === 375) {
        halfDayCredits = count;
      } else if (subTotal === 750) {
        fullDayCredits = count;
      }
      break;
    case 4:
      subTotal = isHalfDaySelected ? count * 125.0 : count * 250.0;
      if (subTotal === 500) {
        halfDayCredits = count;
      } else if (subTotal === 1000) {
        fullDayCredits = count;
      }
      break;
    case 5:
      subTotal = isHalfDaySelected ? count * 115.0 : count * 231.25;
      if (subTotal === 575) {
        halfDayCredits = count;
      } else if (subTotal === 1156.25) {
        fullDayCredits = count;
      }
      break;
    case 10:
      subTotal = isHalfDaySelected ? count * 106.0 : count * 218.75;
      if (subTotal === 1060) {
        halfDayCredits = count;
      } else if (subTotal === 2187.5) {
        fullDayCredits = count;
      }
      break;
    default:
      break;
  }
  const currencyConversion = async (convertAmount) => {
    const myHeaders = new Headers();
    myHeaders.append('apikey', 'Hjxcs6lyVWKEApVOggR2MjabEv4czZeX');
    myHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };

    return fetch(
      `https://api.apilayer.com/exchangerates_data/convert?to=INR&from=USD&amount=${convertAmount} `,
      requestOptions
    )
      .then((response) => {
        return response.text();
      })
      .then((result) => {
        subTotal = JSON.parse(result).result;
        return subTotal;
      })
      .catch((error) => console.log('error', error));
  };
  const onBuy = async () => {
    if (radioInput === 'gst' || InoiceType === 'gst') {
      const convertAmount = subTotal;
      convertedAmount = await currencyConversion(convertAmount);
      subTotal = convertedAmount;
      subTotal = (subTotal / 100) * 18 + subTotal;
      subTotal = (Math.round(subTotal * 100) / 100).toFixed(2);
    }

    buy.mutate({
      amount: subTotal.toString(),
      currency: radioInput === 'gst' || InoiceType === 'gst' ? 'INR' : 'USD',
      credit_type: isHalfDaySelected ? 'HALF' : 'FULL',
      credit: isHalfDaySelected ? halfDayCredits : fullDayCredits,
    });
    setShowPopup(false);
  };
  const onShowDetails = async () => {
    const convertAmount = subTotal;
    convertedAmount = await currencyConversion(convertAmount);
    setBaseAmount(convertedAmount);
    setShowPopup(true);
  };
  useGetCreditsByUserId();

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <div
          style={{
            backgroundColor: '#ffffff',
            border: 'solid',
            borderColor: '#0d2359',
            borderTopLeftRadius: '25px',
            borderBottomLeftRadius: '20px',
            height: 'calc(100vh - 62px)',
            overflowY: 'scroll',
          }}
          className="container">
          <div
            style={{
              backgroundColor: '#0d2359',
              borderTopLeftRadius: '20px',
              height: '12vh',
              paddingTop: !isMobile && '10px',
            }}
            className="row">
            <div className="col-5">
              <img
                className="pt-3 pb-3 float-right"
                src={BuyCredits}
                alt="complete-icon"
                style={{
                  width: '60px',
                  marginRight: '10px',
                  marginTop: isMobile ? '15px' : '-5px',
                }}
              />
            </div>
            <div className="col-6" style={{ color: '#ffffff' }}>
              <h3
                className="pt-5"
                style={{ marginTop: isMobile ? '-0px' : -25, fontSize: isMobile && 25 }}>
                <strong className="text-center">Buy Credits</strong>
                <div className="tooltips">
                  <Link to='/settings'>
                    <img
                      className="float-right"
                      src={WarnIcon}
                      alt="complete-icon"
                      style={{ width: '20px', marginLeft: 10, position: 'relative' }}
                    />
                  </Link>
                  <div style={{ width: 400, borderRadius: 4, fontSize: 12, left: -250}} className="tooltiptext">
                    Credits are used to facilitate games with advanced settings. Click here to see Game Settings.
                  </div>
                </div>
              </h3>
            </div>
          </div>

          <div className="row" style={{ height: '9vh' }}>
            <input
              type="button"
              value="Half Day"
              className={`col text-center p-3 ${styles.credit1} ${
                !isHalfDaySelected ? styles.active : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setCredit(true);
              }}
            />

            <input
              type="button"
              value="Full Day"
              className={`col text-center p-3 ${styles.credit1} ${
                isHalfDaySelected ? styles.active : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setCredit(false);
              }}
            />
          </div>

          <div className="border-bottom border-secondary p-2" />
          <div className="row">
            <h5 className="col text-center pt-1">
              {isHalfDaySelected
                ? `Remaining Half Day Credits : ${halfCr}`
                : `Remaining Full Day Credits : ${fullCr}`}
            </h5>
          </div>
          <div className="border-top border-secondary pb-1" />

          <div style={{ height: '38vh', paddingBottom: 10, overflowY: 'auto' }}>
            <table className={`table table-sm ${styles['table-bg']}`}>
              <thead>
                <tr>
                  <th scope="col">Count</th>
                  <th scope="col">Cost/Credit</th>
                  <th scope="col">Discount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>{isHalfDaySelected ? '$125.00' : '$250.00'}</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>{isHalfDaySelected ? '$250.00' : '$500.00'}</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>{isHalfDaySelected ? '$375.00' : '$750.00'}</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>{isHalfDaySelected ? '$500.00' : '$1000.00'}</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  {/* <td>{isHalfDaySelected ? '$115.00' : '$185.00'}</td> */}
                  <td>{isHalfDaySelected ? '$575.00' : '$1156.25'}</td>
                  <td>7.5%</td>
                </tr>
                <tr>
                  <th scope="row">10</th>
                  {/* <td>{isHalfDaySelected ? '$106.00' : '$170.00'}</td> */}
                  <td>{isHalfDaySelected ? '$1060.00' : '$2187.50'}</td>
                  <td>15%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ height: 'auto', marginTop: 20 }}>
            <div className="text-center ">
              <Counter num={count} decNum={decNum} incNum={incNum} />
            </div>
            <h4 className={`text-center ${isMobile ? 'mr-2 ' : 'mr-4'}`}>
              <strong className="pl-2">Subtotal = ${subTotal}</strong>
            </h4>
          </div>
          <div className="col btn-lg btn-block">
            <button
              type="button"
              className={`btn btn-secondary w-100 ${styles['credit-btn']}`}
              onClick={() =>
                radioInput === 'gst' || InoiceType === 'gst' ? onShowDetails() : onBuy()
              }>
              {isHalfDaySelected ? 'Buy Half-Day Credit' : 'Buy Full-Day Credit'}
            </button>
          </div>
        </div>
      </div>
      <div
        style={
          showPopup
            ? {
                position: 'absolute',
                top: '38%',
                left: '-22%',
                transform: 'translate(-50% ,-50%',
                backgroundColor: '#fff6ea',
                backdropFilter: 'grayscale(0.5) opacity(0.8)',
                boxShadow: 'rgb(0 0 0 / 60%) 0px 2px 12px',
                borderRadius: '10px',
                width: '370px',
                padding: '2rem',
              }
            : { display: 'none' }
        }>
        <p>
          <b>Base Amount</b> : {(Math.round(baseAmount * 100) / 100).toFixed(2)}
        </p>
        <p>
          <b>Tax (GST)</b> : {(Math.round((baseAmount / 100) * 18 * 100) / 100).toFixed(2)}
        </p>
        <p>
          <b>Total Amount </b>:{' '}
          {(Math.round(((baseAmount / 100) * 18 + baseAmount) * 100) / 100).toFixed(2)}
        </p>

        <button
          type="button"
          onClick={onBuy}
          className={`btn btn-secondary w-100 ${styles['credit-btn']}`}>
          Proceed
        </button>
      </div>
    </>
  );
};

BuyCreditComponent.propTypes = {
  halfCr: PropTypes.string,
  fullCr: PropTypes.string,
  radioInput: PropTypes.string.isRequired,
  stateList: PropTypes.string.isRequired,
  gstNum: PropTypes.string.isRequired,
};
BuyCreditComponent.defaultProps = {
  halfCr: '',
  fullCr: '',
};
export default BuyCreditComponent;
