import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router-dom';
import styles from './programInvite.module.scss';
import 'react-quill/dist/quill.snow.css';

import TitleComponent from '../../components/title/title.component';
import CardComponent from '../../components/card/card.component';
import { setProgramData } from '../../actions/program.action';
import DefaultDescription from './description';
import axiosClient from '../../utils/axiosClient';
import ShowToast from '../../components/ShowToast';
import Loader from '../../components/Loader';
import { isMobile } from '../../utils';

const ProgramInvitePage = () => {
  // const dispatch = useDispatch();
  // const rdcInvitation = useSelector((state) => state.program.programInvitation);

  const history = useHistory();

  const [programInvitation, setProgramInvitation] = useState('');
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [myDescription, setMyDescription] = useState();
  const [load, setLoad] = useState(false);

  const editContentHandler = () => {
    setIsInEditMode(!isInEditMode);
    setEditButton(!editButton);
  };

  const onPreviousClick = () => {
    history.push('/my-programs');
  };

  const handleResetDescription = () => {
    const id = localStorage.getItem('userId');
    const data = {
      user_id: id,
      desc: DefaultDescription,
    };

    axiosClient()
      .post('/updateProgramInviteByUser', data)
      .then((res) => {
        setMyDescription(DefaultDescription);
        ShowToast('Reset Successfully!', 'success');
      })
      .catch((err) => {
        ShowToast('Error...!', 'error');
      });
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['clean'],
      [{ header: [1, 2, 3, 4, 5, 6] }],
      [{ size: ['small', false, 'large', 'huge'] }],
    ],
  };

  useEffect(() => {
    setLoad(true);
    const id = localStorage.getItem('userId');
    axiosClient()
      .get(`/getProgramInviteByUser/${id}`)
      .then((res) => {
        if (res.data.data.programInviteDesc) {
          setMyDescription(res.data.data.programInviteDesc);
          setLoad(false);
        } else {
          setMyDescription(DefaultDescription);
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(true);
        ShowToast('Something went wrong...!', 'error');
      });
  }, []);

  const handleUpdateDescription = () => {
    const id = localStorage.getItem('userId');
    if (myDescription === '<p><br></p>') return ShowToast('Invite can not be empty', 'error');

    const data = {
      user_id: id,
      desc: myDescription,
    };

    axiosClient()
      .post('/updateProgramInviteByUser', data)
      .then((res) => {
        setMyDescription(res.data.data.programInviteDesc);
        ShowToast('Updated Successfully!', 'success');
      })
      .catch((err) => {
        ShowToast('Error...!', 'error');
      });

    return true;
  };

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <>
          <div className={`w-75 pt-3  ${styles.title}`}>
            <TitleComponent
              title="Home/ Invite"
              heading="Invite"
              subtitle="Personalize your Invitation"
            />
          </div>

          <div className={`container col-12 mt-4 ${styles['box-shadow']}`}>
            <CardComponent>
              <>
                <h4 className="font-weight-bold">Program Invite</h4>
                <p style={{ fontSize: 'large' }}>
                  Copy and paste the text below into your program’s calendar invite. Feel free to
                  make edits, keep what you need.
                </p>
                <ReactQuill
                  className="quill"
                  modules={modules}
                  value={myDescription}
                  onChange={(description) => setMyDescription(description)}
                />
                {/* <div className={`pl-3 pb-4 ${styles['invite-border']}`} id="editContent">
              {isInEditMode ? (
                <div className={styles['line-break']}>
                  <textarea
                    style={{ width: '100%' }}
                    type="text"
                    rows="45"
                    onChange={(e) => setProgramInvitation(e.target.value)}
                    value={programInvitation}
                  />
                </div>
              ) : (
                <div className={styles['line-break']}>{programInvitation}</div>
              )}
            </div> */}
                <div className={`p-5 ${isMobile && 'd-flex'}`}>
                  <button
                    className={`btn btn-secondary float-right ml-2 mt-3 ${styles['invite-button']}`}
                    onClick={handleUpdateDescription}
                    type="button">
                    Save
                  </button>
                  <button
                    onClick={onPreviousClick}
                    className={`btn btn-secondary float-right ml-2 mt-3 ${styles['invite-button']}`}
                    type="button">
                    Back
                  </button>
                  <button
                    className={`btn btn-secondary float-right mt-3 ml-2 ${styles['invite-button']}`}
                    style={{ background: '#ed6972' }}
                    onClick={handleResetDescription}
                    type="button">
                    Reset
                  </button>
                </div>
              </>
            </CardComponent>
          </div>
        </>
      )}
    </>
  );
};
ProgramInvitePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default ProgramInvitePage;
