import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import styles from './profile.module.scss';
import { useUserStore } from '../../store';
import TitleComponent from '../title/title.component';
import CardComponent from '../card/card.component';
import Loader from '../Loader';
import Profilepic from '../../assets/user/profile.png';
import CertificateIcon from '../../assets/certificate_icon.png';
import { useUpdateProfilePic, useUpdateUserProfile } from '../../services';
import ShowToast from '../ShowToast';
import ImageCropper from '../imageCropper/imageCropper';
import { isMobile } from '../../utils';

const ProfileComponent = () => {
  const user = useUserStore((s) => s.user);
  const picUpload = useUpdateProfilePic();
  const update = useUpdateUserProfile();
  const history = useHistory();

  const [image, setImage] = useState({ profile: Profilepic, raw: '' });
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [phone, setPhone] = useState('');
  const [imgSrc, setImgSrc] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [aspect, setAspect] = useState(16 / 9);
  const [crop, setCrop] = useState();
  useEffect(() => {
    if (user.avatar_url) {
      console.log('maha', user);
      setImage({ profile: user.avatar_url, raw: '' });
    }
  }, [user.avatar_url]);
  const handleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  useEffect(() => {
    if (user.id) {
      setEmail(user.email);
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setCompany(user.company || '');
      setPosition(user.position || '');
      setPhone(user.phone || '');
    }
  }, [
    user.company,
    user.email,
    user.first_name,
    user.id,
    user.last_name,
    user.phone,
    user.position,
  ]);

  const uploadProfilePic = (e) => {
    console.log('sadsa');

    if (e.target.files.length) {
      // setImage({
      //   profile: URL.createObjectURL(e.target.files[0]),
      //   raw: e.target.files[0],
      // });
      setModalIsOpen(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handlePicUpload = (photoUrl) => {
    console.log('url', photoUrl);
    const pic = new FormData();
    pic.append('avatar', photoUrl);
    setImgSrc('');
    handleModal();
    picUpload.mutate(pic);
  };

  const updatePasswordHandle = () => {
    if (!currentPassword || !newPassword) {
      ShowToast('Enter current & new password', 'error');
      return;
    }
    if (newPassword !== confirmPassword) {
      ShowToast('Password Not matched', 'error');
      return;
    }
    update.mutate({
      ...user,
      current_password: currentPassword,
      new_password: newPassword,
    });
  };

  const saveChangeHandle = () => {
    if (!firstName || !lastName) {
      ShowToast('Enter first name & last name', 'error');
      return;
    }

    update.mutate({
      ...user,
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      phone: phone.trim(),
      company: company.trim(),
      position: position.trim(),
      email: email.trim(),
    });
  };

  return (
    <>
      {imgSrc && modalIsOpen && (
        <ImageCropper
          handleModal={handleModal}
          onCropperClick={(e) => {
            console.log(e);
          }}
          handlePicUpload={handlePicUpload}
          imgSrc={imgSrc}
        />
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (modalIsOpen) {
            handleModal();
          }
        }}>
        {(picUpload.isLoading || update.isLoading) && <Loader />}
        <div className={`w-75 ${styles.title}`}>
          <TitleComponent title="Home/ Profile" />
        </div>

        <div>
          <div className={`container ${styles['box-shadow']}`}>
            <CardComponent>
              <>
                <div className="container">
                  <div className="row pb-2">
                    <div className="col-md-5 col-sm-12">
                      <label htmlFor="upload-button">
                        {image.profile ? (
                          <img
                            style={{ borderRadius: '50%' }}
                            src={image.profile}
                            alt="dummy"
                            width="83"
                            height="83"
                          />
                        ) : (
                          <></>
                        )}
                      </label>
                      <input
                        type="file"
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        id="upload-button"
                        style={{ display: 'none' }}
                        accept=".jpg,.jpeg,.png"
                        onChange={uploadProfilePic}
                      />

                      <span style={{ fontSize: 'x-large' }} className="pl-4">
                        {`${user.first_name} ${user.last_name}`}
                      </span>
                    </div>
                    {user.is_Certified ? (
                      <div className="col-md-5 col-sm-12">
                        <button
                          onClick={() =>
                            history.push(`/certification/${user.certificateVerifyCode}`)
                          }
                          type="submit"
                          className="btn btn-secondary float-right certifacation_button"
                          style={{
                            width: isMobile ? '100%' : '50%',
                            backgroundColor: '#0d2359',
                            marginTop: '25px',
                          }}>
                          <i className="fa-light fa-file-certificate" />
                          <img
                            // style={{ borderRadius: '50%' }}
                            src={CertificateIcon}
                            alt="dummy"
                            width="20"
                            height="20"
                            className="mr-3"
                          />
                          View Certificate
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="border" />
                  <form>
                    <div className="form-row pt-2">
                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="inputName">First name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          id="inputName"
                          placeholder="Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="col-2" />

                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="inputPassword">Current Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your current Password"
                          name="currentPassword"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </div>
                      <div className="w-100" />

                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="inputName">Last name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          id="lastName"
                          placeholder="Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>

                      <div className="col-2" />

                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="inputPassword">New Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your new Password"
                          name="newPassword"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="inputEmail">Email</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          id="inputEmail"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                      <div className="col-2" />
                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="inputName">Retype Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="confirmPass"
                          id="lastName"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>

                      <div className="col-md-10 col-sm-12 pb-2">
                        <button
                          type="button"
                          className="btn btn-secondary float-right"
                          onClick={updatePasswordHandle}>
                          Update Password
                        </button>
                      </div>
                    </div>
                  </form>

                  <div className="border" />

                  <form>
                    <div className="form-row pt-2">
                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="inputName">Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputName"
                          placeholder="Company Name"
                          name="company"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                      </div>
                      <div className="col-2" />
                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="inputName">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputName"
                          placeholder="Phone"
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="col-2" />
                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="inputPassword">Position/Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Position/Title"
                          name="position"
                          value={position}
                          onChange={(e) => setPosition(e.target.value)}
                        />
                      </div>
                      <div className="col-md-10 col-sm-12">
                        <button
                          type="button"
                          // style={{ width: '19%' }}
                          className="btn btn-secondary float-right"
                          onClick={saveChangeHandle}>
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            </CardComponent>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileComponent;
