import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForgetPassword } from '../../services';
import styles from './auth.module.scss';

const AuthForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sendAlert, setSendAlert] = useState(false);
  const [required, setRequired] = useState(false);

  const forgetPassowrd = useForgetPassword();

  const deleteHandler = () => {
    setModalIsOpen(true);
  };
  const cancelHandler = () => {
    setModalIsOpen(false);
    setSendAlert(false);
  };
  const confirmHandler = () => {
    if (email === '') {
      setRequired(true);
    } else {
      setSendAlert(true);
      setModalIsOpen(true);
      forgetPassowrd.mutate({ email });
      setTimeout(() => {
        setModalIsOpen(false);
      }, 3000);
    }
  };
  console.log('sss', styles.modal);
  return (
    <div style={{ marginRight: '45px' }}>
      <div className={styles.actions}>
        <button type="button" className="btn" onClick={deleteHandler}>
          Forgot Password
        </button>
      </div>
      {modalIsOpen && (
        <div className={styles.modal}>
          <h3>Forgot Password</h3>
          <p>Enter your email address and we will send instructions to reset your password.</p>
          <input
            type="text"
            className={`form-control ${required ? 'border-danger form-control is-invalid' : ''}`}
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <div className="invalid-feedback">Please enter a valid Program name.</div>

          {sendAlert ? (
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
          ) : (
            ''
          )}

          <button type="button" className="btn btn-secondary m-3" onClick={confirmHandler}>
            Send Instructions
          </button>
        </div>
      )}

      {modalIsOpen && <div className={styles.backdrop} onClick={cancelHandler} />}
    </div>
  );
};

export default AuthForgotPassword;
