import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const EmbedPage = () => {

  const history = useHistory()

  const onNextClick = () => {
    history.push('/my-programs');
  };
  return (
    <>
      <div>
        <iframe
          scrolling="no"
          title="calendly-iframe"
          src=" https://calendly.com/evivve"
          width="100%"
          height="500"
          frameBorder="no"
          allowtransparency="true"
          allowFullScreen="true"
        />
      </div>
      <div>
        <button
          type="button"
          onClick={onNextClick}
          className="btn btn-secondary float-right mr-5 mt-3"
          >
          Complete Booking
        </button>
      </div>
    </>
  );
};
EmbedPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default EmbedPage;
