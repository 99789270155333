/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import caretUp from '../../assets/navbar/caretUp.png';
import caretDown from '../../assets/navbar/caretDown.png';
import Profilepic from '../../assets/user/profile.png';
import styles from './dropdown.module.scss';
import navstyles from '../navbar/navbar.module.scss';
import { useUserStore } from '../../store';
import { isMobile } from '../../utils';

const DropdownComponent = ({ data, active, setActive, handleClick }) => {
  const user = useUserStore((s) => s.user);
  const [click, setClick] = useState(isMobile ? true : false);
  const [image, setImage] = useState(Profilepic);

  useEffect(() => {
    if (user.avatar_url) {
      setImage(user.avatar_url);
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = () => {
      setActive('');
      setClick(click);
    };
    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const onHeadingClick = (e) => {
    console.log('click', data);
    setActive(data.heading);
    setClick(!click);
    e.stopPropagation();
  };

  console.log('click', click);
  return (
    <li className={navstyles['navigation-bar-item']} onClick={onHeadingClick}>
      <div className={navstyles['navigation-bar-links']}>
        {data.img && (
          <img
            src={image}
            style={{ height: '40px', width: '40px', marginRight: '5px', borderRadius: '50%' }}
            alt="userProfile"
          />
        )}
        {user.id && data.id === 'user'
          ? `${user.first_name} ${user.last_name}`
          : data.id === 'My Program'
          ? data.heading
          : 'User'}
        {click ? <img src={caretUp} alt="caretUp" /> : <img src={caretDown} alt="caretDown" />}
        {active === data.heading && (
          <ul
            style={{ display: click ? '' : 'none' }}
            className={`${styles['dropdn-menu']} ${click ? styles.clickd : ''}`}>
            {data.content.map((e) => (
              <li key={e.id}>
                {e.path === '/roadmap' ? (
                  <div className="tooltips">
                    <a
                      href="https://evivve.canny.io/"
                      target='_blank'
                      rel="noreferrer"
                      className={styles[e.cName]}
                    >
                      <img
                        className={styles[e.className]}
                        style={{ height: '35px', width: '32px' }}
                        alt={e.id}
                        src={e.src}
                        key={e.id}
                      />

                      {e.title}
                    </a>
                  </div>
                ) : e.path !== '/resources' ? (
                  <div className={e.path === '/community' ? 'tooltips' : ''}>
                    <Link
                      to={e.path}
                      onClick={() => {
                        e.path === '/settings'
                          ? localStorage.removeItem('gameSettingClicked')
                          : null;
                        handleClick();
                      }}
                      className={styles[e.cName]}>
                      <img
                        className={styles[e.className]}
                        style={{ height: '30px', width: '30px' }}
                        alt={e.id}
                        src={e.src}
                        key={e.id}
                      />
                      {e.path === '/community' && (
                        <span className="tooltiptext" style={{ backgroundColor: '#d3d3d3' }}>
                          Meet and greet members of your community in Evivve University!
                        </span>
                      )}
                      {e.title}
                      {e.path === '/community' && (
                        <span
                          style={{
                            backgroundColor: '#ee7498',
                            borderRadius: '2px',
                            padding: '2px',
                            fontWeight: 'bolder',
                            fontSize: '9px',
                          }}>
                          NEW
                        </span>
                      )}
                    </Link>
                  </div>
                ) : (
                    <a
                      href="https://gamitar.freshdesk.com/support/home"
                      target='_blank'
                      rel="noreferrer"
                      className={styles[e.cName]}
                    >
                      <img
                        className={styles[e.className]}
                        style={{ height: '30px', width: '30px' }}
                        alt={e.id}
                        src={e.src}
                        key={e.id}
                      />

                      {e.title}
                    </a>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};

DropdownComponent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
};

export default DropdownComponent;
