import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { GoogleLogin } from '@react-oauth/google';
import ShowToast from '../../components/ShowToast';
import usernameIcon from '../../assets/auth/username-icon.png';
import passwordIcon from '../../assets/auth/password-icon.png';
import styles from './auth.module.scss';
import AuthForgotPassword from './forgotPassword.auth';
import { useGoogleLogin } from '../../services';

const AuthLogin = ({ setEmail, setPassword, email, password, required }) => {
  const googleLogin = useGoogleLogin();

  useEffect(() => {
    localStorage.setItem('email1', email);
    setEmail('');
    setPassword('');

    return () => {
      setEmail('');
      setPassword('');
    };
  }, [setEmail, setPassword]);

  const handleGoogleLogin = (credentialResponse) => {
    googleLogin.mutate(credentialResponse);
  };

  return (
    <>
      <div className={styles.input}>
        <img className={styles['user-icon']} src={usernameIcon} alt="user-icon" />
        <input
          type="text"
          className={`form-control
          ${required ? 'border-danger form-control is-invalid' : ''}`}
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="Email Address"
        />
        <span className="invalid-feedback">Please enter a email address.</span>
      </div>

      <div className={styles.input}>
        <img className={styles['pass-icon']} src={passwordIcon} alt="user-icon" />

        <input
          type="password"
          className={`form-control mt-1
          ${required ? 'border-danger form-control is-invalid' : ''}`}
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <span className="invalid-feedback">Please enter a password.</span>
      </div>
      <div style={{ textAlign: '-webkit-center' }}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            handleGoogleLogin(credentialResponse);
          }}
          onError={() => {
            ShowToast('Failed to login', 'error');
          }}
          size
        />
      </div>
      <section className="mt-2 ml-5 mr-1">
        <small>
          <AuthForgotPassword />
        </small>
      </section>
    </>
  );
};

AuthLogin.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

export default AuthLogin;
