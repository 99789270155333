/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import AddProgramDetails from '../../components/addProgramDetails/addProgramDetails.component';
import Calendar from '../../components/calendar/calendar.component';
import CardComponent from '../../components/card/card.component';
import CardFooterComponent from '../../components/container-buttons/cardFooter.component';
import ShowToast from '../../components/ShowToast';
import TitleComponent from '../../components/title/title.component';
import { useCheckProgramDate, useGetProgramsList, useUpdateProgram } from '../../services';
import { useProgramStore, useSetTZProgram, useUserStore, useNewProgramTime } from '../../store';
import styles from './schedule.module.scss';
import startTimeArray from './scheduleData';
import Loader from '../../components/Loader';
import TimeZoneJson from '../../utils/timezone.json';
import { isMobile } from '../../utils';

dayjs.extend(utc);
dayjs.extend(tz);

const SchedulePage = () => {
  const history = useHistory();
  const timezone = useUserStore((s) => s.user.timezone);
  // const checkDate = useProgramDateCheckStore((s) => s.result)
  const checkMutation = useCheckProgramDate();
  const updateProgram = useUpdateProgram();

  const [selectedDay, setSelectedDay] = useState('');
  const [startTime, setStartTime] = useState('09:00 AM');
  const [dataToUpdate, setDataToUpdate] = useState({});

  // const selectDay = JSON.stringify(selectedDay).substring(1, 11)

  const program = useProgramStore((s) => s.program);
  const setProgram = useProgramStore((s) => s.setProgram);
  const setProgramTime = useNewProgramTime((d) => d.setNewProgramTime);
  const setTz = useSetTZProgram((s) => s.setTz);

  const query = useGetProgramsList();

  const { id, action } = useParams();
  const allProgramsList = useProgramStore((s) => s.allPrograms);
  const programToEdit = allProgramsList.filter((prog) => prog.id === id);

  useEffect(() => {}, [dataToUpdate]);

  useEffect(() => {
    if (programToEdit[0]?.time) {
      setStartTime(programToEdit[0].time);
    }

    if (programToEdit[0]?.date) {
      setSelectedDay(new Date(programToEdit[0].date));
    }
  }, [program]);

  const onPreviousClick = () => {
    if (action === 'edit') {
      history.push(`/design/edit/${id}`);
    } else {
      history.push('/design/add/new');
    }
  };

  function convertTZ(date, tzString) {
    const index = TimeZoneJson.findIndex((tzjson) => tzjson.text === tzString);
    const timeZoneee = TimeZoneJson[index].utc[0];
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
        timeZone: timeZoneee,
      })
    );
  }

  useEffect(() => {
    if (!program.name) {
      history.push('/design/add/new');
    }
  }, [history, program.name]);

  const onNextClick = () => {
    const date = new Date();
    const selectedTimeZoneCurrentTime = convertTZ(date, timezone);

    const index = TimeZoneJson.findIndex((tzjson) => tzjson.text === timezone);
    const timeZoneee = TimeZoneJson[index].utc[0];
    const timeZoneSelectedDate = dayjs(
      `${dayjs(selectedDay).format('YYYY-MM-DD')} ${startTime}`,
      'YYYY-MM-DD hh:mm A'
    ).tz(timeZoneee, true);

    if (timeZoneSelectedDate.$d.getTime() < selectedTimeZoneCurrentTime.getTime()) {
      return ShowToast('Please select a time in the future.', 'error');
    }
    if (selectedDay) {
      const data = {
        date: dayjs(selectedDay).format('YYYY-MM-DD'),
        time: startTime,
        timezone,
      };
      setProgramTime(data);
      history.push('/confirm');

      if (action === 'edit') {
        setDataToUpdate({ id, ...program, ...data });
        updateProgram.mutate({ id, ...program, ...data });
      }

      if (action === 'add') setProgram({ ...data });
    } else {
      ShowToast('Select date...', 'error');
    }
    return true;
  };
  const onUpdateTimeZone = () => {
    setTz(true);
    if (action === 'edit') {
      history.push(`/settings?programpage=${id}&edit=true`);
    } else {
      history.push(`/settings?programpage=${id}&edit=false`);
    }
  };

  return (
    <>
      {checkMutation.isLoading && <Loader />}
      <div className={`w-75 pt-3 ${styles.title}`}>
        <TitleComponent
          title="Home/ New program"
          heading="Schedule"
          subtitle="Set the date for your program"
        />
      </div>
      <div className={`container ${styles['box-shadow']}`}>
        <CardComponent>
          <>
            <AddProgramDetails h5="Date and time" />

            <div
              className={`${!isMobile && 'container'} ${styles['schedule-container']}`}
              style={{
                width: isMobile ? '100%' : '54%',
              }}>
              <Calendar selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
            </div>

            <div
              className={`container bg-light ${styles['timezone-container']}`}
              style={{
                width: isMobile ? '100%' : '54%',
              }}>
              <div className="row">
                <div className={`col-md-4 col-sm-12 ${styles['time-desc']}`}>
                  Please enter the time you intend to begin using the game in your program
                </div>
                <div
                  className={`col-md-8 col-sm-12 d-flex align-items-center border
                  ${styles['time-selector']}`}>
                  <div className="pr-1"> Start time</div>
                  <div className="border">
                    <select onChange={(e) => setStartTime(e.target.value)} value={startTime}>
                      {startTimeArray.map((e, index) => (
                        <option value={e} key={index}>
                          {e}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <div className={`pt-2 ${styles['time-desc']}`}>{timezone}</div>
                    <div className="row p-2">
                      <button
                        onClick={onUpdateTimeZone}
                        type="button"
                        className={`btn btn-sm
                       ${styles['update-timezone']}`}>
                        Update Your Timezone
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <CardFooterComponent
              previous="Previous"
              next={action === 'edit' ? 'Update' : 'Continue'}
              onPreviousClick={onPreviousClick}
              onNextClick={onNextClick}
            />
          </>
        </CardComponent>
      </div>
    </>
  );
};
SchedulePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default SchedulePage;
