// "husky": {
//   "hooks": {
//     "pre-commit": "lint-staged"
//   }
// }

/* eslint-disable */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useVerifyCertificateCode, useTokenStore, useUserStore } from './store';
import AuthPage from './pages/auth/auth.page';
import DesignPage from './pages/design/design.page';
import ConfirmPage from './pages/confirm/confirm.page';
import NotFoundPage from './pages/notFound/notFound.page';
import DemoPage from './pages/demo/demo.page';
import InvitePage from './pages/invite/invite.page';
import CompletePage from './pages/complete/complete.page';
import CertificationPage from './pages/certification/certification.page';
import ProgramInvitePage from './pages/program-invitation/programInvite.page';
import BuyCreditComponent from './components/buyCredits/buyCredits.component';
import NavbarComponent from './components/navbar/navbar.component';
import TabViewPage from './pages/tabview/tabView.page';
import SchedulePage from './pages/schedule/schedule.page';
import EmbedPage from './pages/embed/embed.page';
import MyProgramsPage from './pages/programs/program.page';
import HomePage from './pages/home/home.page';
import EditDesignPage from './pages/edit-design/editDesign.page';
import PaymentPage from './pages/payment/payment.page';
import SignOut from './pages/SignOut';
import { useUserInfo } from './services';
import CertificatePage from './pages/home/certificate.page';
import ResetPasswordPage from './pages/auth/resetPassword';
import Charts from './pages/reports/reports';
import CommunityPage from './pages/community/community.page';

const App = () => {
  const hasToken = useTokenStore((s) => !!s.accessToken);
  useUserInfo();

  const user = useUserStore((s) => s.user);
  const isRegisterationFeesPaid = user.isRegisterationFeesPaid;
  const certificateData = useVerifyCertificateCode((c) => c.certificateData);

  const routes = (
    <Switch>
      <Route path="/certification/:certificationCode" exact component={CertificationPage} />
      <Route path="/resetPassword" exact component={ResetPasswordPage} />
      <Route path="/">
        {!hasToken ? (
          <AuthPage />
        ) : (
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route
              path="/certificates"
              exact
              // component={CertificatePage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <CertificatePage />;
                return <CertificatePage />;
              }}
            />
            <Route
              path="/reports"
              exact
              // component={CertificatePage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <Charts />;
                return <Charts />;
              }}
            />
            <Route
              path="/embed"
              exact
              // component={EmbedPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <EmbedPage />;
                return <EmbedPage />;
              }}
            />
            <Route
              path="/my-programs"
              exact
              // component={MyProgramsPage}
              render={() => {
                return <MyProgramsPage />;

                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <MyProgramsPage />;
              }}
            />
            <Route
              path="/community"
              exact
              // component={MyProgramsPage}
              render={() => {
                return <CommunityPage />;

                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <MyProgramsPage />;
              }}
            />

            <Route
              path="/design/:action/:id"
              exact
              // component={DesignPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <DesignPage />;
                return <DesignPage />;
              }}
            />
            <Route
              path="/schedule/:action/:id"
              exact
              // component={SchedulePage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <SchedulePage />;
                return <SchedulePage />;
              }}
            />
            <Route
              path="/edit-design"
              exact
              // component={EditDesignPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <EditDesignPage />;
                return <EditDesignPage />;
              }}
            />
            <Route
              path="/not-found"
              exact
              // component={NotFoundPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <NotFoundPage />;
                return <NotFoundPage />;
              }}
            />
            <Route
              path="/confirm"
              exact
              // component={ConfirmPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <ConfirmPage />;
                return <ConfirmPage />;
              }}
            />
            <Route
              path="/demo"
              exact
              // component={DemoPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <DemoPage />;
                return <DemoPage />;
              }}
            />
            <Route
              path="/invite/:action"
              exact
              // component={InvitePage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <InvitePage />;
                return <InvitePage />;
              }}
            />
            <Route
              path="/complete"
              exact
              // component={CompletePage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <CompletePage />;
                return <CompletePage />;
              }}
            />
            <Route
              path="/payment"
              exact
              // component={PaymentPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <PaymentPage />;
                return <PaymentPage />;
              }}
            />
            <Route
              path="/program-invite"
              exact
              // component={ProgramInvitePage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <ProgramInvitePage />;
                return <ProgramInvitePage />;
              }}
            />
            <Route
              path="/profile"
              exact
              // component={TabViewPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <TabViewPage />;
                return <TabViewPage />;
              }}
            />
            <Route
              path="/settings"
              exact
              // component={TabViewPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <TabViewPage />;
                return <TabViewPage />;
              }}
            />
            <Route
              path="/resources"
              exact
              // component={TabViewPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <TabViewPage />;
                return <TabViewPage />;
              }}
            />
            <Route
              path="/roadmap"
              exact
              // component={TabViewPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <TabViewPage />;
                return <TabViewPage />;
              }}
            />
            <Route
              path="/transactions"
              exact
              // component={TabViewPage}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <TabViewPage />;
                return <TabViewPage />;
              }}
            />
            <Route
              path="/buy-credits"
              exact
              // component={BuyCreditComponent}
              render={() => {
                // return !isRegisterationFeesPaid ? <Redirect to="/" /> : <BuyCreditComponent />;
                return <BuyCreditComponent />;
              }}
            />
            <Route path="/signout" exact component={SignOut} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        )}
      </Route>
    </Switch>
  );

  return (
    <div>
      <Helmet>
        <meta property="og:image" content={certificateData.certificate_image_url} />
      </Helmet>
      <NavbarComponent />
      {routes}
    </div>
  );
};

export default App;
