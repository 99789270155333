import React from 'react';
import PropTypes from 'prop-types';

import increment from '../../assets/design/increment.png';
import decrement from '../../assets/design/decrement.png';
import styles from './counter.module.scss';
import { isMobile } from '../../utils';

const Counter = ({ incNum, num, decNum }) => {
  return (
    <>
      <div className={`row d-flex ${isMobile && 'justify-content-center'}`}>
        <div
          className={`d-inline-flex
${styles['counter-border']}`}>
          <div>
            <button type="button" className={`btn ${styles['button-border']}`} onClick={decNum}>
              <img style={{ filter: 'brightness(50%)' }} src={decrement} alt="increment-icon" />
            </button>
          </div>
          <div className={` ${styles['inside-border']}`}>{num}</div>
          <div>
            <button type="button" className={`btn ${styles['button-border']}`} onClick={incNum}>
              <img src={increment} alt="increment-icon" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
Counter.propTypes = {
  incNum: PropTypes.func.isRequired,
  num: PropTypes.number.isRequired,
  decNum: PropTypes.func.isRequired,
};
export default Counter;
