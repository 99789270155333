/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable */
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState, createRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';
import AddFacilitators from '../../assets/invite/add-facilitators.png';
import SubFacilitators from '../../assets/invite/sub-facilitators.png';
import CardComponent from '../../components/card/card.component';
import ShowToast from '../../components/ShowToast';
import TitleComponent from '../../components/title/title.component';
import BuyCreditComponent from '../../components/buyCredits/buyCredits.component';
import {
  useAddProgram,
  useUserSearch,
  useGetAllFacilitator,
  useUpdateFacilitator,
  useCheckProgramDate,
  useGetUserByEmail,
  useGetCreditsByUserId,
} from '../../services';
import {
  useProgramStore,
  useUserStore,
  useFacilitatorStore,
  useNewProgramTime,
  useSelectedFacilitator,
  useCreditStore,
} from '../../store';
import { DateFunction, GameAccessEnum, isMobile, useDebounce, validateEmail } from '../../utils';
import styles from './invite.module.scss';
import Loader from '../../components/Loader';
import GstModal from '../../components/gstModal/gstModal';

const InvitePage = () => {
  const history = useHistory();

  const [date, setDate] = useState();
  const [showModal, setShowModal] = useState('');
  const [check, setCheck] = useState(false);
  const [gstNum, setGstNum] = useState('');
  const [stateList, setStateList] = useState('');
  const [radioInput, setRadioInput] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [showBuyCredit, setShowBuyCredit] = useState(false);
  const [facilitator, setFacilitator] = useState([]);
  const [searchedFacil, setSearchFacil] = useState({});
  const [isFacilFound, setIsFacilFound] = useState(false);
  const [extraCreditPopup, setExtraCreditPopup] = useState(false);
  const [selected, setSelected] = useState();
  const [search, setSearch] = useState();
  const [users, setUsers] = useState([{ id: 'test', name: 'test', value: 'test', label: 'test' }]);
  const { action } = useParams();
  useGetAllFacilitator();
  const facilitators = useFacilitatorStore().facilitatorsList;

  const checkMutation = useCheckProgramDate();

  const Gstuser = useCreditStore((g) => g.isGst);
  const Gstdate = useCreditStore((g) => g.gstDate);
  const currentDate = new Date(Date.now()).toString();

  const selectedFacilt = useSelectedFacilitator((f) => f.selectedFacil);
  const removeSelectedFacilt = useSelectedFacilitator((f) => f.removeSelectedFacil);

  useEffect(() => {
    if (Object.keys(selectedFacilt).length > 0) {
      setIsFacilFound(true);
      setSearchFacil(selectedFacilt);
    }
  }, [selectedFacilt]);

  const newArray = [];

  const user = useUserStore((s) => s.user);
  facilitators.map((facil) =>
    facil.is_Certified || facil.id == user.id
      ? newArray.push({ ...facil, label: facil.email, value: facil.id })
      : null
  );

  const updatedData = useGetCreditsByUserId();

  const program = useProgramStore((s) => s.program);
  const setProgram = useProgramStore((s) => s.setProgram);

  const programTime = useNewProgramTime((d) => d.data);
  const half_cr = useCreditStore((s) => s.half_cr);
  const full_cr = useCreditStore((s) => s.full_cr);

  const availableCredits = program.game_access === GameAccessEnum.HALF ? half_cr : full_cr;

  const data = useDebounce(search, 1000);
  const query = useUserSearch(data, setUsers);

  const addProgram = useAddProgram();
  const updateFacilitator = useUpdateFacilitator();

  const addFacil = useGetUserByEmail();

  useLayoutEffect(() => {
    if (!program.name) {
      history.push('/');
    }
  }, [history, program]);

  useEffect(() => {}, [updatedData.isLoading]);

  useEffect(() => {
    if (Object.keys(program).length < 1) {
      return history.push('/');
    }
    if (program.facilitatorids) {
      const createFacilitatorsFromIds = [];
      program.facilitatorids.map((facilId) => {
        const index = newArray.findIndex((facil) => facil.id === facilId);
        if (index > -1) {
          createFacilitatorsFromIds.push({
            label: newArray[index].email,
            value: newArray[index].id,
          });
        }
        return null;
      });

      setProgram({
        facilitators: createFacilitatorsFromIds,
      });
      setFacilitator(createFacilitatorsFromIds);
    } else {
      let temp = [];
      program.facilitators.forEach((val) => {
        if (facilitators && facilitators.length) {
          let isFacilatorCertified = facilitators.find((value) => value.id === val.value);

          if (
            (isFacilatorCertified && isFacilatorCertified.is_Certified) ||
            isFacilatorCertified.id == user.id
          ) {
            temp.push(val);
          }
        }
      });

      setFacilitator(temp);
    }
  }, [program.facilitatorids, facilitators]);

  const onNextClick = () => {
    if (action === 'edit') {
      if (program.fac_count < facilitator.length) {
        setExtraCreditPopup(true);

        return ShowToast(
          'You can only invite as many facilitators as the game accesses you have activated for this program. ',
          'error'
        );
      }

      const dataa = {
        id: program.id,
        facilitators: facilitator,
        totalFacilLength: facilitator.length,
        userId: user.id,
      };
      updateFacilitator.mutate(dataa);
    } else {
      const facIds = program.facilitators.map((d) => d.value);
      if (facIds.length < 1) return ShowToast('Please add atleast one facilitator', 'error');

      const progrmaDateCheckData = {
        facilitators: facIds,
        ...programTime,
      };

      checkMutation.mutate(progrmaDateCheckData);

      if (facIds.length > program.fac_count) {
        setExtraCreditPopup(true);
        return ShowToast(
          'You can only invite as many facilitators as the game accesses you have activated for this program. ',
          'error'
        );
      }

      return addProgram.mutate({
        name: program.name,
        description: program.description,
        date: program.date,
        time: program.time,
        game_access: program.game_access,
        facilitators: facIds,
        timezone: program.timezone,
        fac_count: program.fac_count.toString(),
        ...programTime,
      });
    }
    return null;
  };

  const addSelected = () => {
    if (!isFacilFound) {
      return ShowToast(
        "The email is not linked to any certified facilitator's account. Please check the email and try again",
        'error'
      );
    }

    // if (Object.keys(selectedFacilt).length < 1) {
    //   return ShowToast('No Facilitator found', 'error');
    // }

    const index = program.facilitators.findIndex((d) => d.label === searchedFacil.email);

    const facilData = { value: searchedFacil.id, label: searchedFacil.email };

    if (index === -1) {
      setProgram({
        facilitators: [...program.facilitators, facilData],
      });
      setFacilitator([...program.facilitators, facilData]);
      emailField.current.value = '';
    } else {
      ShowToast('Facilitator already added.', 'info');
    }
    // setSelected('');
    return 0;
  };

  const handleSearchFacil = (e) => {
    setIsFacilFound(false);
    setSearchFacil(e.target.value);
    if (validateEmail(e.target.value)) {
      addFacil.mutate({ email: e.target.value });
    } else {
      removeSelectedFacilt();
    }
  };
  let emailField = createRef();
  const diff = DateFunction(currentDate, Gstdate);
  useEffect(() => {
    if (diff > 30) {
      setShowModal(true);
      setCheck(false);
      setDate('');
    }
  }, [diff]);
  return (
    <>
      {extraCreditPopup && (
        <>
          <div className={styles.modal}>
            <div style={{ display: 'flex', paddingLeft: '28%' }}>
              <h3 style={{ fontWeight: '900' }}>Add Extra Facilitators?</h3>
              <p
                onClick={() => setExtraCreditPopup(false)}
                style={{ paddingLeft: '27%', cursor: 'pointer' }}>
                X
              </p>
            </div>
            <p style={{ fontSize: '20px' }}>
              We noticed you're trying to add more facilitators to this program. Would you like to
              use/buy credits to complete this action?
            </p>
            <button
              disabled={facilitator.length > availableCredits ? true : false}
              type="button"
              // style={{ transform: ' translate(1px, 39px)' }}
              className="btn btn-secondary"
              onClick={() => {
                setProgram({ ...program, fac_count: facilitator.length });
                setExtraCreditPopup(false);
              }}>
              Use Credits
            </button>{' '}
            <button
              type="button"
              // style={{ transform: ' translate(1px, 39px)' }}
              className="btn btn-secondary"
              onClick={() => {
                setExtraCreditPopup(false);
                !Gstuser || showModal ? setOpenModal(true) : setShowBuyCredit(!showBuyCredit);
              }}>
              Buy Credits
            </button>
            {/* <p style={{ fontSize: '12px', marginTop: '10px' }}>
              Complete the certification within 39 days to earn a free Half-Day Evivve credit worth
              $125
            </p> */}
          </div>
        </>
      )}
      {addProgram.isLoading && <Loader />}
      <div className={`w-75 pt-3   ${styles.title}`}>
        <TitleComponent
          title="Home/ New program"
          heading="Invite"
          subtitle="Facilitators that will co-facilitate with you"
        />
      </div>

      <div className={`container ${styles['box-shadow']}`}>
        <div
          onClick={() => {
            if (showBuyCredit) setShowBuyCredit(false);
          }}>
          <CardComponent>
            <>
              <h5 className="font-weight-bold p-2"> Program Details </h5>
              <div className="ml-4">
                <h6>
                  Program name :<strong>{program.name}</strong>
                </h6>

                <h6>
                  Scheduled date and time :
                  <strong>{`${dayjs(program.date).format('DD-MMM-YYYY')}, ${program.time}`}</strong>
                  <strong>{program.timezone}`</strong>
                </h6>

                <h6>
                  Game Access :
                  <strong>
                    {program.game_access === GameAccessEnum.HALF ? 'HALF DAY' : 'FULL DAY'}
                  </strong>
                </h6>
                <h6>
                  Number of Game Accesses :<strong>{program.fac_count}</strong>
                </h6>
              </div>

              <div className="border m-3" />

              <strong> Add facilitators to this program</strong>

              <div className="container">
                <div className="row">
                  <div className="col">
                    {facilitator
                      ? facilitator.map((f, i) => (
                          <div className="row" style={{ marginBottom: '16px' }} key={f.value}>
                            <div className="col-md col-sm-12 font-weight-bold">Facilitator {i + 1}</div>
                            <div className="col-md-7 col-sm-12">
                              <b>{f.label}</b>
                            </div>
                            <div className="col">
                              <button
                                type="button"
                                style={{ display: 'flex', alignItems: 'center' }}
                                className={`btn ${styles['edit-border']}`}
                                // disabled={f.value === user.id}
                                onClick={() => {
                                  setProgram({
                                    facilitators: program.facilitators.filter(
                                      (p) => p.value !== f.value
                                    ),
                                  });
                                  setFacilitator(facilitator.filter((p) => p.value !== f.value));
                                }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div style={{ fontWeight: '700', fontSize: '25px' }}>-</div>
                                  <div style={{ marginLeft: '10px', marginTop: '15px' }}>
                                    <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Remove</p>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        ))
                      : null}

                    {/* {program.facilitators.length < program.fac_count && ( */}
                    <div className="row" style={{ marginBottom: '10px' }}>
                      <div className=" col-sm-12 col-md">Add Facilitator</div>
                      <div className={`col-md-7 col-sm-12 ${isMobile && ' mb-3'}`}>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmail"
                          placeholder="Search facilitator by email..."
                          style={{ backgroundColor: 'white' }}
                          ref={emailField}
                          onChange={handleSearchFacil}
                        />
                      </div>
                      <div className="col-md col-sm-12">
                        <button
                          disabled={validateEmail(searchedFacil) ? false : true}
                          type="button"
                          style={{ display: 'flex', alignItems: 'center', width: '110px' }}
                          className={
                            isFacilFound
                              ? `btn ${styles['edit-border-background']}`
                              : `btn ${styles['edit-border']}`
                          }
                          onClick={addSelected}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                              style={{ fontWeight: '700', fontSize: '25px', marginRight: '12px' }}>
                              +
                            </div>
                            <div style={{ marginLeft: '10px', marginTop: '15px' }}>
                              <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Add</p>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <strong>Please note: </strong>
                    <p>
                      You will not be able to invite facilitators that are not certified to run
                      Evivve- The Leadership Game
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-3 float-right">
                <button
                  type="button"
                  onClick={onNextClick}
                  className="btn btn-secondary w-100 ml-3 mr-3">
                  {action === 'edit' ? 'Update' : 'Finish'}
                </button>
              </div>
            </>
          </CardComponent>
        </div>
      </div>
      <div
       style={{
        width: isMobile ? '90%' : '40%',
      }}
        className={`${styles['navigation-bar-menu']}
                        ${showBuyCredit ? styles.active : ''}`}>
        {showBuyCredit && (
          <BuyCreditComponent
            halfCr={half_cr}
            fullCr={full_cr}
            radioInput={radioInput}
            stateList={stateList}
            gstNum={gstNum}
          />
        )}
      </div>
      <GstModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setShowBuyCredit={setShowBuyCredit}
        showBuyCredit={showBuyCredit}
        radioInput={radioInput}
        setRadioInput={setRadioInput}
        stateList={stateList}
        setStateList={setStateList}
        gstNum={gstNum}
        setGstNum={setGstNum}
        setCheck={setCheck}
        check={check}
        date={date}
        setDate={setDate}
      />
    </>
  );
};
InvitePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default InvitePage;
