/* eslint-disable dot-notation */
import axios from 'axios';
import { useTokenStore } from '../store';


// const apiURL = `http://localhost:4000/api`;
const apiURL = `${process.env.REACT_APP_GAMITAR_BACKEND}/api`;


export default function axiosClient(paramsToken) {
  const defaultOptions = {
    withCredentials: true,
    baseURL: apiURL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token || paramsToken;
    return config;
  });

  instance.defaults.headers.common['Authorization'] =
    useTokenStore.getState().accessToken || paramsToken;

  return instance;
}
