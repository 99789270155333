import PropTypes from 'prop-types';
import React from 'react';
import backgroundLeft from '../../assets/auth/background-left.png';
import backgroundRight from '../../assets/auth/background-right.png';
import backgroundTop from '../../assets/auth/background-top.png';
import teamworkLower from '../../assets/auth/teamwork-lower.png';
import teamworkUpper from '../../assets/auth/teamwork-upper.png';
import teamworkLowerl from '../../assets/auth/teamwork-lower-l.png';
import teamworkUpperl from '../../assets/auth/teamwork-upper-l.png';
import newBackground from '../../assets/auth/new_background.png';

import logo from '../../assets/evivve-icon.png';
import styles from './auth.module.scss';
import AuthFormContainer from './formContainer.auth';

const bgImages = [
  // {
  //   id: 'background-top',
  //   className: 'background-top',
  //   alt: 'background-top',
  //   src: backgroundTop,
  // },
  // {
  //   id: 'background-left',
  //   className: 'background-left',
  //   alt: 'background-left',
  //   src: backgroundLeft,
  // },
  // {
  //   id: 'background-right',
  //   className: 'background-right',
  //   alt: 'right-background',
  //   src: backgroundRight;
  // },
  {
    id: 'background-lower',
    className: 'teamwork-lower',
    alt: 'teamwork-lower',
    src: teamworkLower,
  },
  {
    id: 'background-upper',
    className: 'teamwork-upper',
    alt: 'teamwork-upper',
    src: teamworkUpper,
  },
  {
    id: 'background-lower-l',
    className: 'teamwork-lower-l',
    alt: 'teamwork-lower-l',
    src: teamworkLowerl,
  },
  {
    id: 'background-upper-l',
    className: 'teamwork-upper-l',
    alt: 'teamwork-upper-l',
    src: teamworkUpperl,
  },
];
const mainLogo = [
  {
    id: 'mainLogo',
    className: 'main-logo',
    alt: 'Evivve Logo',
    src: logo,
  },
];

const AuthPage = () => {
  return (
    <div className={styles['bg-image']}>
      {mainLogo.map((e) => (
        <img className={styles[e.className]} key={e.id} alt={e.id} id={e.id} src={e.src} />
      ))}

      {/* <img src={newBackground} alt="tasveer" className="teamwork-upper-l" /> */}

      {/* {bgImages.map((e) => (
        <img className={styles[e.className]} key={e.id} alt={e.id} id={e.id} src={e.src} />
      ))} */}

      <AuthFormContainer />
    </div>
  );
};

export default AuthPage;
