/* eslint-disable max-len */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useQuery } from 'react-query';
import styles from './transactions.module.scss';
import TitleComponent from '../title/title.component';
import CardComponent from '../card/card.component';
import dayjs from 'dayjs';

import BuyCreditComponent from '../buyCredits/buyCredits.component';
import {
  useCreditStore,
  useFacilitatorStore,
  useUserTransactions,
  useSelectedFacilitator,
  useUserStore,
} from '../../store';
import {
  useGetCreditsByUserId,
  useGetAllFacilitator,
  useGetUserTransactions,
  useTransferCredits,
  useGetUserByEmail,
} from '../../services';
import { DateFunction, isMobile, validateEmail } from '../../utils';
import ShowToast from '../ShowToast';
import Loader from '../Loader';
import InvoiceTemplate from '../../assets/Invoice_Template.pdf';
import DownloadInvoice from '../../utils/downloadInvoice';
import GstModal from '../gstModal/gstModal';

const TransactionsComponent = () => {
  const [date, setDate] = useState();
  const [showModal, setShowModal] = useState('');
  const [check, setCheck] = useState(false);
  const [gstNum, setGstNum] = useState('');
  const [stateList, setStateList] = useState('');
  const [radioInput, setRadioInput] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [shareAbleUrl, setShareAbleUrl] = useState(InvoiceTemplate);
  const user = useUserStore((s) => s.user);
  const [qty, setQty] = useState();
  const [timeZone, setTimeZone] = useState(user?.timezone);
  const [showBuyCredit, setShowBuyCredit] = useState(false);
  const [isFacilFound, setIsFacilFound] = useState(false);
  const [selected, setSelected] = useState();
  const [searchedFacil, setSearchFacil] = useState({});
  const [numberOfCredits, setNumberOfCredits] = useState();
  const [selectedCredit, setSelectedCredit] = useState();
  const queryCredit = useGetCreditsByUserId();
  const queryTrans = useGetUserTransactions();
  const queryFacil = useGetAllFacilitator();

  const addFacil = useGetUserByEmail();
  const removeSelectedFacilt = useSelectedFacilitator((f) => f.removeSelectedFacil);
  const selectedFacilt = useSelectedFacilitator((f) => f.selectedFacil);

  const { refetch } = queryTrans;

  useEffect(() => {
    refetch();
  }, []);

  const halfCr = useCreditStore((s) => s.half_cr);
  const fullCr = useCreditStore((s) => s.full_cr);
  const Gstuser = useCreditStore((g) => g.isGst);
  const Gstdate = useCreditStore((g) => g.gstDate);
  const currentDate = new Date(Date.now()).toString();
  const [stateHalfCr, setStateHalfCr] = useState(halfCr);
  const [stateFullCr, setStateFullCr] = useState(fullCr);

  useEffect(() => {
    setStateHalfCr(halfCr);
    setStateFullCr(fullCr);
  }, [halfCr, fullCr]);

  useEffect(() => {
    if (Object.keys(selectedFacilt).length > 0) {
      setIsFacilFound(true);
      setSearchFacil(selectedFacilt);
      setSelected(selectedFacilt);
    }
  }, [selectedFacilt]);

  const facilitators = useFacilitatorStore().facilitatorsList;
  const transactions = useUserTransactions().transactionList;

  const transfer = useTransferCredits();
  const newArray = [];

  const creditsOption = [
    {
      label: 'Half Credit',
      value: 'HALF',
    },
    {
      label: 'Full Credit',
      value: 'FULL',
    },
  ];

  facilitators.map((facil) =>
    facil.is_Certified ? newArray.push({ ...facil, label: facil.email, value: facil.id }) : null
  );

  const handleTransfer = async () => {
    if (!selected) {
      ShowToast(
        'Please enter the email address of a certified facilitator to transfer credits',
        'error'
      );
      return null;
    }
    if (!selectedCredit) {
      ShowToast('Please select credit type', 'error');
      return null;
    }
    if (!numberOfCredits) {
      ShowToast('Please select number of credits', 'error');
      return null;
    }

    if (selectedCredit.value === 'FULL' && numberOfCredits > fullCr) {
      ShowToast('Can not select more than available credits', 'error');
      return null;
    }

    if (selectedCredit.value === 'HALF' && numberOfCredits > halfCr) {
      ShowToast('Can not select more than available credits', 'error');
      return null;
    }

    if (numberOfCredits < 1) {
      ShowToast('Please select valid credits', 'error');
      return null;
    }

    var data = {
      email: selected.email,
      credit_type: selectedCredit.value,
      credit: parseInt(numberOfCredits),
    };

    setSelected(undefined);
    setNumberOfCredits(undefined);
    setSelectedCredit(undefined);

    await transfer.mutate(data);

    if (selectedCredit.value === 'FULL') {
      setStateFullCr(stateFullCr - numberOfCredits);
    }

    if (selectedCredit.value === 'HALF') {
      setStateHalfCr(stateHalfCr - numberOfCredits);
    }

    setSelected(null);
    setSelectedCredit(null);
    setNumberOfCredits(0);

    await refetch();
    await refetch();
    await refetch();
    await refetch();
    // await refetch();
  };

  const handleSearchFacil = (e) => {
    setIsFacilFound(false);
    setSearchFacil(e.target.value);
    if (validateEmail(e.target.value)) {
      addFacil.mutate({ email: e.target.value });
    } else {
      removeSelectedFacilt();
    }
  };
  const diff = DateFunction(currentDate, Gstdate);

  useEffect(() => {
    if (diff > 30) {
      setCheck(false);
      setShowModal(true);
      setDate('');
    }
  }, [diff]);
  return (
    <>
      {queryCredit.isLoading ? (
        <Loader />
      ) : queryTrans.isLoading ? (
        <Loader />
      ) : queryFacil.isLoading ? (
        <Loader />
      ) : queryTrans.isLoading ? (
        <Loader />
      ) : null}
      <div className={`w-75 pt-3 ${styles.title}`}>
        <TitleComponent title="Home/ Transactions" />
      </div>
      <div
        className={`container ${styles['box-shadow']}`}
        onClick={() => {
          if (showBuyCredit) setShowBuyCredit(false);
        }}>
        <CardComponent>
          <>
            <div className="container">
              <div className="row" style={{ paddingBottom: '8px' }}>
                <div className="col-md-8 col-sm-12">
                  <h6>
                    <strong>Transfer Credits</strong>
                  </h6>

                  <div className="row">
                    <div className="col-md-4 col-sm-12 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail"
                        placeholder="Search facilitator by email..."
                        style={{ backgroundColor: 'white' }}
                        onChange={handleSearchFacil}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 mt-3">
                      <Select
                        placeholder="Select Credit"
                        options={creditsOption}
                        // isLoading={query.isLoading}
                        isClearable
                        isSearchable
                        value={selectedCredit}
                        // onInputChange={(v) => setSearch(v)}
                        onChange={(v) => setSelectedCredit(v)}
                      />
                    </div>
                    <div className="col-md-1 mt-3 col-sm-12">
                      <input
                        type="Number"
                        value={numberOfCredits}
                        style={{ width: '100%', height: 35 }}
                        onChange={(e) => setNumberOfCredits(e.target.value)}
                        placeholder={"Number of credits to transfer"}
                      />
                    </div>

                    <div className="col-md-3 col-sm-12 mt-3">
                      <button
                        disabled={validateEmail(searchedFacil) ? false : true}
                        type="submit"
                        // className="btn btn-secondary"
                        style={{ width: '100%' }}
                        className={
                          isFacilFound ? `btn btn-secondary` : `btn ${styles['transfer-btn']}`
                        }
                        onClick={handleTransfer}>
                        Transfer
                      </button>
                    </div>
                  </div>
                </div>
                {console.log('isMobile', isMobile)}
                <div className="col-md-4 col-sm-12 mt-3">
                  <div className="row">
                    <h6
                      className={`col-12 ${
                        isMobile ? 'd-flex flex-column ' : 'd-flex justify-content-end'
                      }`}>
                      <div className="d-flex">
                        <strong>Half Day Credits : </strong>
                        {stateHalfCr}
                      </div>
                      <div className="d-flex">
                        <strong> Full Day Credits : </strong>
                        {stateFullCr}
                      </div>
                    </h6>
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-end">
                      <button
                        // onClick={() => setShowBuyCredit(!showBuyCredit)}
                        onClick={() =>
                          !Gstuser || showModal
                            ? setOpenModal(true)
                            : setShowBuyCredit(!showBuyCredit)
                        }
                        type="button"
                        style={isMobile ? { width: '100%' } : {}}
                        className="btn btn-secondary">
                        Buy Credits
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
               style={{
                width: isMobile ? '90%' : '40%',
              }}
                className={`${styles['navigation-bar-menu']}
                        ${showBuyCredit ? styles.active : ''}`}>
                {showBuyCredit && (
                  <BuyCreditComponent
                    halfCr={halfCr}
                    fullCr={fullCr}
                    radioInput={radioInput}
                    stateList={stateList}
                    gstNum={gstNum}
                  />
                )}
              </div>
              <hr className="mt-3 mb-4" />
              <h6>
                <strong>Your Recent Transactions</strong>
              </h6>

              <div
                style={{
                  maxHeight: '300px',
                  overflow: 'auto',
                  background: 'white',
                  padding: '10px',
                  ...(isMobile && { zoom: 0.5 }),
                }}>
                {transactions.map((trans) =>
                  trans.half_credits > 0 || trans.full_credits > 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '0.5rem 0',
                      }}>
                      <p>
                        You {''}
                        {trans.trans_type === 'BUY'
                          ? 'bought'
                          : trans.trans_type === 'USE'
                          ? 'used'
                          : trans.trans_type === 'TRF_OUT'
                          ? 'transfered'
                          : trans.trans_type === 'TRF_IN'
                          ? 'received'
                          : trans.trans_type === 'REW'
                          ? 'earned'
                          : null}{' '}
                        {trans.half_credits > 0 ? trans.half_credits : trans.full_credits}{' '}
                        {trans.half_credits > 0 ? 'Half-Day Credits' : 'Full-Day Credits'} on{' '}
                        {dayjs(trans.created_at).format('DD MMM, YYYY')}
                        {trans.creditSenderEmail
                          ? ` from ${trans.creditSenderEmail}`
                          : trans.creditReceiverEmail
                          ? ` to ${trans.creditReceiverEmail}`
                          : null}
                      </p>
                      {trans.trans_type === 'BUY' ? (
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            DownloadInvoice(shareAbleUrl, setShareAbleUrl, timeZone, user, trans);
                          }}>
                          {' '}
                          VIEW INVOICE
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '0.5rem 0',
                      }}>
                      <p>
                        You Paid $99 Certification Fee on{' '}
                        {dayjs(trans.created_at).format('DD MMM, YYYY')}
                      </p>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          DownloadInvoice(shareAbleUrl, setShareAbleUrl, timeZone, user, trans);
                        }}>
                        {' '}
                        VIEW INVOICE
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        </CardComponent>
      </div>
      <GstModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setShowBuyCredit={setShowBuyCredit}
        showBuyCredit={showBuyCredit}
        radioInput={radioInput}
        setRadioInput={setRadioInput}
        stateList={stateList}
        setStateList={setStateList}
        gstNum={gstNum}
        setGstNum={setGstNum}
        setCheck={setCheck}
        check={check}
        date={date}
        setDate={setDate}
      />
    </>
  );
};
export default TransactionsComponent;
