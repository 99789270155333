import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './demo.module.scss';
import demoData from './demoData';
import TitleComponent from '../../components/title/title.component';
import CardComponent from '../../components/card/card.component';

const DemoPage = () => {

  const history = useHistory()

  const onNextClick = () => {
    history.push('/embed');
  };
  return (
    <>
      <div className={`w-75 pt-3 ml-5  ${styles.title}`}>
        <TitleComponent
          title="Home/ New program"
          heading="Demo"
          subtitle="Instructions and Important Information"
        />
      </div>

      <div className={`container col-9 mt-4 ${styles['box-shadow']}`}>
        <CardComponent>
          <>
            <div className="col-12">
              {demoData.map((Data) => (
                <p>{Data}</p>
              ))}
            </div>
            <div className="p-5">
              <button
                type="button"
                onClick={onNextClick}
                className={`btn btn-secondary float-right ${styles['demo-button']}`}>
                Continue
              </button>
            </div>
          </>
        </CardComponent>
      </div>
    </>
  );
};
DemoPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default DemoPage;
