/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Countdown from 'react-countdown';
// import Confetti from 'react-confetti';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import styles from './home.module.scss';
import BackgroundImage from '../../assets/home/backgroundimage.png';
import CreateProgram from '../../assets/home/createprogram.png';
import Loader from '../../components/Loader';
import Confetti from './Confetti';
import {
  useGetProgramsList,
  useGetCertificationCode,
  useValidateReward,
  useGetRegisterationFees,
  useValidateReg,
} from '../../services';
import { useUserStore, useCertificationMessage } from '../../store';
import ShowToast from '../../components/ShowToast';
import { isMobile } from '../../utils';
import WarnIcon from '../../assets/program/info.png';

const HomePage = () =>
{
  const history = useHistory();
  const [button3, setButton3] = useState('');
  const [button2, setButton2] = useState('');
  const [button1, setButton1] = useState('');
  const [button1Link, setButton1Link] = useState('');
  const [button2Link, setButton2Link] = useState('');
  const [isRewardChecked, setIsRewardChecked] = useState(false);

  const regFees = useGetRegisterationFees();

  const user = useUserStore((s) => s.user);
  const message = useCertificationMessage((c) => c.message);
  const heading = useCertificationMessage((c) => c.heading);
  const congModel = useCertificationMessage((c) => c.congModel);
  const regModel = useCertificationMessage((c) => c.regModel);
  const [videoFinished, setVideoFinish] = useState(true);

  const validateRegistration = useValidateReg();
  const parsed = queryString.parse(window.location.search);
  console.log('locationlocation', window.location);

  useEffect(() => {
    if (Object.keys(parsed).includes(`pmt_complete_67fssf9fhi72ds7642`)) {
      const userId = localStorage.getItem('userId');
      validateRegistration.mutate({
        userId,
      });
    }
  }, []);

  useEffect(() => {
    if (congModel) {
      ShowToast('Congratulations!', 'success');
    }
  }, [congModel]);

  useEffect(() =>
  {
    console.log('inside homeeeepahaslkdajsd');

    if (
      !user.isRegisterationFeesPaid &&
      user.id &&
      !Object.keys(parsed).includes(`pmt_complete_67fssf9fhi72ds7642`)
    ) {
      ShowToast('Please complete the payment in order to begin your certification program', 'info');
      useCertificationMessage.getState().setRegModel(true);
      // regFees.mutate();
    }
  }, [user]);

  const query = useGetProgramsList();
  localStorage.setItem('programsLength', query.data?.data.result.length);
  const programsLength = localStorage.getItem('programsLength');

  const cc = useGetCertificationCode();
  const certificCode = cc?.data?.Certificationcoderesp.Code;
  const onCreateClick = () => history.push('/design/add/new');
  const onContinueCertification = () => {
    window.open(`https://app.eduflow.com/join/${certificCode}`, '_blank');
  };
  const goToResourceCenter = () => {
    window.open(`https://gamitar.freshdesk.com/support/home`, '_blank');
  };
  const onHostClick = () => {
    if (document.body.getAttribute('evivve_extension_exist') === 'yes') {
      window.postMessage({
        url: `${process.env.REACT_APP_GAME_APP}?code=demoHost&id=${localStorage.getItem('userId')}`,
        type: 'FROM_PAGE',
        my_space: 'evivve_sample_extension',
      });
      // window.open(`${process.env.REACT_APP_GAME_APP}?code=demoHost&id=${localStorage.getItem("userId")}`, "popup", 'height=1600,width=1800,resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes')
    } else {
      alert(
        'You need the Evivve extension to host the game. Please unblock the pop-up and try again. If you have already installed the extension, reload this page'
      );
      window.open(
        'https://chrome.google.com/webstore/detail/evivve-the-leadership-gam/gnlmlciclhcodcgikaoedgjeeggaoipc',
        '_blank'
      );
    }
  };
  const onDemoClick = () => {
    if (document.body.getAttribute('evivve_extension_exist') === 'yes') {
      window.postMessage({
        url: `${process.env.REACT_APP_GAME_APP}?code=demoHost&id=${localStorage.getItem('userId')}`,
        type: 'FROM_PAGE',
        my_space: 'evivve_sample_extension',
      });
      // window.open(`${process.env.REACT_APP_GAME_APP}?code=demoHost&id=${localStorage.getItem("userId")}`, "popup", 'height=1600,width=1800,resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes')
    } else {
      alert(
        'You need the Evivve extension to host the game. Please unblock the pop-up and try again. If you have already installed the extension, reload this page'
      );
      window.open(
        'https://chrome.google.com/webstore/detail/evivve-the-leadership-gam/gnlmlciclhcodcgikaoedgjeeggaoipc',
        '_blank'
      );
    }
    // const randomString = Math.random().toString(36).substring(4);
    // window.open(
    //   `https://staging.evivve.com?code=${randomString}&id=${localStorage.getItem('userId')}`,
    //   '_blank'
    // );
  };

  const validateReward = useValidateReward();

  useEffect(() => {
    // validateReward.mutate({
    //   completeCertificationTime: dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
    // });

    if (parsed.dateAndTime && !isRewardChecked) {
      setIsRewardChecked(true);
      validateReward.mutate({
        completeCertificationTime: dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
      });
    }
  }, []);
  const Completionist = () => <span> </span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) =>
    // Render a completed state
    completed ? (
      <Completionist />
    ) : (
      <span
        style={{
          display: 'flex',
          backgroundColor: '#A135ff',
        }}>
        {/* <center> */}
        <div
          style={{
            display: 'flex',
            backgroundColor: '#f67598',
            padding: '10px 20px',
            color: 'f67598',
            marginRight: 5,
          }}>
          <div style={{ paddingRight: '15px' }}>
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{days}</div>
            <div>DAYS</div>
          </div>
          <div style={{ paddingRight: '15px' }}>
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{hours}</div>
            <div>HOURS</div>
          </div>
          <div style={{ paddingRight: '15px' }}>
            {' '}
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{minutes}</div>
            <div>MINUTES</div>
          </div>
          <div>
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{seconds}</div>
            <div>SECONDS</div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '17pt',
            width: '100%',
            color: 'white',
            fontWeight: 'bold',
          }}>
          <center>
            Complete your certification in time to earn a free Half-Day Evivve credit{' '}
          </center>
          <div className="tooltips">
            <img
              className="float-right"
              src={WarnIcon}
              alt="complete-icon"
              style={{ width: '20px', marginLeft: 10, position: 'relative' }}
              // onClick={() => setEdit(true)}
              onClick={() => {
                history.push('/settings');
              }}
            />
            <div style={{ width: 200, borderRadius: 4, fontSize: 12 }} className="tooltiptext">
              Credits are used to facilitate games with advanced settings. Click here to see Game
              Settings.
            </div>
          </div>
        </div>
        {/* </center> */}
      </span>
    );
  useEffect(() => {
    if (!query.isLoading) {
      if (programsLength && programsLength > 0 && user.is_Certified)
        return history.push('/my-programs');
      // my programs

      if (user.is_Certified) {
        localStorage.setItem('isCertified', true);
        if (!query.isLoading && query.data?.data.result.length > 0) {
          localStorage.setItem('programsLength', query.data?.data.result.length);
          return history.push('/my-programs');
          // my programs
        }
        if (!query.isLoading && query.data?.data.result.length === 0) {
          setButton3('Resource Center');
        }
        setButton1('Create Program');
        setButton1Link('create');
        setButton2('Demo Game');
        setButton2Link('demo');
        setVideoFinish(false);

        // not defined so redirecting to index page
      } else {
        localStorage.setItem('isCertified', false);
        setButton1('Continue Certification');
        setButton1Link('continue');

        setButton2('Host Game');
        setButton2Link('host');
        setVideoFinish(false);
      }
    }
    return null;
  }, [query.isLoading]);
  return videoFinished ? (
    <>
      <Loader />
    </>
  ) : (
    <>
      {/* {congModel && <Confetti width={width} height={height} friction="0.99" />} */}
      {regModel && (
        <>
          <div className={styles.modal}>
            <h3 className={styles.welcom}>An Exciting Offer for You!</h3>
            <p style={{ fontSize: '16px' }}>
              Enroll into the Evivve&apos;s certification program to learn how to facilitate the
              game like a pro! <br />
              <br />
              Develop a deep understanding of game-based learning and gameful experiences taught by
              <b> Evivve&apos;s Designer, Mohsin Memon</b>.
              <br />
              <a href="https://evivve.com/certification" target="_blank" rel="noreferrer">
                <b>
                  <u>Learn More</u>
                </b>
              </a>
            </p>
            <button
              type="button"
              // style={{ transform: ' translate(1px, 39px)' }}
              className="btn btn-secondary"
              onClick={() => {
                regFees.mutate();
              }}>
              Start Certification: $99
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              style={{ marginLeft: 10 }}
              onClick={() =>
              {
                history.push('/my-programs');
              }}>
              Skip Certification
            </button>

            <p style={{ fontSize: '12px', marginTop: '10px' }}>
              Complete the certification within 39 days to earn a free Half-Day Evivve credit worth
              $125
            </p>

            <div
              style={{
                border: 'solid',
                borderRadius: '10px',
                borderColor: 'indigo',
                padding: 'inherit',
              }}>
              <p>
                <b>Want to waive the enrollment fee?</b>
              </p>

              <p style={{ textAlign: 'left' }}>Follow these instructions to apply:</p>
              <p style={{ textAlign: 'left' }}>
                1. Write to support@gamitar.com with an honest reason for waiving the fee along with
                how you want to use Evivve.
              </p>
              <p style={{ textAlign: 'left', Top: '0px' }}>
                2. Ensure the Subject reads: Request for Waiving Enrollment Fee
              </p>
              <p>
                <b>You&apos;ll receive an email with confirmation if your request is approved. </b>
              </p>
            </div>
          </div>
        </>
      )}
      {congModel && (
        <>
          <Confetti />
          <div className={styles.modal} style={{ zIndex: 10 }}>
            <h3>{heading}</h3>
            <p style={{ fontSize: '23px' }}>{message}
              <div
                className="tooltips"
                // onClick={() =>
                // {
                //   history.push("/settings")
                // }}
                onClick={() => window.open('https://gamitar.freshdesk.com/support/solutions/articles/82000898043-what-is-the-difference-between-the-free-and-paid-versions-in-evivve')}

              >
                <img
                  className="float-right"
                  src={WarnIcon}
                  alt="complete-icon"
                  style={{ width: '20px', marginLeft: 10, position: 'relative', }}
                />
                <div style={{ width: 200, borderRadius: 4, fontSize: 12 }} className="tooltiptext">
                  Credits are used to facilitate games with advanced settings. Click here to see all the cool advanced features you can use!
                </div>
              </div>
            </p>
            <button
              type="button"
              // style={{ transform: ' translate(1px, 39px)' }}
              className="btn btn-secondary m-3"
              onClick={() => {
                // clearCongModel();
                // console.log('in button');
                // window.open('http://localhost:3000');
                // window.location.reload(false);
                history.push("my-programs")
              }}>
              View Facilitator Dashboard
            </button>

            <div>
              <button
                type="button"
                onClick={() =>
                {
                  history.push('/design/add/new');
                }}
                style={{ marginTop: 10, width: "auto" }}
                className={`btn btn-primary  ${styles['home-button']}`}>
                Create Your First Program!
              </button>
            </div>
          </div>
        </>
      )}
      {query.isLoading && <Loader />}
      {!user.isRegisterationFeesPaid ? (
        <></>
      ) : (
        <>
          {/* <div className="container">
          <div style={{ display: 'flex' }}>
          <div className={`w-75 pt-3 ${styles.title}`}>
            <TitleComponent title="Home" />
          </div>

          {user.userrType === 'ADMIN' && (
            <div className={`w-75 pt-3 ${styles.title}`}>
              <TitleComponent title="Home" />
            </div>
          )}
          </div>
          </div> */}
          {user?.rewardEndTime &&
            !user?.is_Certified &&
            new Date(user?.rewardEndTime).getTime() > new Date().getTime() && (
              <Countdown date={new Date(user?.rewardEndTime).getTime()} renderer={renderer} />
            )}
          <div className="container">
            <div className="row align-items-center my-5">
              {/* <div className="img col-lg-6 col-md-6 col-12 w-50">
            <img src={BackgroundImage} alt="background" className="img-fluid" />
          </div> */}
              {/* <div className="col-lg-6 col-md-6 col-12 w-50">
            {user.is_Certified ? (
              <div style={{ marginLeft: '-23px' }}>
                <p style={{ lineHeight: 0, paddingLeft: '0px' }} className="ml-3">
                  <strong>You haven’t designed any programs yet,</strong>
                </p>
                <p style={{ paddingLeft: '112px' }}>
                  <strong>get started here</strong>
                </p>
              </div>
            ) : (
              <div>
                <p style={{ lineHeight: 0, paddingLeft: '66px' }} className="ml-3">
                  <strong>Welcome to MOGL</strong>
                </p>
                <p style={{ paddingLeft: '42px' }}>
                  <strong>Evivve Certification Program</strong>
                </p>
              </div>
            )}
          </div> */}
              <div className="row align-items-center my-5">
                <div className="img col-lg-6 col-md-6 col-12 w-50">
                  <img src={BackgroundImage} alt="background" className="img-fluid" />
                </div>
                <div className="col-lg-6 col-md-6 col-12 w-50">
                  {user.is_Certified ? (
                    <div style={{ marginLeft: '-23px' }}>
                      <p style={{ lineHeight: 0, paddingLeft: '0px' }} className="ml-3">
                        <strong>You haven’t designed any programs yet,</strong>
                      </p>
                      <p style={{ paddingLeft: '112px' }}>
                        <strong>get started here</strong>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p
                        style={{
                          lineHeight: 0,
                          paddingLeft: !isMobile && '66px',
                          textAlign: isMobile && 'center',
                        }}
                        className="ml-3 mt-4">
                        <strong>Welcome to MOGL</strong>
                      </p>
                      <p
                        style={{
                          paddingLeft: !isMobile && '42px',
                          textAlign: isMobile && 'center',
                        }}>
                        <strong>Evivve Certification Program</strong>
                      </p>
                    </div>
                  )}

                  <div className="col">
                    <button
                      type="button"
                      onClick={button1Link === 'create' ? onCreateClick : onContinueCertification}
                      className={`btn btn-secondary ${styles['home-button']}`}>
                      <img
                        src={CreateProgram}
                        alt="Create-Program"
                        className="mr-1"
                        // height="13px"
                        // width="13px"
                      />
                      {button1}
                    </button>
                  </div>
                  {user.is_Certified && !query.isLoading && query.data?.data.result.length === 0 ? (
                    <div className="col tooltips">
                      <button
                        type="button"
                        onClick={goToResourceCenter}
                        className={`btn btn-secondary mt-3 ${styles['home-button']}`}>
                        {/* <img
                        src={CreateProgram}
                        alt="resource-center"
                        className="mr-1"
                        // height="13px"
                        // width="13px"
                      /> */}
                        {button3}
                      </button>
                      <span className="tooltiptext" style={{ backgroundColor: '#d3d3d3' }}>
                        Learn about create/manage programs, buy/use credits and more.
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="col">
                    <button
                      type="button"
                      onClick={button2Link === 'demo' ? onDemoClick : onHostClick}
                      className={`btn btn-primary mt-3 ${styles['home-button']}`}>
                      {button2}
                    </button>
                  </div>

                  <div className="col">
                    <button
                      type="button"
                      onClick={() => {
                        history.push('/my-programs');
                      }}
                      style={{ marginTop: 10 }}
                      className={`btn btn-primary mt-3 ${styles['home-button']}`}>
                      Manager Mode
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

HomePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default HomePage;
