import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './payment.module.scss';
import TitleComponent from '../../components/title/title.component';

const PaymentPage = () => {

  const history = useHistory()
  
  const onNextClick = () => {
    history.push('/confirm');
  };

  return (
    <>
      <div className={`w-75 pt-3 ml-5  ${styles.title}`}>
        <TitleComponent title="Home/New Program" />
      </div>

      <div className={styles.container}>
        <button type="button" onClick={onNextClick} className="btn btn-secondary">
          Credit Payment
          <br />
          Complete
        </button>
      </div>
    </>
  );
};
PaymentPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default PaymentPage;
