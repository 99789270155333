/* eslint-disable no-empty */
// import { NULL } from 'node-sass';
import pipe from 'ramda/es/pipe';
import create from 'zustand';
import { combine, devtools } from 'zustand/middleware';
import { UserTypeEnum } from '../utils';

const log = (config) => (set, get, api) =>
  config(
    (args) => {
      set(args);
    },
    get,
    api
  );

const createStore = pipe(log, create);

/**
 * userTokenStore
 */
const accessTokenKey = 'token';
const expiresInKey = 'expiresIn';

const getDefaultValues = () => {
  try {
    return {
      accessToken: localStorage.getItem(accessTokenKey) || '',
      expiresIn: localStorage.getItem(expiresInKey) || '',
    };
  } catch {
    return {
      accessToken: '',
      expiresIn: '',
    };
  }
};

export const useTokenStore = createStore(
  combine(getDefaultValues(), (set) => ({
    setTokens: (x) => {
      localStorage.setItem(accessTokenKey, x.accessToken);
      localStorage.setItem(expiresInKey, x.expiresIn);
      set(x);
    },
    clearTokens: () => {
      localStorage.removeItem(accessTokenKey);
      localStorage.removeItem(expiresInKey);
      set({ accessToken: '', expiresIn: '' });
    },
  }))
);

/**
 * USER STORE
 */
const user = {
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  is_blocked: false,
  userrType: null,
  phone: null,
  company: null,
  position: null,
  user_type: UserTypeEnum.USER,
  created_at: null,
  updated_at: null,
  avatar_url: null,
};

export const useUserStore = createStore(
  combine(
    {
      user,
    },
    (set) => ({
      setUser: (u) => set({ user: u }),
      clearUser: () => set({ user }),
    })
  )
);

/**
 * SET TIMEZONE FROM PROGRAMS
 */

export const useSetTZProgram = createStore(
  combine(
    {
      from_program: false,
    },
    (set) => ({
      setTz: (p) => set({ from_program: p }),
    })
  )
);

/**
 * PROGRAM STORE
 */

// const program = {
//   name: '',
//   description: '',
//   fac_count: 1,
//   date: '',
//   time: '9:00 AM',
//   game_access: GameAccessEnum.HALF,
//   facilitators: [],
//   timezone: ''
// };

export const useNewProgramTime = createStore(
  combine(
    {
      data: {},
    },
    (set, get) => ({
      setNewProgramTime: (d) => set({ data: { ...d } }),
      clearNewProgramTime: () => set({ setup: {} }),
    })
  )
);

export const useUsersStore = createStore(
  combine(
    {
      usersList: [],
      userLength: 0,
    },
    devtools((set, get) => ({
      setUsers: (u) => set({ usersList: u }),
      clearUsers: () => set({ usersList: [] }),
      setUsersLength: (l) => set({ userLength: l }),
      clearUsersLength: () => set({ userLength: 0 }),
    }))
  )
);

export const useCertificationMessage = createStore(
  combine(
    {
      heading: '',
      message: '',
      congModel: false,
      regModel: false,
    },
    (set, get) => ({
      setHeading: (h) => set({ heading: h }),
      clearHeading: () => set({ heading: '' }),
      setMessage: (m) => set({ message: m }),
      clearMessage: () => set({ message: '' }),
      setCongModel: (c) => set({ congModel: c }),
      clearCongModel: () => set({ congModel: false }),
      setRegModel: (r) => set({ regModel: r }),
      clearRegModel: () => set({ regModel: false }),
    })
  )
);

export const useProgramStore = createStore(
  combine(
    {
      program: {},
      programsList: [],
      allprogram: {},
      allPrograms: [],
    },
    devtools((set, get) => ({
      setProgram: (p) => set({ program: { ...get().program, ...p } }),
      clearProgram: () => set({ program: {} }),
      setAllProgramList: (allP) => set({ allPrograms: allP }),
      setProgramsList: (p) => set({ programsList: p }),
      clearProgramsList: () => set({ programsList: [] }),
    }))
  )
);

export const useGameConfigSetup = createStore(
  combine(
    {
      setup: {},
      setupList: [],
    },
    (set, get) => ({
      setSetup: (s) => set({ setup: { ...get().setup, ...s } }),
      clearSetup: () => set({ setup: {} }),
      setSetupList: (s) => set({ setupList: s }),
      clearSetupList: () => set({ setupList: [] }),
    })
  )
);
export const useGameConfigCalamity = createStore(
  combine(
    {
      calamity: {},
      calamityList: [],
    },
    (set, get) => ({
      setCalamity: (c) => set({ calamity: { ...get().calamity, ...c } }),
      clearCalamity: () => set({ calamity: {} }),
      setCalamityList: (c) => set({ calamityList: c }),
      clearCalamityList: () => set({ calamityList: [] }),
    })
  )
);

/**
 * facilitator
 */

export const useFacilitatorStore = createStore(
  combine(
    {
      facilitatorsList: [],
    },
    (set, get) => ({
      setFacilitatorsList: (c) => set({ facilitatorsList: c }),
      clearFacilitatorsList: () => set({ facilitatorsList: [] }),
    })
  )
);

/**
 * transactions
 */

export const useUserTransactions = createStore(
  combine(
    {
      transactionList: [],
    },
    (set) => ({
      setTransactionList: (t) => set({ transactionList: t }),
      clearTransactionList: () => set({ transactionList: [] }),
    })
  )
);

/**
 * verify certification code
 */

export const useVerifyCertificateCode = createStore(
  combine(
    {
      isExist: undefined,
      certificateData: {},
    },
    (set) => ({
      setCertificateDate: (c) => set({ certificateData: c }),
      clearCertificateDate: (c) => set({ certificateData: {} }),
      setIsExist: (e) => set({ isExist: e }),
      clearIsExist: (e) => set({ isExist: undefined }),
    })
  )
);

/**
 * check program date...
 */
export const useProgramDateCheckStore = createStore(
  combine(
    {
      result: false,
    },
    (set) => ({
      setCheck: (p) => set({ result: p }),
    })
  )
);

/**
 * user credits
 */

export const useCreditStore = createStore(
  combine(
    {
      half_cr: 0,
      full_cr: 0,
      trans: [],
      isGst: {},
      inoiceType: {},
      gstDate: {},
    },
    (set) => ({
      setCredits: ({ half, full }) => set({ half_cr: half, full_cr: full }),
      setTrans: (t) => set({ trans: t }),
      setClear: () => set({ half_cr: 0, full_cr: 0, trans: [] }),
      setGSTCheck: (g) => set({ isGst: g }),
      setInvoiceType: (g) => set({ inoiceType: g }),
      setGSTDate: (g) => set({ gstDate: g }),
    })
  )
);

/**
 * Add facilitator
 */

export const useSelectedFacilitator = createStore(
  combine(
    {
      selectedFacil: {},
    },
    (set) => ({
      setSelectedFacil: (f) => set({ selectedFacil: f }),
      removeSelectedFacil: () => set({ selectedFacil: {} }),
    })
  )
);

export const useGameConut = createStore(
  combine(
    {
      gameData: {},
      gameDemo: {},
      demo_count: 0,
      paid_count: 0,
      total_count: 0,
      demo_user: {},
      paid_user: {},
      combined_users: {},
    },
    (set) => ({
      setGameDemoData: (f) => set({ gameDemo: f }),
      setGameData: (f) => set({ gameData: f }),
      setDemoCount: (f) => set({ demo_count: f }),
      setPaidCount: (f) => set({ paid_count: f }),
      setTotalCount: (f) => set({ total_count: f }),
      setPaidUsers: (f) => set({ paid_user: f }),
      setDemoUsers: (f) => set({ demo_user: f }),
      setCombinedUsers: (f) => set({ combined_users: f }),
    })
  )
);
export const useCreditsConut = createStore(
  combine(
    {
      half_day: {},
      full_day: {},
      total_credits: {},
      halfCredit_count: 0,
      fullCredit_count: 0,
      totalCredit_count: 0,
      half_users: {},
      full_users: {},
      comb_users: {},
    },
    (set) => ({
      setHalfDay: (f) => set({ half_day: f }),
      setFullDay: (f) => set({ full_day: f }),
      setTotalCredits: (f) => set({ total_credits: f }),
      setHalfCreditCount: (f) => set({ halfCredit_count: f }),
      setfullCreditCount: (f) => set({ fullCredit_count: f }),
      setTotalCreditsCount: (f) => set({ totalCredit_count: f }),
      setHalfUsers: (f) => set({ half_users: f }),
      setFullUsers: (f) => set({ full_users: f }),
      setCombUsers: (f) => set({ comb_users: f }),
    })
  )
);