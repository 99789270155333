/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

const TopUserModal = ({
  openModal,
  setOpenModal,
  comb_users,
  full_users,
  half_users,
  combined_users,
  demo_user,
  paid_user,
}) => {
  const TopUsersFunc = (dataObj, title) => {
    return (
      <>
        {/* <p style={{ fontSize: '15px', fontWeight: 'bold' }}>{title}</p> */}
        {Object.entries(dataObj).map(([k, v]) => (
          <>
            <p style={{ display: 'grid' }}>
              {k} :{v}
            </p>
          </>
        ))}
      </>
    );
  };
  return (
    <>
      <div
        style={
          openModal
            ? {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50% ,-50%',
                backgroundColor: '#fff6ea',
                backdropFilter: 'grayscale(0.5) opacity(0.8)',
                boxShadow: 'rgb(0 0 0 / 60%) 0px 2px 12px',
                borderRadius: '10px',
                width: '100%',
                padding: '3rem',
                overflow: 'auto',
                maxHeight: '600px',
              }
            : { display: 'none' }
        }>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Top 10 Users</p>{' '}
          <p
            onClick={() => setOpenModal(false)}
            style={{ cursor: 'pointer', fontSize: '15px', fontWeight: 'bold' }}>
            x
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {' '}
            {paid_user && Object.keys(paid_user).length ? (
              <>
                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Paid Games</p>
                <>{TopUsersFunc(paid_user, 'Paid')}</>
              </>
            ) : (
              ''
            )}
          </div>
          <div>
            {' '}
            {demo_user && Object.keys(demo_user).length ? (
              <>
                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Demo Games</p>

                <>{TopUsersFunc(demo_user, 'Demo')}</>
              </>
            ) : (
              ''
            )}{' '}
          </div>
          <div>
            {' '}
            {combined_users && Object.keys(combined_users).length ? (
              <>
                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Combined Games</p>
                <>{TopUsersFunc(combined_users, 'Combined')}</>
              </>
            ) : (
              ''
            )}{' '}
          </div>
          <div>
            {half_users && Object.keys(half_users).length ? (
              <>
                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Half Credits</p>
                <>{TopUsersFunc(half_users, 'Half_day')}</>
              </>
            ) : (
              ''
            )}{' '}
          </div>
          <div>
            {full_users && Object.keys(full_users).length ? (
              <>
                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Full Credits</p>
                <>{TopUsersFunc(full_users, 'Full_Day')}</>
              </>
            ) : (
              ''
            )}
          </div>
          <div>
            {comb_users && Object.keys(comb_users).length ? (
              <>
                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Combined Credits</p>
                <>{TopUsersFunc(comb_users, 'Total')}</>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};
TopUserModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  comb_users: PropTypes.object.isRequired,
  full_users: PropTypes.object.isRequired,
  half_users: PropTypes.object.isRequired,
  combined_users: PropTypes.object.isRequired,
  demo_user: PropTypes.object.isRequired,
  paid_user: PropTypes.object.isRequired,
};
export default TopUserModal;
