/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import styles from './home.module.scss';
import BackgroundImage from '../../assets/home/backgroundimage.png';
import CreateProgram from '../../assets/home/createprogram.png';
import TitleComponent from '../../components/title/title.component';
import Loader from '../../components/Loader';
import {
  useGetCertificationCode,
  useGetProgramsList,
  useGetUsersList,
  useUpdateUserCertify,
} from '../../services';
import { useUserStore, useUsersStore } from '../../store';
import axiosClient from '../../utils/axiosClient';
import { isMobile } from '../../utils';

const CertificatePage = () => {
  const history = useHistory();

  const options = [
    { value: 'username', label: 'User Name' },
    { value: 'signUpDate', label: 'Sign Up Date' },
    { value: 'certifyDate', label: 'Certify Date' },
    { value: 'latestSession', label: 'Latest Session' },
  ];

  const [page, setPage] = useState(1);
  const [button2, setButton2] = useState('');
  const [button1, setButton1] = useState('');
  const [button1Link, setButton1Link] = useState('');
  const [button2Link, setButton2Link] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [currenSort, setCurrentSort] = useState('username');
  const [selectedOption, setSelectedOption] = useState(options[1].value);
  const cc = useGetCertificationCode();
  const [value, setValue] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const dummyUsers = [];
  const updateCertification = useUpdateUserCertify();

  const user = useUserStore((s) => s.user);
  const query = useGetProgramsList();
  const getUserList = useGetUsersList();

  const users = useUsersStore((u) => u.usersList);
  let usersLength = useUsersStore((u) => u.userLength);
  usersLength = Math.ceil(usersLength / 10);

  const handleChangeCertify = (d) => {
    const data = {
      certify: !d.is_Certified,
      targetUserId: d.id,
      pageNumber: currentPage,
    };

    updateCertification.mutate(data);
  };

  useEffect(() => {
    users.map((d, i) => {
      const userNewObject = {
        JSXContent: (
          <>
            <td style={{ paddingLeft: '30px' }}>
              {d.first_name} {d.last_name}
            </td>
            <td>{dayjs(d.created_at).format('DD MMM, YYYY')}</td>
            <td>{d.certifyDate ? dayjs(d.certifyDate).format('DD MMM, YYYY') : ''}</td>
            <td>{d.last_session_date ? dayjs(d.last_session_date).format('DD MMM, YYYY') : ''}</td>
            <td>
              {d.halfNet}, {d.fullNet}F, {d.availableRewardCredits}R
            </td>
            <td style={{ float: 'right' }}>
              <button
                onClick={() => handleChangeCertify(d)}
                type="button"
                className={`btn ${styles['edit-border']}`}>
                {d.is_Certified ? 'Revoke' : 'Certify'}
              </button>
              <button type="button" className={`btn ${styles['edit-border']}`}>
                View Report
              </button>
            </td>
          </>
        ),
        user: d,
      };

      dummyUsers.push(userNewObject);

      if (users.length - 1 === i) setUsersData(dummyUsers);

      return userNewObject.JSXContent;
    });
  }, [users]);

  useEffect(() => {
    if (user.is_Certified) {
      localStorage.setItem('isCertified', true);
      if (!query.isLoading) {
        if (query.data?.data.result.length > 0) {
          localStorage.setItem('programsLength', query.data?.data.result.length);
        }
      }
      setButton1('Create Program');
      setButton1Link('create');
      setButton2('Demo Game');
      setButton2Link('demo');
    } else {
      localStorage.setItem('isCertified', false);
      setButton1('Continue Certification');
      setButton1Link('continue');

      setButton2('Host Game');
      setButton2Link('host');
    }
    return null;
  }, []);

  const submit = (e) => {
    // axios.post(`http://192.168.100.232:4000/api/certificationCode/${value}`)
    axiosClient()
      .post(`/certificationCode/${value}`)
      .then((res) => {
        console.log('posted....', value, res);
      })
      .catch((err) => {
        console.log('error', err);
      });
    history.push('/');
  };

  const handleSort = (e) => {
    setSelectedOption(e);
    setCurrentSort(e.value);
    getUserList.mutate({ page: currentPage, sort: e.value, search });
  };

  useEffect(() => {
    getUserList.mutate({ page: 1, sort: currenSort, search });
  }, []);

  const handlePaginationPrev = (currentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      getUserList.mutate({ page: currentPage - 1, sort: currenSort, search });
    }
  };

  const handlePaginationNext = (currentPage, userLength) => {
    if (currentPage < userLength) {
      setCurrentPage(currentPage + 1);
      getUserList.mutate({ page: currentPage + 1, sort: currenSort, search });
    }
  };

  const handlePagination = (pageNum) => {
    setCurrentPage(pageNum);
    getUserList.mutate({ page: pageNum, sort: currenSort, search });
  };

  const hanldeSearch = (e, currentPage, currenSort) => {
    setSearch(e.target.value);
    getUserList.mutate({ page: currentPage, sort: currenSort, search: e.target.value });
  };

  const renderPagination = () => {
    let JSXContent = [];
    for (let i = 0; i < usersLength; i += 1) {
      JSXContent = [
        ...JSXContent,
        <li className="page-item">
          <p
            className="page-link"
            onClick={() => handlePagination(i + 1)}
            style={
              currentPage === i + 1
                ? { color: 'black', cursor: 'pointer', backgroundColor: '#fdf1e0' }
                : { color: 'black', cursor: 'pointer' }
            }>
            {i + 1}
          </p>
        </li>,
      ];
    }
    return JSXContent;
  };

  return (
    <>
      {query.isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="container">
            <div style={{ display: 'flex' }}>
              <div className={`w-75 pt-3 ${styles.title}`} style={{}}>
                <Link to="/"> Home </Link>
              </div>

              <div className={`col-4 pb-0 pt-4 ${styles.title}`}>
                <TitleComponent title="Admin Panel" />
              </div>
            </div>

            <div className="row mt-4" style={{ borderBottom: '1px solid lightgrey' }}>
              {/* <div className="col-3 p-2 h-25 text-right"> Enter Certificate Code </div> */}
              <div className="col p-2 h-25 text-right">
                <input
                  type="text"
                  placeholder="Enter Certificate Code"
                  value={value === null ? cc?.data?.Certificationcoderesp.Code : value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
              </div>
              <div className="col pt-0 mb-2 text-left">
                <button
                  type="button"
                  onClick={submit}
                  className={`btn btn-primary mt-1 ${styles['home-button']}`}>
                  Submit
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md col-sm-12 mt-2 ">
                <Link to="/reports">
                  <button type="button" className={`btn btn-primary  ${styles['home-button']}`}>
                    Usage Report
                  </button>
                </Link>
              </div>
              <div className="col-md-1 col-sm-12 mt-2 p-2 text-center"> Sort by </div>

              <div className="col-md-2 col-sm-12 mt-2 pr-0 pl-1 pr-1">
                <Select
                  value={selectedOption}
                  onChange={handleSort}
                  options={options}
                  // className={`btn btn-secondary mr-3 ${styles['program-button']}`}
                />
              </div>

              <div className="col-md-1 col-sm-12 mt-2 p-2 text-center"> Search </div>

              <div className="col-md-2 col-sm-12 mt-2 pr-0 pl-2 pr-2">
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail"
                  placeholder="Search User"
                  value={search}
                  onChange={(e) => hanldeSearch(e, currentPage, currenSort)}
                />
              </div>
            </div>

            <div
              style={
                isMobile
                  ? {
                      display: 'block',
                      whiteSpace: 'nowrap',
                      overflowX: 'scroll',
                    }
                  : {
                      width: '100%',
                    }
              }>
              <table className={`table table-sm ${styles['table-bg']}`}>
                <thead className="thread">
                  <tr>
                    <th style={{ paddingLeft: '30px' }} scope="col">
                      User Name
                    </th>
                    <th scope="col">Sign Up Date</th>
                    <th scope="col">Certify Date</th>
                    {/* <th scope="col">Most Recent Session</th> */}
                    <th scope="col">Latest Session</th>
                    <th scope="col">Credits</th>
                    <th scope="col" style={{ paddingLeft: '13%' }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: '#fff6ea' }}>
                  {usersData.map((u) => (
                    <tr>{u.JSXContent}</tr>
                  ))}
                </tbody>
              </table>
              <div
                className="container d-flex justify-content-center"
                style={{ color: 'black', marginLeft: isMobile && 500 }}>
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li class="page-item">
                      <p
                        class="page-link"
                        onClick={() => handlePaginationPrev(currentPage)}
                        style={{ color: 'black', cursor: 'pointer' }}>
                        Prev
                      </p>
                    </li>
                    {renderPagination()}
                    <li class="page-item">
                      <p
                        class="page-link"
                        onClick={() => handlePaginationNext(currentPage, usersLength)}
                        style={{ color: 'black', cursor: 'pointer' }}>
                        Next
                      </p>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

CertificatePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CertificatePage;
