import React from 'react';
import PropTypes from 'prop-types';
import styles from './cardFooter.module.scss';

const CardFooterComponent = ({ previous, next, onPreviousClick, onNextClick }) => {
  return (
    <>
      <div className="row mt-2">
        <div className="col-auto mr-auto ">
          <button
            type="button"
            style={{ width: '130px' }}
            className="btn btn-secondary"
            onClick={onPreviousClick}>
            {previous}
          </button>
        </div>
        <div className="col-auto">
          <button
            type="button"
            style={{ width: '130px' }}
            className={`btn btn-secondary ${styles['continue-button']}`}
            onClick={onNextClick}>
            {next}
          </button>
        </div>
      </div>
    </>
  );
};
CardFooterComponent.propTypes = {
  previous: PropTypes.string.isRequired,
  next: PropTypes.string.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
};

export default CardFooterComponent;
