/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import CertificationDetailsComponent from '../../components/certificationDetails/certificationDetails.component';
import PDFCertificateComponent from '../../components/pdfCertificate/pdfCertificate.component';
import { useVerifyCertificateCode } from '../../store';
import { useCertificationCodeVerification } from '../../services';
import Loader from '../../components/Loader';
import styles from './certification.module.scss';
import { isMobile } from '../../utils';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const CertificationPage = () => {
  const [certificateDetails, setCertificateDetails] = useState({
    username: '',
    userAvatar: '',
    certifyDate: '',
    id: '',
    certificate_image_url: '',
  });
  const certifyCode = useCertificationCodeVerification();
  const { certificationCode } = useParams();

  useEffect(() => {
    certifyCode.mutate({ code: certificationCode });
  }, []);

  const isExist = useVerifyCertificateCode((c) => c.isExist);
  const certificateData = useVerifyCertificateCode((c) => c.certificateData);

  useEffect(() => {
    setCertificateDetails({ ...certificateDetails, ...certificateData });
  }, [certificateData]);

  return (
    <>
      {isExist === undefined ? (
        <Loader />
      ) : isExist === true ? (
        <>
          <div>
            <p className={styles['certificate-small']}>Certification</p>
            <p
              className={styles['certificate-heading']}
              style={{
                marginTop: isMobile && 50,
              }}>
              Multiplayer Online Game-Based Learning: Evivve
            </p>
          </div>
          <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
            <div
              className={`col-md-6 col-sm-12 ${styles['certification-details-section']}`}
              style={{
                padding: isMobile && '50px 40px 60px 45px',
              }}>
              <CertificationDetailsComponent
                username={certificateDetails.username}
                certifyDate={certificateDetails.certifyDate}
                userAvatar={certificateDetails.userAvatar}
              />
            </div>
            <div className={`col-md-6 col-sm12 ${styles['certificate-pdf-section']} `}>
              <PDFCertificateComponent
                userid={certificateDetails.id}
                username={certificateDetails.username}
                certifyDate={certificateDetails.certifyDate}
                certificate_image_url={certificateDetails.certificate_image_url}
                certificationCode={certificationCode}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <p className={styles['certificate-heading-unknown']}>Unknown Code</p>
          </div>
        </>
      )}
    </>
  );
};

export default CertificationPage;
