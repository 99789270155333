/* eslint-disable */

import React from 'react';
import 'react-day-picker/lib/style.css';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import './styles/styles.scss';
import { QueryClientProvider } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import queryClient from './utils/queryClient';

const tagManagerArgs = {
  gtmId: 'GTM-PZPT8TB',
};

TagManager.initialize(tagManagerArgs);

const app = (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/evivve2">
        {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}> */}
        {/* <GoogleOAuthProvider clientId="755067706797-b3l09v9ttk2rckuq3qn3q0467qfgpkd5.apps.googleusercontent.com"> */}
        <GoogleOAuthProvider clientId="86155783287-taskdn0upgrfv9pnqlkgdjbnkg980qog.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </BrowserRouter>
      <ToastContainer />
    </QueryClientProvider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));
