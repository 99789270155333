/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useUpdateUserProfile, useGetGameConfigSetting } from '../../services';
import { useUserStore, useGameConfigSetup, useGameConfigCalamity } from '../../store';
import tz from '../../utils/timezone.json';
import CardComponent from '../card/card.component';
import Loader from '../Loader';
import TitleComponent from '../title/title.component';
import styles from './settings.module.scss';
import Setup from './gameConfig/setup';
import Calamity from './gameConfig/calamity';
import ShowToast from '../ShowToast';
import axiosClient from '../../utils/axiosClient';
import { isMobile } from '../../utils';

const SettingsComponent = () => {
  const history = useHistory();
  const user = useUserStore((s) => s.user);
  const userId = user.id;
  const [timezone, setTimezone] = useState();
  const [edit, setEdit] = useState(false);
  const [isCertified, setIsCertified] = useState();
  const utz = useUpdateUserProfile();

  useGetGameConfigSetting();

  const calamityFirst = useGameConfigCalamity((c) => c.calamityList);
  const setupFirst = useGameConfigSetup((s) => s.setupList);

  const [calamity, setCalamity] = useState(useGameConfigCalamity((c) => c.calamityList));
  const [setup, setSetup] = useState(useGameConfigSetup((s) => s.setupList));

  const [selectedCalamityId, setSelectedCalamityId] = useState();
  const [selectedRound, setSelectedRound] = useState('easy');
  const [credits, setSelectCredits] = useState();
  const [days, setSelectDays] = useState();
  const [secDays, setSelectSecDays] = useState();
  const [offers, setOffers] = useState();
  const [offersRejection, setOffersRejection] = useState();
  const [mov, setMov] = useState();

  const [selectedSetupId, setSelectedSetupId] = useState();
  const [noOfCalamities, setNoOfCalamities] = useState();
  const [calamities, setCalamities] = useState();
  const [calamityLevels, setCalamityLevels] = useState();
  const [exhaustLand, setExhaustLand] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setCalamity(calamityFirst);
    setSetup(setupFirst);
  }, [calamityFirst, setupFirst, reload]);

  useEffect(() => {
    if (setup.length > 1) {
      const setupIndex = setup.findIndex((set) => set.round_name === selectedRound);

      setSelectedSetupId(setup[setupIndex]?.id);
      setSelectCredits(setup[setupIndex]?.credits);
      setSelectDays(setup[setupIndex]?.days);
      setSelectSecDays(setup[setupIndex]?.sec_days);
      setOffers(setup[setupIndex]?.offers);
      setOffersRejection(setup[setupIndex]?.offers_rejection);
      setMov(setup[setupIndex]?.mov);
    }

    if (calamity.length > 1) {
      const calIndex = calamity.findIndex((cal) => cal.round_name === selectedRound);
      setSelectedCalamityId(calamity[calIndex]?.id);
      setNoOfCalamities(calamity[calIndex]?.no_calamities);
      setCalamities(calamity[calIndex]?.calamities);
      setCalamityLevels(calamity[calIndex]?.calamities_lvl);
      setExhaustLand(calamity[calIndex]?.exhaust_land);
    }
  }, [setup, calamity, reload]);

  useEffect(() => {
    if (user.timezone) {
      setTimezone(user.timezone);
    }
    if (user.is_Certified) {
      setIsCertified(true);
    } else {
      setIsCertified(false);
    }
    return null;
  }, [user.timezone]);

  const onClickSave = () => {
    utz.mutate({
      ...user,
      timezone,
    });
  };

  const resetConfig = () => {
    setIsLoading(true);
    axiosClient()
      .post('/resetUserConfigSettings', {
        user_id: userId,
      })
      .then((res) => {
        setIsLoading(false);
        setReload(!reload);
        ShowToast('Reseting Successful!', 'success');
      })
      .catch((err) => {
        setIsLoading(false);
        ShowToast('Something went wrong...!', 'error');
      });
  };

  const handleSelectedRound = (roundName) => {
    const calIndex = calamity.findIndex((cal) => cal.round_name === roundName);
    const setupIndex = setup.findIndex((set) => set.round_name === roundName);

    setSelectedSetupId(setup[setupIndex].id);
    setSelectCredits(setup[setupIndex].credits);
    setSelectDays(setup[setupIndex].days);
    setSelectSecDays(setup[setupIndex].sec_days);
    setOffers(setup[setupIndex].offers);
    setOffersRejection(setup[setupIndex].offers_rejection);
    setMov(setup[setupIndex].mov);

    setSelectedCalamityId(calamity[calIndex].id);
    setNoOfCalamities(calamity[calIndex].no_calamities);
    setCalamities(calamity[calIndex].calamities);
    setCalamityLevels(calamity[calIndex].calamities_lvl);
    setExhaustLand(calamity[calIndex].exhaust_land);

    setSelectedRound(roundName);
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (
      !selectedRound ||
      // !noOfCalamities ||
      !calamities ||
      !calamityLevels ||
      !selectedRound ||
      !credits ||
      !days ||
      !secDays ||
      !offers ||
      !offersRejection ||
      !mov
    ) {
      setIsLoading(false);
      return ShowToast('All fields are required', 'error');
    }

    const setupData = {
      days: parseInt(days, 10),
      id: selectedSetupId,
      mov: parseInt(mov, 10),
      offers: parseInt(offers, 10),
      offers_rejection: parseInt(offersRejection, 10),
      round_name: selectedRound,
      sec_days: parseInt(secDays, 10),
      user_id: userId,
      credits: parseInt(credits, 10),
    };

    const calamityData = {
      id: selectedCalamityId,
      user_id: userId,
      calamities,
      calamities_lvl: calamityLevels,
      round_name: selectedRound,
      days_check: parseInt(exhaustLand, 10) > 0,
      exhaust_land: parseInt(exhaustLand, 10),
      no_calamities: parseInt(calamities.length, 10),
    };

    let updatedCalamity;

    await axiosClient()
      .post('/updateCalamityByUserId', {
        ...calamityData,
      })
      .then((res) => {
        updatedCalamity = res.data.data;
        ShowToast('Calamity Saved Successfull!', 'success');
      })
      .catch((err) => {
        setIsLoading(false);
        ShowToast('Something went wrong...!', 'error');
      });

    await axiosClient()
      .post('/updateSetupByUserId', {
        ...setupData,
      })
      .then((res) => {
        setSetup(res.data.data);
        setCalamity(updatedCalamity);
        setIsLoading(false);
        ShowToast('Setup Saved Successfull!', 'success');
      })
      .catch((err) => {
        setIsLoading(false);
        ShowToast('Something went wrong...!', 'error');
      });
    return true;
  };

  const handleInvite = () => {
    history.push('/program-invite');
  };

  return (
    <div>
      {utz.isLoading && <Loader />}
      {isLoading && (
        <>
          <Loader />
        </>
      )}
      <div className={`w-75 pt-3 ${styles.title}`}>
        <TitleComponent title="Home/ Settings" />
      </div>
      <div
        className={`container ${styles['box-shadow']}`}
        style={
          edit || localStorage.getItem('gameSettingClicked')
            ? { display: 'block', backgroundColor: '#fff6ea', padding: '22px', overflow: 'hidden' }
            : { display: 'none' }
        }>
        <button
          type="button"
          className="btn btn-secondary  mt-3 px-5"
          onClick={() => {
            localStorage.removeItem('gameSettingClicked');
            setEdit(false);
            setReload(!reload);
          }}>
          Back
        </button>
        <div className="gameconfig">
          <Setup
            handleSelectedRound={handleSelectedRound}
            setup={setup}
            selectedRound={selectedRound}
            credits={credits}
            days={days}
            secDays={secDays}
            offers={offers}
            offersRejection={offersRejection}
            mov={mov}
            setSelectedRound={setSelectedRound}
            setSelectCredits={setSelectCredits}
            setSelectDays={setSelectDays}
            setSelectSecDays={setSelectSecDays}
            setOffers={setOffers}
            setOffersRejection={setOffersRejection}
            setMov={setMov}
          />
          <Calamity
            selectedRound={selectedRound}
            noOfCalamities={noOfCalamities}
            calamities={calamities}
            calamityLevels={calamityLevels}
            exhaustLand={exhaustLand}
            setNoOfCalamities={setNoOfCalamities}
            setCalamities={setCalamities}
            setCalamityLevels={setCalamityLevels}
            setExhaustLand={setExhaustLand}
          />
          <div className="text-right">
            <button type="submit" className="btn btn-primary mt-3 px-5" onClick={handleSave}>
              Save
            </button>
          </div>
          <div className="mt-5">
            <hr />
            <h4 className="d-inline-block">Reset All Configurations : &nbsp; </h4>
            <button type="button" className="btn btn-primary" onClick={resetConfig}>
              RESET
            </button>
          </div>
        </div>
      </div>
      <div
        className={`container ${styles['box-shadow']}`}
        style={
          edit || localStorage.getItem('gameSettingClicked')
            ? { display: 'none' }
            : { display: 'block' }
        }>
        <CardComponent>
          <>
            <div className="container">
              <div className="row">
                <div className="col-md-2 col-sm-12"><b>Timezone</b></div>
                <div className={`col-md-4 col-sm-12 ${styles['timezone-scroll-bar']}`}>
                  <select
                    style={{ width: '100%' }}
                    onChange={(e) => setTimezone(e.target.value)}
                    value={timezone}>
                    {tz.map((e, index) => (
                      <option value={e.text} key={index}>
                        {e.text}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4 col-sm-12">
                  <p> Change your timezone. Game access is granted based on your timezone</p>
                </div>
                <div className="col-md-2 col-sm-12">
                  <button
                    type="button"
                    className="btn btn-secondary float-right w-50"
                    onClick={onClickSave}>
                    Save
                  </button>
                </div>
              </div>

              {/* {isCertified ? (
                <> */}
              <hr className="mt-2 mb-3" />
              <div className="row">
                <div className="col-md-2 col-sm-12"><b>Game Settings</b></div>
                <div className="col-md-4 col-sm-12" style={{ visibility: 'hidden' }}>
                  h1
                </div>

                <div className="col-md-3 col-sm-12 pl-3">
                  <p>View and edit your <b>advanced game settings</b> here</p>
                </div>
                <div className="col-md-3 col-sm-12">
                  <button
                    type="submit"
                    onClick={() => setEdit(true)}
                    className={`btn btn-secondary float-right ${isMobile ? 'w-50' : 'w-50'}`}>
                    View/Edit
                  </button>
                </div>
              </div>
              {/* </>
              ) : (
                ''
              )} */}
              <hr className="mt-2 mb-3" />
              <div className="row">
                <div className="col-md-2 col-sm-12"><b>Invitation</b></div>
                <div className="col-md-4 col-sm-12" style={{ visibility: 'hidden' }}>
                  h1
                </div>
                <div className="col-md-3 col-sm-12 pl-3">
                  <p>Edit and save your default invitation message</p>
                </div>
                <div className="col-md-3 col-sm-12">
                  <button
                    type="button"
                    onClick={handleInvite}
                    className={`btn btn-secondary float-right ${isMobile ? 'w-50' : 'w-50'}`}>
                    View/Edit
                  </button>
                </div>
              </div>
            </div>
          </>
        </CardComponent>
      </div>
    </div>
  );
};

export default withRouter(SettingsComponent);
