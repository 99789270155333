/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useCreditStore } from '../store';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import InvoiceTemplate from '../assets/Invoice_Template.pdf';
import { split } from 'ramda';

const PDFJS = window.pdfjsLib;
const DownloadInvoice = async (
  // pdfDoc,
  // setPdfDoc,
  shareAbleUrl,
  setShareAbleUrl,
  timeZone,
  user,
  trans
) => {
  const existingPdfBytes = await fetch(InvoiceTemplate)
    .then((res) => res.arrayBuffer())
    .catch((err) => console.log('error in fetch', err));
  const bytes = new Uint8Array(existingPdfBytes);
  const pdfDocument = await PDFDocument.load(bytes);

  const helveticaFont = await pdfDocument.embedFont(StandardFonts.Helvetica);
  const HelveticaBold = await pdfDocument.embedFont(StandardFonts.HelveticaBold);

  const pages = pdfDocument.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();
  if (trans?.invoice_type === 'gst') {
    firstPage.drawText(trans?.gst_state, {
      x: 45,
      y: height / 3.7 + 300,
      size: 12,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
  } else if (trans?.invoice_type === 'non-gst') {
    firstPage.drawText(timeZone.split(')')[1], {
      x: 45,
      y: height / 3.7 + 300,
      size: 12,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
  }
  firstPage.drawText(user && user.company ? user.company : user.email, {
    x: 45,
    y: height / 2.9 + 300,
    size: 10,
    font: helveticaFont,
    color: rgb(0.2, 0.2, 0.2),
  });
  if (trans?.invoice_type === 'gst') {
    firstPage.drawText(`GSTIN: ${trans?.gst_no}`, {
      x: 45,
      y: height / 3.1 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  firstPage.drawText(trans && trans?.updated_at.split('T')[0], {
    x: 74,
    y: height / 6.1 + 300,
    size: 10,
    font: helveticaFont,
    color: rgb(0.2, 0.2, 0.2),
  });
  if (trans.half_credits > 0 || trans.full_credits > 0) {
    firstPage.drawText('Evivve', {
      x: 180,
      y: height / 6.1 + 300,
      size: 10,
      font: HelveticaBold,
      color: rgb(0.2, 0.2, 0.2),
    });
    firstPage.drawText('Enterprise', {
      x: 180,
      y: height / 6.1 + 285,
      size: 10,
      font: HelveticaBold,
      color: rgb(0.2, 0.2, 0.2),
    });
    firstPage.drawText('License', {
      x: 180,
      y: height / 6.1 + 270,
      size: 10,
      font: HelveticaBold,
      color: rgb(0.2, 0.2, 0.2),
    });

    firstPage.drawText('Credits', {
      x: 267,
      y: height / 6.1 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
    firstPage.drawText('for Evivve-', {
      x: 267,
      y: height / 6.1 + 285,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
    firstPage.drawText('The Leadership Game', {
      x: 267,
      y: height / 6.1 + 270,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  } else {
    firstPage.drawText('Evivve Certification', {
      x: 258,
      y: height / 6.1 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  if (trans && trans.half_credits > 0) {
    firstPage.drawText(trans?.half_credits, {
      x: 431,
      y: height / 6.1 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  } else if (trans && trans.full_credits > 0) {
    firstPage.drawText(trans?.full_credits, {
      x: 431,
      y: height / 6.1 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  } else {
    firstPage.drawText('1', {
      x: 431,
      y: height / 6.1 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  if (trans && trans.half_credits > 0) {
    firstPage.drawText(`${trans.amount / trans?.half_credits}`, {
      x: 484,
      y: height / 6.1 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  } else if (trans && trans.full_credits > 0) {
    firstPage.drawText(`${trans.amount / trans?.full_credits}`, {
      x: 484,
      y: height / 6.1 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  } else {
    firstPage.drawText('1', {
      x: 484,
      y: height / 6.1 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }

  firstPage.drawText(trans && trans.amount, {
    x: 524,
    y: height / 6.1 + 300,
    size: 10,
    font: helveticaFont,
    color: rgb(0.2, 0.2, 0.2),
  });
  if (trans && trans?.amount) {
    firstPage.drawText(
      trans?.invoice_type === 'gst' ? `INR ${trans.amount}` : `USD ${trans.amount}`,
      {
        x: 524,
        y: height / 18.3 + 300,
        size: 10,
        font: helveticaFont,
        color: rgb(0.2, 0.2, 0.2),
      }
    );
  }
  if (trans?.invoice_type === 'gst' && trans?.gst_state === 'Maharashtra') {
    firstPage.drawText(`CGST @ 9%`, {
      x: 324,
      y: height / 11.3 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  if (trans?.invoice_type === 'gst' && trans?.gst_state === 'Maharashtra') {
    firstPage.drawText(`SGST @ 9%`, {
      x: 324,
      y: height / 13.7 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  if (trans?.invoice_type === 'gst' && trans?.gst_state !== 'Maharashtra') {
    firstPage.drawText(`IGST @ 18%`, {
      x: 324,
      y: height / 11.3 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  if (trans?.invoice_type === 'gst' && trans?.gst_state === 'Maharashtra') {
    let tax = (trans?.amount * 100) / 109;
    tax = trans?.amount - tax;
    firstPage.drawText(`INR ${tax.toFixed(2)}`, {
      x: 524,
      y: height / 11.3 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  if (trans?.invoice_type === 'gst' && trans?.gst_state === 'Maharashtra') {
    let tax = (trans?.amount * 100) / 109;
    tax = trans?.amount - tax;
    firstPage.drawText(`INR ${tax.toFixed(2)}`, {
      x: 524,
      y: height / 13.7 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  if (trans?.invoice_type === 'gst' && trans?.gst_state !== 'Maharashtra') {
    let tax = (trans?.amount * 100) / 118;
    tax = trans?.amount - tax;
    firstPage.drawText(`INR ${tax.toFixed(2)}`, {
      x: 524,
      y: height / 11.3 + 300,
      size: 10,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  if (trans && trans?.invoiceSequence) {
    firstPage.drawText(`MLPS${trans?.invoiceSequence}`, {
      x: 470,
      y: height - 198,
      size: 11,
      font: helveticaFont,
      color: rgb(0.2, 0.2, 0.2),
    });
  }
  firstPage.drawText(trans && trans?.updated_at.split('T')[0], {
    x: 470,
    y: height - 212,
    size: 11,
    font: helveticaFont,
    color: rgb(0.2, 0.2, 0.2),
  });
  firstPage.drawText(trans && trans?.updated_at.split('T')[0], {
    x: 470,
    y: height - 225,
    size: 11,
    font: helveticaFont,
    color: rgb(0.2, 0.2, 0.2),
  });

  const pdfBytes = await pdfDocument.save();

  const file = new Blob([pdfBytes], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  setShareAbleUrl(fileURL);
  window.open(fileURL, '_blank');
  return <></>;
};

export default DownloadInvoice;
