import { toast } from 'react-toastify';

const ShowToast = (msg, type, progress) =>
  toast(msg, {
    type,
    position: 'bottom-right',
    autoClose: 2000,
    hideProgressBar: !progress,
    closeOnClick: true,
    draggable: false,
  });

export default ShowToast;
