/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './auth.module.scss';
import { useResetPassword } from '../../services';
import { useTokenStore } from '../../store';
import ShowToast from '../../components/ShowToast';

const ResetPassword = () => {
  const history = useHistory();
  const hasToken = useTokenStore((s) => !!s.accessToken);

  const [inputDisable, setInputDisable] = useState({
    resetCode: true,
    newPassword: true,
    confirmPassword: true,
  });

  useEffect(() => {
    if (hasToken) history.push('/');
  }, [hasToken]);

  const resetPassword = useResetPassword();

  const [resetPasswordDetails, setResetPasswordDetails] = useState({
    resetCode: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    setResetPasswordDetails({ ...resetPasswordDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (
      !resetPasswordDetails.resetCode ||
      !resetPasswordDetails.newPassword ||
      !resetPasswordDetails.confirmPassword
    ) {
      return ShowToast('All fields are required', 'error');
    }

    if (resetPasswordDetails.newPassword !== resetPasswordDetails.confirmPassword) {
      return ShowToast('Password does not matched', 'error');
    }

    return resetPassword.mutate({ ...resetPasswordDetails });
  };

  return (
    <>
      {hasToken ? (
        <></>
      ) : (
        <div className={styles['form-container']}>
          <form className={styles.resetForm}>
            <input
              type="text"
              className="form-control mt-1`"
              name="resetCode"
              value={resetPasswordDetails.resetCode}
              onChange={handleChange}
              placeholder="Reset Code"
              readOnly={inputDisable.resetCode}
              onFocus={() => setInputDisable({ ...inputDisable, resetCode: false })}
              onBlur={() => setInputDisable({ ...inputDisable, resetCode: true })}
            />
            <input
              readOnly={inputDisable.newPassword}
              type="password"
              className="form-control mt-1`"
              value={resetPasswordDetails.newPassword}
              onChange={handleChange}
              name="newPassword"
              placeholder="New Password"
              onFocus={() => setInputDisable({ ...inputDisable, newPassword: false })}
              onBlur={() => setInputDisable({ ...inputDisable, newPassword: false })}
            />
            <input
              readOnly={inputDisable.confirmPassword}
              type="password"
              className="form-control mt-1`"
              value={resetPasswordDetails.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
              name="confirmPassword"
              onFocus={() => setInputDisable({ ...inputDisable, confirmPassword: false })}
              onBlur={() => setInputDisable({ ...inputDisable, confirmPassword: false })}
            />
            <button
              type="button"
              onClick={handleSubmit}
              className={`btn  btn-secondary ${styles['submit-btn']} pr-5 pl-5 mt-5`}>
              Reset
            </button>
          </form>
        </div>
      )}
    </>
  );
};
export default ResetPassword;
