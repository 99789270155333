/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Bar, Line } from 'react-chartjs-2';
import { GetCreditsStats, GetGameCount } from '../../services';
import { useGameConut, useCreditsConut } from '../../store';
import { faArrowDown, faArrowsLeftRightToLine } from '@fortawesome/free-solid-svg-icons';
import ArrowDown from '../../assets/arrowdown.svg';
import ArrowRight from '../../assets/rightarrow.svg';
import ShowToast from '../../components/ShowToast';
import moment from 'moment/moment';
import styles from '../programs/program.module.scss';
import { forEach, includes } from 'ramda';
import { isMobile, monthObject, weekObject } from '../../utils';
import TopUserModal from './TopUserModal';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Line Chart - Multi Axis',
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: false,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

export default function Charts() {
  const gameData = useGameConut((g) => g.gameData);
  const gameDemo = useGameConut((g) => g.gameDemo);
  const half_day = useCreditsConut((g) => g.half_day);
  const full_day = useCreditsConut((g) => g.full_day);
  const total_credits = useCreditsConut((g) => g.total_credits);
  const halfCredit_count = useCreditsConut((g) => g.halfCredit_count);
  const fullCredit_count = useCreditsConut((g) => g.fullCredit_count);
  const totalCredit_count = useCreditsConut((g) => g.totalCredit_count);
  const half_users = useCreditsConut((g) => g.half_users);
  const full_users = useCreditsConut((g) => g.full_users);
  const comb_users = useCreditsConut((g) => g.comb_users);
  const setGameData = useGameConut((g) => g.setGameData);
  const setPaidCount = useGameConut((g) => g.setPaidCount);
  const setPaidUsers = useGameConut((g) => g.setPaidUsers);
  const setDemoUsers = useGameConut((g) => g.setDemoUsers);
  const setDemoCount = useGameConut((g) => g.setDemoCount);
  const setCombinedUsers = useGameConut((g) => g.setCombinedUsers);
  const setTotalCount = useGameConut((g) => g.setTotalCount);

  const demo_count = useGameConut((g) => g.demo_count);
  const paid_count = useGameConut((g) => g.paid_count);
  const total_count = useGameConut((g) => g.total_count);
  const combined_users = useGameConut((g) => g.combined_users);
  const demo_user = useGameConut((g) => g.demo_user);
  const paid_user = useGameConut((g) => g.paid_user);
  const [position, setPosition] = useState(false);
  const [paramCount, setParamCount] = useState(0);
  const [isUsage, setIsUsage] = useState([]);
  const [showTop10, setShowTop10] = useState();
  const [isPurchase, setIsPurchase] = useState(false);
  const [rangeOption, setRangeOption] = useState(30);
  const [gameOption, setGameOption] = useState([]);
  const [firstDate, setFirstDate] = useState();
  const [prevDate, setPrevDate] = useState();
  const [viewType, setViewTpe] = useState('daily');
  const [usage, setUsage] = React.useState([]);
  const [purchase, setPurchase] = React.useState([]);
  const [purchaseArray, setPurchaseArray] = React.useState([]);
  const [value, onChange] = useState([new Date(), new Date()]);
  // const [minDate, setMinDate] = useState(Object.keys(gameData)[Object.keys(gameData).length - 1]);
  // const [maxDate, setMaxDate] = useState(Object.keys(gameData)[0]);
  const [labels, setLabels] = useState();
  const [faker, setFaker] = useState();
  const [faker1, setFaker1] = useState();
  const [faker2, setFaker2] = useState();
  const [faker3, setFaker3] = useState();
  const [selectedCustomDates, setSelectedCustomDates] = useState([]);
  const [faker4, setFaker4] = useState();
  const [checkedValues, setCheckedValues] = useState([]);
  const [openModal, setOpenModal] = useState();
  let updatelab = Object.keys(gameData);
  let updatelab1 = Object.keys(gameDemo);
  let data;
  let datasets = [];
  const [array, setArray] = useState([]);
  const [top10, setTop10] = useState([]);

  const [showData, setShowData] = useState();
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date).toISOString().split('T')[0]);
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }
  function removeUsageData() {
    setGameData({});
    setPaidCount(0);
    setPaidUsers({});
    setDemoUsers({});
    setDemoCount(0);
    setCombinedUsers({});
    setTotalCount(0);
  }
  useEffect(() => {
    setShowData(10 / paramCount);
    setTop10([]);
  }, [paramCount]);
  useEffect(() => {
    console.log('isUsage', isUsage);

    if (isUsage.includes('usage') && isUsage.includes('purchase')) {
      setShowTop10(false);
    } else {
      setShowTop10(true);
    }
  }, [isUsage, paramCount]);
  useEffect(() => {
    if (viewType === 'daily') {
      // setLabels(Object.keys(gameData));
      let dummyLabels = [];
      let dummyGameData = [];
      let dummyGameDemo = [];
      let days;
      if (prevDate && firstDate) {
        days = getDatesInRange(new Date(prevDate), new Date(firstDate));

        days.forEach((d) => {
          let date = new Date(d);
          let dateMonth = date.getMonth() + 1;
          let dateDay = date.getDate();

          dummyLabels = [...dummyLabels, `${dateDay}-${dateMonth}`];
          dummyGameData = [...dummyGameData, gameData[d] ? gameData[d] : 0];
          if (Object.keys(gameDemo).length) {
            dummyGameDemo = [...dummyGameDemo, gameDemo[d] ? gameDemo[d] : 0];
          }
        });
        setLabels(dummyLabels);
      }
      setFaker(dummyGameData);
      // setFaker(Object.values(gameData));
      setFaker1(dummyGameDemo);
      // setFaker1(Object.values(gameDemo));
    }
  }, [gameData, viewType, gameDemo, prevDate, firstDate]);

  useEffect(() => {
    //set your logic here
    if (viewType === 'weekly') {
      let newWeek = {};
      let total = 0;
      let days;
      days = getDatesInRange(new Date(prevDate), new Date(firstDate));
      for (let i = 0; i < days.length; i++) {
        const weekNumber = moment(days[i]).week();
        const currentVal = newWeek[`Week ${weekNumber}`] ? newWeek[`Week ${weekNumber}`] : 0;

        const newVal = gameData[days[i]] ? gameData[days[i]] : 0;
        newWeek = {
          ...newWeek,
          [`Week ${weekNumber}`]: currentVal + newVal,
        };
      }

      const momentFirstDate = moment(firstDate);
      const momentSecondDate = moment(prevDate);
      const diffInDays = momentFirstDate.diff(momentSecondDate, 'days') + 1;

      // if (moment(prevDate).year() === moment(firstDate).year()) {
      console.log('diffInDaysdiffInDays', diffInDays);
      if (diffInDays < 364) {
        setLabels(Object.keys(newWeek));
        setFaker(Object.values(newWeek));
      } else {
        ShowToast('please select date in same year1', 'warning');
      }
    } else if (viewType === 'monthly') {
      let total = 0;
      let newMonth = {};
      let days;
      days = getDatesInRange(new Date(prevDate), new Date(firstDate));

      console.log('prevDate_1', prevDate);
      console.log('firstDate_1', firstDate);
      console.log('daysdays', days);

      for (let i = 0; i < days.length; i++) {
        const monthNum = moment(updatelab[i]).month();
        const monthName = moment(days[i]).format('MMMM');
        const currentVal = newMonth[monthName] ? newMonth[monthName] : 0;
        const newVal = gameData[days[i]] ? gameData[days[i]] : 0;
        newMonth = { ...newMonth, [monthName]: currentVal + newVal };
      }

      const momentFirstDate = moment(firstDate);
      const momentSecondDate = moment(prevDate);
      const diffInDays = momentFirstDate.diff(momentSecondDate, 'days') + 1;

      // if (moment(prevDate).year() === moment(firstDate).year()) {
      if (diffInDays < 364) {
        setLabels(Object.keys(newMonth));
        setFaker(Object.values(newMonth));
      } else {
        ShowToast('please select date in same year2', 'warning');
      }
    }
  }, [viewType, gameData, prevDate, firstDate]);
  useEffect(() => {
    //set your logic here
    if (viewType === 'weekly' && Object.keys(gameDemo).length) {
      let newWeek = {};
      let days;
      days = getDatesInRange(new Date(prevDate), new Date(firstDate));
      for (let i = 0; i < days.length; i++) {
        const weekNumber = moment(days[i]).week();

        const currentVal = newWeek[`Week ${weekNumber}`] ? newWeek[`Week ${weekNumber}`] : 0;
        const newVal = gameDemo[days[i]] ? gameDemo[days[i]] : 0;
        newWeek = {
          ...newWeek,
          [`Week ${weekNumber}`]: currentVal + newVal,
        };
      }

      const momentFirstDate = moment(firstDate);
      const momentSecondDate = moment(prevDate);
      const diffInDays = momentFirstDate.diff(momentSecondDate, 'days') + 1;

      // if (moment(prevDate).year() === moment(firstDate).year()) {
      if (diffInDays < 364) {
        setLabels(Object.keys(newWeek));
        setFaker1(Object.values(newWeek));
      } else {
        ShowToast('please select date in same year3', 'warning');
      }
    } else if (viewType === 'monthly' && Object.keys(gameDemo).length) {
      let newMonth = {};
      let days;
      days = getDatesInRange(new Date(prevDate), new Date(firstDate));
      for (let i = 0; i < days.length; i++) {
        const monthNum = moment(updatelab[i]).month();
        const monthName = moment(days[i]).format('MMMM');
        const currentVal = newMonth[monthName] ? newMonth[monthName] : 0;
        const newVal = gameDemo[days[i]] ? gameDemo[days[i]] : 0;
        newMonth = { ...newMonth, [monthName]: currentVal + newVal };
      }

      const momentFirstDate = moment(firstDate);
      const momentSecondDate = moment(prevDate);
      const diffInDays = momentFirstDate.diff(momentSecondDate, 'days') + 1;

      // if (moment(prevDate).year() === moment(firstDate).year()) {
      if (diffInDays < 364) {
        setLabels(Object.keys(newMonth));
        setFaker1(Object.values(newMonth));
      } else {
        ShowToast('please select date in same year4', 'warning');
      }
    }
  }, [viewType, gameDemo, prevDate, firstDate]);

  useEffect(() => {
    if (Object.keys(half_day).length) {
      if (viewType === 'daily') {
        let dummyLabels = [];
        let dummyGameData = [];
        let dummyGameDemo = [];
        let days;
        if (prevDate && firstDate) {
          days = getDatesInRange(new Date(prevDate), new Date(firstDate));

          days.forEach((d) => {
            let date = new Date(d);
            let dateMonth = date.getMonth() + 1;
            let dateDay = date.getDate();

            dummyLabels = [...dummyLabels, `${dateDay}-${dateMonth}`];
            dummyGameData = [...dummyGameData, half_day[d] ? half_day[d] : 0];
          });
          setLabels(dummyLabels);
        }
        setFaker2(dummyGameData);
      }
      if (viewType === 'weekly') {
        let newWeek = {};
        let days;
        days = getDatesInRange(new Date(prevDate), new Date(firstDate));
        for (let i = 0; i < days.length; i++) {
          const weekNumber = moment(days[i]).week();

          const currentVal = newWeek[`Week ${weekNumber}`] ? newWeek[`Week ${weekNumber}`] : 0;
          const newVal = half_day[days[i]] ? half_day[days[i]] : 0;
          newWeek = {
            ...newWeek,
            [`Week ${weekNumber}`]: currentVal + newVal,
          };
        }
        if (moment(prevDate).year() === moment(firstDate).year()) {
          setLabels(Object.keys(newWeek));
          setFaker2(Object.values(newWeek));
        }
      }
      if (viewType === 'monthly') {
        let newMonth = {};
        let days;
        days = getDatesInRange(new Date(prevDate), new Date(firstDate));
        for (let i = 0; i < days.length; i++) {
          const monthNum = moment(updatelab[i]).month();
          const monthName = moment(days[i]).format('MMMM');
          const currentVal = newMonth[monthName] ? newMonth[monthName] : 0;
          const newVal = half_day[days[i]] ? half_day[days[i]] : 0;
          newMonth = { ...newMonth, [monthName]: currentVal + newVal };
        }
        if (moment(prevDate).year() === moment(firstDate).year()) {
          setLabels(Object.keys(newMonth));
          setFaker2(Object.values(newMonth));
        }
      }
    } else {
      setFaker2({});
    }
    //  full_credits
    if (Object.keys(full_day).length) {
      if (viewType === 'daily') {
        let dummyLabels = [];
        let dummyGameData = [];
        let dummyGameDemo = [];
        let days;
        if (prevDate && firstDate) {
          days = getDatesInRange(new Date(prevDate), new Date(firstDate));

          days.forEach((d) => {
            let date = new Date(d);
            let dateMonth = date.getMonth() + 1;
            let dateDay = date.getDate();

            dummyLabels = [...dummyLabels, `${dateDay}-${dateMonth}`];
            dummyGameData = [...dummyGameData, full_day[d] ? full_day[d] : 0];
          });
          setLabels(dummyLabels);
        }
        setFaker3(dummyGameData);
      }
      if (viewType === 'weekly') {
        let newWeek = {};
        let days;
        days = getDatesInRange(new Date(prevDate), new Date(firstDate));
        for (let i = 0; i < days.length; i++) {
          const weekNumber = moment(days[i]).week();

          const currentVal = newWeek[`Week ${weekNumber}`] ? newWeek[`Week ${weekNumber}`] : 0;
          const newVal = full_day[days[i]] ? full_day[days[i]] : 0;
          newWeek = {
            ...newWeek,
            [`Week ${weekNumber}`]: currentVal + newVal,
          };
        }
        if (moment(prevDate).year() === moment(firstDate).year()) {
          setLabels(Object.keys(newWeek));
          setFaker3(Object.values(newWeek));
        }
      }
      if (viewType === 'monthly') {
        let newMonth = {};
        let days;
        days = getDatesInRange(new Date(prevDate), new Date(firstDate));
        for (let i = 0; i < days.length; i++) {
          const monthNum = moment(updatelab[i]).month();
          const monthName = moment(days[i]).format('MMMM');
          const currentVal = newMonth[monthName] ? newMonth[monthName] : 0;
          const newVal = full_day[days[i]] ? full_day[days[i]] : 0;
          newMonth = { ...newMonth, [monthName]: currentVal + newVal };
        }
        if (moment(prevDate).year() === moment(firstDate).year()) {
          setLabels(Object.keys(newMonth));
          setFaker3(Object.values(newMonth));
        }
      }
    } else {
      setFaker3({});
    }
    // total_credits
    if (Object.keys(total_credits).length) {
      if (viewType === 'daily') {
        let dummyLabels = [];
        let dummyGameData = [];
        let dummyGameDemo = [];
        let days;
        if (prevDate && firstDate) {
          days = getDatesInRange(new Date(prevDate), new Date(firstDate));

          days.forEach((d) => {
            let date = new Date(d);
            let dateMonth = date.getMonth() + 1;
            let dateDay = date.getDate();

            dummyLabels = [...dummyLabels, `${dateDay}-${dateMonth}`];
            dummyGameData = [...dummyGameData, total_credits[d] ? total_credits[d] : 0];
          });
          setLabels(dummyLabels);
        }
        setFaker4(dummyGameData);
      }
      if (viewType === 'weekly') {
        let newWeek = {};
        let days;
        days = getDatesInRange(new Date(prevDate), new Date(firstDate));
        for (let i = 0; i < days.length; i++) {
          const weekNumber = moment(days[i]).week();

          const currentVal = newWeek[`Week ${weekNumber}`] ? newWeek[`Week ${weekNumber}`] : 0;
          const newVal = total_credits[days[i]] ? total_credits[days[i]] : 0;
          newWeek = {
            ...newWeek,
            [`Week ${weekNumber}`]: currentVal + newVal,
          };
        }

        const momentFirstDate = moment(firstDate);
        const momentSecondDate = moment(prevDate);
        const diffInDays = momentFirstDate.diff(momentSecondDate, 'days') + 1;

        // if (moment(prevDate).year() === moment(firstDate).year()) {
        if (diffInDays < 364) {
          setLabels(Object.keys(newWeek));
          setFaker4(Object.values(newWeek));
        } else {
          ShowToast('please select date in same year5', 'warning');
        }
      }
      if (viewType === 'monthly') {
        let newMonth = {};
        let days;
        days = getDatesInRange(new Date(prevDate), new Date(firstDate));
        for (let i = 0; i < days.length; i++) {
          const monthNum = moment(updatelab[i]).month();
          const monthName = moment(days[i]).format('MMMM');
          const currentVal = newMonth[monthName] ? newMonth[monthName] : 0;
          const newVal = total_credits[days[i]] ? total_credits[days[i]] : 0;
          newMonth = { ...newMonth, [monthName]: currentVal + newVal };
        }
        if (moment(prevDate).year() === moment(firstDate).year()) {
          setLabels(Object.keys(newMonth));
          setFaker4(Object.values(newMonth));
        }
      }
    } else {
      setFaker4({});
    }
  }, [half_day, full_day, total_credits, viewType, prevDate, firstDate]);

  if (Object.keys(half_day).length) {
    datasets = [
      ...datasets,
      {
        label: 'Half Day Credits',
        data: faker2,
        borderColor: '#e7ad49',
        backgroundColor: '#825a16',
        lineTension: 0.27,
        yAxisID: 'y',
      },
    ];
  }

  if (Object.keys(total_credits)) {
    datasets = [
      ...datasets,
      {
        label: 'Total Credits',
        data: faker4,
        borderColor: '#363534',
        backgroundColor: 'black',
        lineTension: 0.27,
        yAxisID: 'y',
      },
    ];
  }

  if (Object.keys(full_day)) {
    datasets = [
      ...datasets,
      {
        label: 'Full Day Credits',
        data: faker3,
        borderColor: '#855f1d',
        backgroundColor: 'black',
        lineTension: 0.27,
        yAxisID: 'y',
      },
    ];
  }

  if (Object.keys(gameDemo).length) {
    datasets = [
      {
        label: 'Paid Games',
        data: faker,
        borderColor: '#052258',
        backgroundColor: '#6c45ed',
        lineTension: 0.27,
        yAxisID: 'y',
      },
      {
        label: 'Demo Games',
        data: faker1,
        borderColor: 'rgb(238, 116, 152)',
        backgroundColor: 'rgb(188 6 61)',
        lineTension: 0.27,
        yAxisID: 'y',
      },
      ...datasets,
    ];
  } else {
    datasets = [
      {
        label: 'Games',
        data: faker,
        borderColor: '#052258',
        backgroundColor: '#6c45ed',
        lineTension: 0.27,
        yAxisID: 'y',
      },
      ...datasets,
    ];
  }

  data = {
    labels,
    // lables: updatedLabels,
    datasets: datasets,
  };

  const RangeOptions = [
    {
      label: 'Past 7 Days',
      value: 7,
    },
    {
      label: 'Past 30 Days',
      value: 30,
    },
    {
      label: 'Past 90 Days',
      value: 90,
    },
    {
      label: 'Custom',
      value: 'custom',
    },
  ];
  const UsageOptions = [
    {
      label: 'Combined',
      value: 'both',
      id: 'Total Combined Sessions',
    },
    {
      label: 'Demo',
      value: 'demo',
      id: 'Total Demo Session',
    },
    {
      label: 'Paid',
      value: 'paid',
      id: 'Total Paid Sessions',
    },
  ];
  const PurchaseOptions = [
    {
      label: 'Half Day',
      value: 'half',
      id: 'Total Half Day Credits',
    },
    {
      label: 'Full Day',
      value: 'full',
      id: 'Total Full Day Credits',
    },
    {
      label: 'Combined',
      value: 'total',
      id: 'Total Credits',
    },
  ];

  const TopUsersFunc = (dataObj, title) => {
    return (
      <>
        <p style={{ fontSize: '15px', fontWeight: 'bold' }}>{title}</p>
        {Object.entries(dataObj).map(([k, v], i) => (
          <>
            {i < Math.floor(showData) ? (
              <p style={{ display: 'grid' }}>
                {k} :{v}
              </p>
            ) : (
              ''
            )}
          </>
        ))}
      </>
    );
  };
  const setSelectedRange = (e) => {
    setRangeOption(e);
  };
  const setSelectedGames = (e) => {
    setGameOption(e);
  };

  const handleOnChange = async (e, type) => {
    console.log('type123', type);
    console.log('eeeeeeeeeee', e);
    console.log('firstDate', firstDate);
    console.log('prevDate', prevDate);

    if (e.target) {
      if (!checkedValues.includes(e.target.name)) {
        setCheckedValues([...checkedValues, e.target.name]);

        if (type[0] === 'type-buy') {
          if (!isUsage.includes('purchase')) setIsUsage([...isUsage, 'purchase']);
        }
        if (type[0] === 'type-dd') {
          if (!isUsage.includes('usage')) setIsUsage([...isUsage, 'usage']);
        }
      } else {
        const j = checkedValues.indexOf(e.target.name);
        const dummyCheckedValues = [...checkedValues];
        dummyCheckedValues.splice(j, 1);
        setCheckedValues(dummyCheckedValues);

        if (
          type[0] === 'type-buy' &&
          !dummyCheckedValues.includes('half') &&
          !dummyCheckedValues.includes('full') &&
          !dummyCheckedValues.includes('total')
        ) {
          const j = isUsage.indexOf('purchase');
          if (j > -1) {
            isUsage.splice(j, 1);
            setIsUsage(isUsage);
          }
        }
        if (
          type[0] === 'type-dd' &&
          !dummyCheckedValues.includes('both') &&
          !dummyCheckedValues.includes('demo') &&
          !dummyCheckedValues.includes('paid')
        ) {
          const i = isUsage.indexOf('usage');
          if (i > -1) {
            isUsage.splice(i, 1);
            setIsUsage(isUsage);
          }
        }
      }

      console.log('eeee', e.target.name);
      console.log('eeee', e.target.value);
    }

    let typeDD = gameOption ? gameOption : '';
    let daysRange = rangeOption ? rangeOption.value : '';

    let customRange = {
      firstDate: firstDate,
      prevDate: prevDate,
    };
    if (type[0] === 'type-buy') {
      setIsUsage([...isUsage, 'usage']);
      const j = isUsage.indexOf('usage');
      if (j > -1) {
        isUsage.splice(j, 1);
        setIsUsage(isUsage);
      }
    }
    if (type[0] === 'type-dd') {
      setIsUsage([...isUsage, 'purchase']);
      const i = isUsage.indexOf('purchase');
      if (i > -1) {
        isUsage.splice(i, 1);
        setIsUsage(isUsage);
      }
    }
    if (type[0] === 'type-buy') {
      setPurchase([...purchase, e.target.id]);
      setPurchaseArray([...purchaseArray, e.target.name]);
      // setIsUsage(false);
      setParamCount(paramCount + 1);
      const j = purchaseArray.indexOf(e.target.name);
      if (j > -1) {
        setParamCount(paramCount - 1);
        purchaseArray.splice(j, 1);
        setPurchaseArray(purchaseArray);
      }
      const i = purchase.indexOf(e.target.id);
      if (i > -1) {
        purchase.splice(i, 1);
        setPurchase(purchase);
      }
      if (e.target.checked === true) {
        await getCredit(prevDate, firstDate, [...purchaseArray, e.target.name]);
      } else {
        await getCredit(prevDate, firstDate, purchaseArray);
      }
    }
    if (type[0] === 'type-dd') {
      setUsage([...usage, e.target.id]);
      const i = usage.indexOf(e.target.id);
      if (i > -1) {
        usage.splice(i, 1);
        setUsage(usage);
      }
      setGameOption([...gameOption, e.target.name]);
      // setIsUsage(false);

      setParamCount(paramCount + 1);
      const g = gameOption.indexOf(e.target.name);
      if (g > -1) {
        setParamCount(paramCount - 1);
        gameOption.splice(g, 1);
        setGameOption(gameOption);
      }
      typeDD = e.target.name;
      setArray([...array, e.target.name]);
      const index = array.indexOf(e.target.name);
      if (index > -1) {
        array.splice(index, 1);
        setArray(array);
      }
      if (e.target.checked === true) {
        await getCount(prevDate, firstDate, [...array, e.target.name]);
      } else {
        await getCount(prevDate, firstDate, array);
        removeUsageData();
      }
    }
    if (type === 'days-range') {
      console.log('inside dayarangeee');

      setRangeOption(e);
      Date.prototype.addDays = function (days) {
        // Add days to given date
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      let today = new Date();

      if (e?.value !== 'custom') {
        console.log('custom firstDatefirstDate', firstDate);
        console.log('customprevDateprevDate', prevDate);

        setFirstDate(new Date(Date.now()).toISOString().replace('T', ' '));
        console.log(
          "today?.addDays(-e?.value)?.toISOString().replace('T', ' ')",
          today?.addDays(-e?.value)?.toISOString().replace('T', ' ')
        );
        setPrevDate(today?.addDays(-e?.value)?.toISOString().replace('T', ' '));
        daysRange = e.value;
        if (gameOption.length) {
          await getCount(
            today?.addDays(-e?.value)?.toISOString().replace('T', ' '),
            new Date(Date.now()).toISOString().replace('T', ' '),
            gameOption
          );
        }
        if (purchaseArray.length) {
          await getCredit(
            today?.addDays(-e?.value)?.toISOString().replace('T', ' '),
            new Date(Date.now()).toISOString().replace('T', ' '),
            purchaseArray
          );
        }
      }
    }

    if (e?.value == 'custom') {
      if (selectedCustomDates.length === 2) {
        onChangeDateRange(selectedCustomDates);

        customRange.firstDate = firstDate;
        customRange.prevDate = prevDate;
        if (purchaseArray.length) {
          await getCount(
            selectedCustomDates[0]?.toISOString().replace('T', ' '),
            selectedCustomDates[1]?.toISOString().replace('T', ' '),
            gameOption
          );
        }
        if (purchaseArray.length) {
          await getCredit(
            selectedCustomDates[0]?.toISOString().replace('T', ' '),
            selectedCustomDates[1]?.toISOString().replace('T', ' '),
            purchaseArray
          );
        }
      }
    }

    if (type === 'custom-range' && e) {
      setSelectedCustomDates(e);

      onChangeDateRange(e);

      customRange.firstDate = firstDate;
      customRange.prevDate = prevDate;
      if (purchaseArray.length) {
        await getCount(
          e[0]?.toISOString().replace('T', ' '),
          e[1]?.toISOString().replace('T', ' '),
          gameOption
        );
      }
      if (purchaseArray.length) {
        await getCredit(
          e[0]?.toISOString().replace('T', ' '),
          e[1]?.toISOString().replace('T', ' '),
          purchaseArray
        );
      }
    }
  };

  const onChangeDateRange = (e) => {
    console.log('e[0]?.', e[0]);
    console.log("e[0]?.toISOString().replace('T', ' ')", e[0]?.toISOString());
    console.log('e[1]?.', e[1]);
    console.log("e[1]?.toISOString().replace('T', ' ')", e[1]?.toISOString());
    setPrevDate(e[0]?.toISOString().replace('T', ' '));
    setFirstDate(e[1]?.toISOString().replace('T', ' '));
    onChange(e);
  };

  const count = GetGameCount();
  const credits = GetCreditsStats();
  const getCount = async (preDate, firstdate, gameOptions) => {
    if (preDate && firstdate && gameOptions.length) {
      count.mutate({
        prevDate: preDate,
        curDate: firstdate,
        type: gameOptions,
      });
    } else {
      ShowToast('please select date and type', 'warning');
    }
  };
  const getCredit = async (preDate, firstdate, gameOptions) => {
    if (preDate && firstdate) {
      credits.mutate({
        prevDate: preDate,
        curDate: firstdate,
        type: gameOptions,
      });
    } else {
      ShowToast('please select date and type', 'warning');
    }
  };
  return (
    <>
      <div className="row m-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="col-md-3 col-sm-12">
          <Select
            placeholder="Select Range"
            options={RangeOptions}
            isClearable
            isSearchable
            value={rangeOption}
            onChange={(e) => handleOnChange(e, 'days-range')}
          />
          {rangeOption && rangeOption.value === 'custom' ? (
            <div
              className="pt-4"
              style={{
                zoom: 0.97,
              }}>
              <DateRangePicker
                clearIcon={null}
                onChange={(e) => handleOnChange(e, 'custom-range')}
                value={value}
                onCalendarOpen={() => setPosition(true)}
                onCalendarClose={() => setPosition(false)}
                calendarIcon={
                  <label htmlFor="calender-icon">
                    <div className="date-wrapper" style={{ width: '2.1rem' }}>
                      <img
                        src={ArrowDown}
                        alt=""
                        id="calender-icon"
                        style={{ paddingTop: '0.5rem' }}
                      />
                    </div>
                    <img src={ArrowDown} alt="" id="calender-icon" style={{ display: 'none' }} />
                  </label>
                }
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div
          className="col-md-5 col-sm-12 "
          style={
            isMobile
              ? {
                  marginTop: 10,
                  marginBottom: 10,
                }
              : {}
          }>
          <button
            onClick={() => setViewTpe('daily')}
            className={`btn btn-primary ${styles['report-button']}`}
            style={{ margin: '0.3rem' }}>
            Daily
          </button>
          <button
            onClick={() => setViewTpe('weekly')}
            className={`btn btn-primary ${styles['report-button']}`}
            style={{ margin: '0.3rem' }}>
            Weekly
          </button>
          <button
            onClick={() => setViewTpe('monthly')}
            className={`btn btn-primary ${styles['report-button']}`}
            style={{ margin: '0.3rem' }}>
            Monthly
          </button>
        </div>
        <div className="col-6 col-md-2 ">
          <p style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'left' }}>Usage</p>
          {UsageOptions.map((x) => {
            return (
              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'baseline' }}>
                <input
                  type="checkbox"
                  // className="custom-control-input"
                  id={x.id}
                  name={x.value}
                  onChange={(e) => {
                    console.log('eeee onclick', e.target);
                    handleOnChange(e, ['type-dd', x.value]);
                  }}
                />
                <p style={{ marginLeft: '2rem' }}>{x.label}</p>
              </div>
            );
          })}
        </div>
        <div className="col-6 col-md-2">
          <p style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'left' }}>Purchase</p>
          {PurchaseOptions.map((x) => {
            return (
              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'baseline' }}>
                <input
                  type="checkbox"
                  // className="custom-control-input"
                  id={x.id}
                  name={x.value}
                  onChange={(e) => handleOnChange(e, ['type-buy', x.value])}
                />
                <p style={{ marginLeft: '2rem' }}>{x.label}</p>
              </div>
            );
          })}
        </div>
      </div>
      {console.log('range213123Option', rangeOption)}

      <div style={{ width: '100%', display: 'flex', flexDirection: isMobile && 'column' }}>
        <div
          style={position ? { width: '70%', padding: '1rem' } : { width: '100%', padding: '1rem' }}>
          <Line options={options} data={data} />
        </div>
        <div
          className="col-md-3 col-sm-12"
          style={{
            textAlign: 'left',
            marginBottom: 10,
            // marginLeft: '3.7rem',
          }}>
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: 20, textAlign: 'center' }}>
            Key Stats
          </p>
          {usage.length ? <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Usage</p> : ''}
          {usage.map((x) => {
            return (
              <p>
                {x}:
                {x === 'Total Combined Sessions'
                  ? total_count
                  : x === 'Total Demo Session'
                  ? demo_count
                  : paid_count}
              </p>
            );
          })}
          {purchase.length ? <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Purchase</p> : ''}
          {purchase.map((x) => {
            return (
              <p>
                {x}:
                {x === 'Total Half Day Credits'
                  ? halfCredit_count
                  : x === 'Total Full Day Credits'
                  ? fullCredit_count
                  : totalCredit_count}
              </p>
            );
          })}
          <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Top 10 Users</p>
          {paid_user && Object.keys(paid_user).length && showTop10 ? (
            <>{TopUsersFunc(paid_user, 'Paid')}</>
          ) : (
            ''
          )}
          {demo_user && Object.keys(demo_user).length && showTop10 ? (
            <>{TopUsersFunc(demo_user, 'Demo')}</>
          ) : (
            ''
          )}
          {combined_users && Object.keys(combined_users).length && showTop10 ? (
            <>{TopUsersFunc(combined_users, 'Combined')}</>
          ) : (
            ''
          )}
          {half_users && Object.keys(half_users).length && showTop10 ? (
            <>{TopUsersFunc(half_users, 'Half_day')}</>
          ) : (
            ''
          )}
          {full_users && Object.keys(full_users).length && showTop10 ? (
            <>{TopUsersFunc(full_users, 'Full_Day')}</>
          ) : (
            ''
          )}
          {/* {comb_users && Object.keys(comb_users).length && showTop10 ? (
            <>{TopUsersFunc(comb_users, 'Total')}</>
          ) : (
            ''
          )} */}
          {(comb_users && Object.keys(comb_users).length) ||
          (full_users && Object.keys(full_users).length) ||
          (half_users && Object.keys(half_users).length) ||
          (combined_users && Object.keys(combined_users).length) ||
          (demo_user && Object.keys(demo_user).length) ||
          (paid_user && Object.keys(paid_user).length && showTop10) ? (
            <>
              <button
                onClick={() => setOpenModal(true)}
                className={`btn btn-primary ${styles['report-button']}`}>
                See All
              </button>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <TopUserModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        comb_users={comb_users}
        full_users={full_users}
        half_users={half_users}
        combined_users={combined_users}
        demo_user={demo_user}
        paid_user={paid_user}
      />
    </>
  );
}
