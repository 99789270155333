import React from 'react';
import PropTypes from 'prop-types';

const TitleComponent = ({ title, heading, subtitle }) => {
  return (
    <>
      <h6 style={{ fontSize: '0.85rem' }}>{title}</h6>
      <h4>{heading}</h4>
      <h6 style={{ lineHeight: '0.8rem' }}>{subtitle}</h6>
    </>
  );
};
TitleComponent.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subtitle: PropTypes.string,
};
TitleComponent.defaultProps = {
  title: '',
  heading: '',
  subtitle: '',
};

export default TitleComponent;
