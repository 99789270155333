import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGameConfigCalamity } from '../../../store';

const Calamity = ({
  selectedRound,
  noOfCalamities,
  calamities,
  calamityLevels,
  exhaustLand,
  setNoOfCalamities,
  setCalamities,
  setCalamityLevels,
  setExhaustLand,
}) => {
  const [exhaustLandChecked, setExhaustLandChecked] = useState(exhaustLand);
  const addCalamities = () => {
    setCalamities((oldArray) => [...oldArray, 'Earthquake']);
    setCalamityLevels((oldArray) => [...oldArray, 'Level1']);
  };

  useEffect(()=>{
    setExhaustLandChecked(exhaustLand)
  }, [exhaustLand])

  const updateValue = (i, value, target) => {
    if (target === 'calamity') {
      const tempCalamity = [...calamities];
      tempCalamity[i] = value;
      setCalamities(tempCalamity);
    }

    if (target === 'level') {
      const tempCalamityLevel = [...calamityLevels];
      tempCalamityLevel[i] = value;
      setCalamityLevels(tempCalamityLevel);
    }
  };

  const deleteCalamity = (i) => {
    const tempCalamity = [...calamities];
    tempCalamity.splice(i, 1);
    setCalamities(tempCalamity);

    const tempCalamityLevel = [...calamityLevels];
    tempCalamityLevel.splice(i, 1);
    setCalamityLevels(tempCalamityLevel);
  };

  const calamity = (item, i) => {
    let currentCalamity = item.replace(/[-+()[\]""]/g, '');
    let currentCalamityLevel = calamityLevels[i].replace(/[-+()[\]""]/g, '');

    currentCalamity = currentCalamity.trim();
    currentCalamityLevel = currentCalamityLevel.trim();

    return (
      <div className="form-row mt-3 align-items-end" key={i}>
        <div className="col-md-2">
          <h5>
            Disaster&nbsp; 
            {i + 1}
          </h5>
        </div>
        <div className="col-md">
          <label htmlFor="temp-id"><b>Name</b> </label>
          <select
            disabled={selectedRound === 'demo'}
            className="form-control"
            name="calamities"
            placeholder="disaster name"
            onChange={(e) => updateValue(i, e.target.value, 'calamity')}
            data-index={i}
            value={currentCalamity}>
            <option value="Earthquake">Earthquake</option>
            <option value="Volcanic Eruption">Volcanic Eruption</option>
            <option value="Acid Rain">Acid Rain</option>
            <option value="Acid Storm">Acid Storm</option>
            <option value="Forest Fire">Forest Fire</option>
          </select>
        </div>
        <div className="col-md">
          <label htmlFor="temp-id"><b>Level</b> </label>
          <select
            className="form-control"
            disabled={selectedRound === 'demo'}
            onChange={(e) => updateValue(i, e.target.value, 'level')}
            name="calamities_lvl"
            data-index={i}
            value={currentCalamityLevel}>
            <option value="Level1">Level 1</option>
            <option value="Level2">Level 2</option>
            <option value="Level3">Level 3</option>
          </select>
        </div>
        <div className="col-md-1">
          <button
            disabled={selectedRound==="demo"}
            type="button"
            onClick={() => deleteCalamity(i)}
            className="btn btn-primary mt-2 px-4">
            Delete
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="calamity">
      <div style={{display:"flex"}}>
        <h1>Edit Disasters</h1>
        <div className="tooltips">
          <img 
            src="https://img.icons8.com/6d49f5/80/000000/help.png" 
            alt='question' 
            style={{width: "20px", paddingLeft:"5px"}} />
          <span 
            className="tooltiptext" 
            style={{borderRadius: "5px", width: "700px", textAlign: "left", padding: "5px"}}>
            All disasters in Evivve have the same effects. Their intensity varies based 
            on the level associated with the disaster. 
            <br /><br />
            <b>Disaster Impacts:</b>
            <br />
            1. Depending on where the disaster occurs, it would prevent players from 
            accessing that land. <br />
            2. Players could lose temporary access to the game. Meaning: they won&apos;t get 
            back into the game after the disaster is finished. They would typically get back 
            in after a subsequent disaster. You can bypass this with a paid-game feature 
            accessible in &apos;Game Controls&apos; called: Disaster Recovery. <br />
            3. Players could lose permanent access to the game. In this case, they would have
            to log in fresh thereby losing all their progress. In such cases, we encourage 
            the affected player to explore other ways of helping their team. 
            <br />
            The above mechanics are triggered through an algorithm in the game and may seem
            random -they&apos;re not.
            <br /><br />
            <b>Levels</b>
            <br /> Levels indicate the duration of the disaster. Meaning the number of game 
            days the disaster will last. <br />
            Level 1: 2 weeks <br />
            Level 2: 4 weeks <br />
            Level 3: 8 weeks <br />
            <br />
            <b>Pro Tip:</b> Decrease the number of disasters to make the game easier. 

          </span>
        </div>
      </div>
      
      <form className="p-4">
        <div className="form-row">
          <div className="col-md">
            {/* <label htmlFor="temp-id">
              No. Of Calamities
              {noOfCalamities}
            </label> */}
            {/* <input
              type="number"
              className="form-control"
              name="no_calamities"
              placeholder="No. Of Calamities"
              value={noOfCalamities}
              setNoOfCalamities
              onChange={(e) => {
                setNoOfCalamities(e.target.value);
              }}
            /> */}
          </div>
        </div>
        {calamities ? calamities.map((item, i) => calamity(item, i)) : null}
        <div className="text-center">
          {/* <span className="p-4">Add Disasters: </span> */}
          <button
            disabled={selectedRound === 'demo'}
            type="button"
            className="btn btn-secondary mt-3 px-5"
            onClick={addCalamities}>
            Add Disaster
          </button>
        </div>

        <div className="custom-control custom-checkbox mt-4 mb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="days_check"
            name="days_check"
            min={0}
            checked={exhaustLandChecked}
            disabled={selectedRound==="demo"}
            onClick={() => {
              setExhaustLandChecked(prev=>!prev);
              setExhaustLand(null);
            }}
          />
          <label className="custom-control-label" htmlFor="days_check">
            <b>Exhaust Land</b>
          </label>
          <div className="tooltips">
            <img 
              src="https://img.icons8.com/6d49f5/80/000000/help.png" 
              alt='question' 
              style={{width: "20px", paddingLeft:"5px"}} />
            <span 
              className="tooltiptext" 
              style={{borderRadius: "5px", width: "400px", textAlign: "left", padding: "5px"}}>
              The &apos;Exhaust Land&apos; feature causes players&apos; lands to get exhausted 
              and become &apos;barren&apos;. This is a powerful game feature that most players 
              don&apos;t see coming. <br /><br />
              When you activate this feature, you are required to enter a value in the field 
              below. That value signifies the number of harvests after which a land will 
              become barren. <br /><br />
              When a land becomes barren, the &apos;Harvest&apos; button turns into the 
              &apos;Barren&apos; button. When the player tries to harvest that land by clicking 
              the &apos;Barren&apos; button, they lose the money they would have spent in 
              harvesting the land but the land does not harvest. If players are not conscious of 
              this, they could end up losing a lot of money this way. <br /><br />
              <b>Pro Tip: </b>
              Keep this feature off for an easier game. A lower value will make the game harder 
              as lands will become barren sooner. 
            </span>
          </div>
        </div>
        <div
          className={
            exhaustLandChecked ? 'form-row align-items-end ' : 'form-row align-items-end d-none'
          }>
          <label 
            htmlFor="temp-id" 
            className="col-md-2 p-1" 
            style={{marginBottom:".3rem", marginTop:"1rem"}}>
            Trigger &apos;Barren&apos; In:
          </label>
          <input
            type="number"
            className="form-control col-md-2"
            placeholder="Harvest Count"
            name="exhaust_land"
            value={exhaustLand}
            onChange={(e) => setExhaustLand(e.target.value)}
          />
          <div className="col-md" />
        </div>
      </form>
    </div>
  );
};

Calamity.propTypes = {
  selectedRound: PropTypes.number,
  noOfCalamities: PropTypes.number,
  calamities: PropTypes.number,
  calamityLevels: PropTypes.number,
  exhaustLand: PropTypes.number,

  setNoOfCalamities: PropTypes.func,
  setCalamities: PropTypes.func,
  setCalamityLevels: PropTypes.func,
  setExhaustLand: PropTypes.func,
};

Calamity.defaultProps = {
  selectedRound: '',
  noOfCalamities: '',
  calamities: '',
  calamityLevels: '',
  exhaustLand: '',
  setNoOfCalamities: '',
  setCalamities: '',
  setCalamityLevels: '',
  setExhaustLand: '',
};

export default Calamity;
