/* eslint-disable max-len */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import DropdownComponent from '../dropdown/dropdown';
import { navLinksArray, navbarData } from './navbarData';
import gamitarLogo from '../../assets/bluepurple-evivve-logo.png';
import multisign from '../../assets/navbar/mult.png';
import bars from '../../assets/navbar/bars.png';
import { useTokenStore, useUserStore, useProgramStore } from '../../store';

import styles from './navbar.module.scss';
import { isMobile } from '../../utils';

const NavbarComponent = () => {
  const showAuthHeader = useTokenStore((s) => !s.accessToken);
  const [closemobilenav, setcloseMobileNav] = useState(false);
  const [active, setActive] = useState('');
  const [isCertified, setIsCertified] = useState();
  const programsList = useProgramStore((obj) => obj?.programsList ?? null);
  const handleClick = () => setcloseMobileNav(!closemobilenav);
  const closeMobileMenu = () => setcloseMobileNav(false);
  const user = useUserStore((s) => s.user);
  const closeOverlay = (e) => {
    if (closemobilenav) {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    // /for testing suppose "testNew@gmail.com" is certified
    if (user.is_Certified) {
      setIsCertified(true);
    } else {
      setIsCertified(false);
    }
    return null;
  }, [user]);

  const check = (id) => {
    if (!isCertified && id === 'My Program') {
      return false;
    }
    return true;
  };

  const NavBar = () => {
    return (
      <ul className={`${styles['navigation-bar-menu']} ${closemobilenav ? styles.active : ''}`}>
        {closemobilenav && isMobile && (
          <div style={{ width: '100%' }} onClick={handleClick}>
            <img
              style={{
                float: 'right',
                cursor: 'pointer',
                width: 20,
                marginRight: 50,
                marginTop: 10,
                marginBottom: 1,
              }}
              src={multisign}
              alt="multisign"
            />
          </div>
        )}

      {!showAuthHeader && <div className='nav-hover'
          onClick={() => location.href="/evivve3"}
          style={{
              display: 'flex',
              padding: '20px',
              alignItems: 'center',
              position: 'relative',
              marginRight: '10px',
              cursor: 'pointer'
          }}>
            <div>Try <b>Evivve 3</b></div>
            <div style={{
              position: 'absolute',
              bottom: '14px',
              fontSize: '12px',
              padding: '0px 3px',
              background: 'red',
              borderRadius: '4px',
              right: '0px',
              color: 'white'
            }}>Beta</div>
        </div>}

        {!showAuthHeader &&
          navbarData.map((data) =>
            check(data.id) ? (
              <DropdownComponent
                handleClick={handleClick}
                data={data}
                active={active}
                setActive={(e) => {
                  console.log(e);
                  setActive(e);
                }}
                key={data.id}
              />
            ) : null
          )}
        {showAuthHeader && (
          <>
            {navLinksArray.map((NavLinks) => NavLinks.url.includes('https') ? (
              <li key={NavLinks} className={styles['navigation-bar-item']}>
                <a
                  href={NavLinks.url}
                  target="_blank"
                  rel="noreferrer"
                  className={styles['navigation-bar-links']}
                  onClick={closeMobileMenu}>
                  {NavLinks.name}
                </a>
              </li>
            ) : (
              <li key={NavLinks} className={styles['navigation-bar-item']}>
                <Link
                  to={{ pathname: NavLinks.url }}
                  target="_blank"
                  className={styles['navigation-bar-links']}
                  onClick={closeMobileMenu}>
                  {NavLinks.name}
                </Link>
              </li>
            ))}
          </>
        )}
      </ul>
    );
  };
  return (
    <>
      <nav onClick={closeOverlay} className={styles['navigation-bar']}>
        <header>
          <Link
            to={programsList && programsList.length ? '/my-programs' : '/'}
            onClick={closeMobileMenu}>
            <img className={styles['navigation-bar-logo']} src={gamitarLogo} alt="gamitar-logo" />
          </Link>

          <div className={styles['menu-icon']} onClick={handleClick}>
            {closemobilenav ? (
              ''
            ) : (
              <img style={{ cursor: 'pointer', width: 40, marginRight: 0 }} src={bars} alt="bars" />
            )}
          </div>
        </header>
        <div
          className={`${styles.navoverlay} `}
          style={{ display: closemobilenav ? 'block' : 'none' }}
        />
        {isMobile ? closemobilenav && <NavBar /> : <NavBar />}
      </nav>
    </>
  );
};

export default withRouter(NavbarComponent);
