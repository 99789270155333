/* eslint-disable */
import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import { isMobile } from '../../utils';

import styles from './calendar.module.scss';

const today = new Date();
const currentYear = today.getFullYear();
const fromMonth = new Date(currentYear, today.getMonth());
const currMonth = fromMonth.getMonth();
const toMonth = new Date(currentYear + 1, 11);

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const myDateArray = [...Array(12).keys()].map((i) => ({
  month: (currMonth + i) % 12,
  name: monthNames[(currMonth + i) % 12],
  year: currMonth + i >= 12 ? currentYear + 1 : currentYear,
}));

const App = ({ selectedDay, setSelectedDay }) => {
  const [month, setMonth] = useState(fromMonth);

  const handleDayClick = (day, { selected }) => {
    if (!selected) {
      setSelectedDay(day);
    }
  };

  return (
    <div
      className={`${styles['cal-container']}`}
      style={{
        display: isMobile ? 'flex' : 'inline-flex',
        flexDirection: isMobile && 'column',
      }}>
      <div
        className={styles['months-scroll']}
        style={{
          maxHeight: isMobile && 130,
          width: isMobile && '100%',
          textAlign: isMobile && 'center',
        }}>
        {myDateArray.map((d) => (
          <div
            key={d.name}
            onClick={() => {
              setMonth(new Date(d.year, d.month));
            }}
            className={`${styles.month}
             ${d.month === month.getMonth() ? styles['active-month'] : ''}`}>
            {d.name} {d.month === 0 ? d.year : ''}
          </div>
        ))}
      </div>
      <div
        className={styles['day-picker']}
        style={{ width: 'fit-content', marginLeft: !isMobile && 40 }}>
        <DayPicker
          canChangeMonth={false}
          month={month}
          fromMonth={fromMonth}
          toMonth={toMonth}
          captionElement={<></>}
          selectedDays={selectedDay}
          onDayClick={handleDayClick}
          disabledDays={[
            {
              before: today,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default App;
