import React from 'react';
import PropTypes from 'prop-types';

const CardComponent = ({ children }) => {
  return (
    <>
      <div className="card bg-primary text-secondary">
        <div className="card-body">
          {children}
        </div>
      </div>
    </>
  );
};

CardComponent.propTypes = {
  children: PropTypes.node.isRequired,
};
export default CardComponent;
