/* eslint-disable react/no-array-index-key */
import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';
import ShowToast from '../../components/ShowToast';
import tz from '../../utils/timezone.json';
import { useGoogleSignup } from '../../services';

const AuthSignUp = ({
  email,
  password,
  setEmail,
  setPassword,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  selectTimezone,
  setTimezone,
  retypePassword,
  setRetypePassword,
  passwordInputRef,
  confirmPasswordInputRef,
  passwordValidate,
  setCertificationCode,
  certificationCode,
  required1,
}) => {
  const googleSignup = useGoogleSignup();
  const handleGoogleSignup = (credentialResponse) => {
    googleSignup.mutate(credentialResponse);
  };

  return (
    <>
      <input
        type="text"
        className={`form-control
        ${required1 ? 'border-danger form-control is-invalid' : ''}`}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        placeholder="First Name"
      />
      <input
        type="text"
        className={`form-control mt-1
        ${required1 ? 'border-danger form-control is-invalid' : ''}`}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        placeholder="Last Name"
      />
      <input
        type="text"
        className={`form-control mt-1
        ${required1 ? 'border-danger form-control is-invalid' : ''}`}
        name="emailAddress"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email Address"
      />
      <input
        type="password"
        className={`form-control mt-1
        ${required1 ? 'border-danger form-control is-invalid' : ''}`}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        ref={passwordInputRef}
        placeholder="Password"
      />
      <input
        type="password"
        className={`form-control mt-1
        ${required1 ? 'border-danger form-control is-invalid' : ''}`}
        name="retry_password"
        value={retypePassword}
        onChange={(e) => setRetypePassword(e.target.value)}
        ref={confirmPasswordInputRef}
        placeholder="Retype Password"
      />
      {/* <input
        type="test"
        className={`form-control mt-1
        ${required1 ? 'border-danger form-control is-invalid' : ''}`}
        name="certificationCode"
        value={certificationCode}
        onChange={(e) => setCertificationCode(e.target.value)}
        placeholder="Certification Code"
      /> */}
      <select
        name="Timezone"
        className={`form-control mt-1
        ${required1 ? 'border-danger form-control is-invalid' : ''}`}
        value={selectTimezone}
        onChange={(e) => setTimezone(e.target.value)}>
        <option defaultValue value="" disabled>
          Select your Timezone
        </option>
        {tz.map((e, index) => (
          <option value={e.text} key={index}>
            {e.text}
          </option>
        ))}
      </select>

      {passwordValidate ? (
        <small className="badge badge-danger" style={{ fontSize: 'x-small' }}>
          Password and Retype Password should same
        </small>
      ) : (
        ''
      )}

      <small style={{ fontSize: '1.3vh' }}>
        <strong>IMPORTANT:</strong>
        Access to games is based on your timezone
      </small>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          handleGoogleSignup(credentialResponse);
        }}
        onError={() => {
          ShowToast('Failed to signup', 'error');
        }}
      />
    </>
  );
};
AuthSignUp.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  retypePassword: PropTypes.string.isRequired,
  setRetypePassword: PropTypes.func.isRequired,
  required1: PropTypes.bool.isRequired,
  selectTimezone: PropTypes.string.isRequired,
  setTimezone: PropTypes.func.isRequired,
  setCertificationCode: PropTypes.func.isRequired,
  certificationCode: PropTypes.string.isRequired,
  passwordInputRef: PropTypes.objectOf(PropTypes.any).isRequired,
  confirmPasswordInputRef: PropTypes.objectOf(PropTypes.any).isRequired,
  passwordValidate: PropTypes.bool.isRequired,
};
export default AuthSignUp;
