import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import DesignPage from '../../../pages/design/design.page';

const calculateTotalMinutes = (secDays) => {
  return ((secDays * 365) / 60).toFixed(2);
};

const Setup = ({
  selectedRound,
  credits,
  days,
  secDays,
  offers,
  offersRejection,
  mov,
  setSelectedRound,
  setSelectCredits,
  setSelectDays,
  setSelectSecDays,
  setOffers,
  setOffersRejection,
  setMov,
  handleSelectedRound,
}) => {
  useEffect(() => {}, [selectedRound]);
  // useEffect(() => {
  //   return localStorage.removeItem('gameSettingClicked');
  // }, []);
  return (
    <div className="setup">
      <h1 style={{marginTop:"20px"}}>Edit Setup</h1>
      <p>
        <b>IMPORTANT:</b> These settings are loaded into the game at the start of a game session. 
        To reflect changes into a game session, <br />save changes here before launching the game. 
        <Link to='/design/add/new'> <u><b>Create a Program</b></u></Link> (using credits) to use Advanced Settings. 
      </p>
      <form className="p-4">
        <div className="form-group">
          <label htmlFor="temp-id"><b>Select Round</b></label>
          <div className="tooltips">
            <img 
            src="https://img.icons8.com/6d49f5/80/000000/help.png" 
            alt='question' 
            style={{width: "20px", paddingLeft:"5px"}} />
            <span 
              className="tooltiptext" 
              style={{borderRadius: "5px", width: "400px", textAlign: "left", padding: "5px"}}>
              Think of a round as collection of pre-set game configurations. You can 
              edit &apos;Easy&apos;, &apos;Medium&apos; and &apos;Hard&apos; settings to match 
              the requirement for an Evivve session you may be delivering. You 
              cannot edit the &apos;Demo&apos; settings because &apos;Demo&apos; is part of 
              the free tier. When you use an Evivve credit to create a program, you will have 
              access to all of the advanced/non-demo options along with &apos;Game Controls&apos;
              when running the game using the Admin Panel.
              <br /><br />
              We have created standard &apos;Easy&apos;, &apos;Medium&apos; and &apos;Hard&apos; 
              settings which work for most teams. Please understand the changes you&apos;re making 
              before making them. 
            </span>
          </div>
          <select
            className="form-control"
            name="selectedRound"
            value={selectedRound}
            onChange={(e) => {
              setSelectedRound(e.target.value);
              handleSelectedRound(e.target.value);
            }}>
            <option selected value="easy">
              Easy (Advanced Settings)
            </option>
            <option value="medium">Medium (Advanced Settings)</option>
            <option value="hard">Hard (Advanced Settings)</option>
            <option value="demo">Demo (Free)</option>
          </select>
        </div>

        <div className="form-group">
        <div className="form-row">
          <div className="col-md">
            <label htmlFor="temp-id"><b>Starting Credits</b></label>
            <div className="tooltips">
              <img 
                src="https://img.icons8.com/6d49f5/80/000000/help.png" 
                alt='question' 
                style={{width: "20px", paddingLeft:"5px"}} />
              <span 
                className="tooltiptext" 
                style={{borderRadius: "5px", width: "400px", textAlign: "left", padding: "5px"}}>
                The number of credits players start out with in the game. <br /><br />
                <b>Pro Tip:</b> Increase this number 
                to make the game easier.
              </span>
            </div>
            <input
              type="number"
              className="form-control"
              name="credits"
              value={credits}
              disabled={selectedRound==="demo"}
              onChange={(e) => {
                setSelectCredits(e.target.value);
              }}
              placeholder="Credits"
            />
          </div>
          <div className="col-md">
            <label htmlFor="temp-id"><b>Number of Days </b></label>
            <div className="tooltips">
              <img 
                src="https://img.icons8.com/6d49f5/80/000000/help.png" 
                alt='question' 
                style={{width: "20px", paddingLeft:"5px"}} />
              <span 
                className="tooltiptext" 
                style={{borderRadius: "5px", width: "400px", textAlign: "left", padding: "5px"}}>
                The number of game days the game round will play for. For most cases, we 
                recommend running a full game of 365 days. The game is balanced across time, 
                meaning: the game&apos;s algorithm changes as the game days pass. 
                <br /><br />
                <b>Pro Tip:</b> If you wish to create a break in a full game session, you may run 
                one 365 day game and stop it at day 182 for a debrief. Then run another game with 
                182 days to simulate splitting the year in half. IMPORTANT: The second game of 
                182 day will be a fresh game without the ability to save/load previous 
                game&apos;s progress. 
              </span>
            </div>
            <input
              type="number"
              className="form-control"
              name="days"
              value={days}
              disabled={selectedRound==="demo"}
              onChange={(e) => {
                setSelectDays(e.target.value);
              }}
              placeholder="Days"
            />
          </div>
          <div className="col-md">
            <label htmlFor="temp-id"><b>Seconds per Day </b></label>
            <div className="tooltips">
              <img 
                src="https://img.icons8.com/6d49f5/80/000000/help.png" 
                alt='question' 
                style={{width: "20px", paddingLeft:"5px"}} />
              <span 
                className="tooltiptext" 
                style={{borderRadius: "5px", width: "400px", textAlign: "left", padding: "5px"}}>
                The duration of each game day. This directly influences the total duration of 
                the game. <br /><br /> 
                <b>Pro tip:</b> Increase this number to make the game easier. 
                <br /><br />
                Example: A value of 5 in this field signifies that each game day will last 
                for 5 seconds. 
              </span>
            </div>
            <input
              type="number"
              className="form-control"
              name="sec_days"
              disabled={selectedRound==="demo"}
              value={secDays}
              onChange={(e) => {
                setSelectSecDays(e.target.value);
              }}
              placeholder="Seconds"
            />
          </div>
        </div>
        </div>
        
        <div className="form-group">
        <div className="form-row">
          <div className="col-md">
            <label htmlFor="temp-id" style={{marginBottom: "0px"}}><b>Total Game Duration</b>
            </label>
            <div className="tooltips">
              <img 
                src="https://img.icons8.com/6d49f5/80/000000/help.png" 
                alt='question' 
                style={{width: "20px", paddingLeft:"5px"}} />
              <span 
                className="tooltiptext" 
                style={{borderRadius: "5px", width: "400px", textAlign: "left", padding: "5px"}}>
                The total duration of the game. This value does not include disaster time. 
                Each disaster runs for 75 seconds. 
              </span>
            </div>
            <input
              type="number"
              readOnly
              className="form-control-plaintext"
              name="total-minutes"
              value={calculateTotalMinutes(secDays)}
              placeholder="Total Minutes"
            />
          </div>
        </div>
        </div>
        
        <div className="form-group">
        <div className="form-row">
          <div className="col-md">
            <label htmlFor="temp-id"><b>Offer Frequency</b> (in game days)</label>
            <div className="tooltips">
              <img 
                src="https://img.icons8.com/6d49f5/80/000000/help.png" 
                alt='question' 
                style={{width: "20px", paddingLeft:"5px"}} />
              <span 
                className="tooltiptext" 
                style={{borderRadius: "5px", width: "400px", textAlign: "left", padding: "5px"}}>
                The rate at which each offer shows up after being accepted. 
                <br /><br />
                <b>Pro Tip:</b> Decrease this number to make the game easier. 
                <br /><br />
                Example: A value of 5 in this field signifies that an offer will arrive at the  
                passing of the 5th game day. This also means that in a game of 365 days, this 
                setting will cause there to be roughly 70 offers. &apos;Roughly&apos; because each 
                next offer is generated based on when the previous one was accepted. 
              </span>
            </div>
            <input
              type="number"
              className="form-control"
              name="offers"
              value={offers}
              disabled={selectedRound==="demo"}
              onChange={(e) => {
                setOffers(e.target.value);
              }}
              placeholder="Gaps"
            />
          </div>
          <div className="col-md">
            <label htmlFor="temp-id"><b>Offer Frequency After &apos;Decline&apos;</b></label>
            <div className="tooltips">
              <img 
                src="https://img.icons8.com/6d49f5/80/000000/help.png" 
                alt='question' 
                style={{width: "20px", paddingLeft:"5px"}} />
              <span 
                className="tooltiptext" 
                style={{borderRadius: "5px", width: "400px", textAlign: "left", padding: "5px"}}>
                Players may choose to &apos;Decline&apos; an offer. This regulates how soon the 
                next offer will arrive. An offer is considered to be &apos;Declined&apos; when at 
                least 50% of the players decline the offer on their respective devices. 
                <br /><br />
                <b>Pro Tip: </b>
                As a facilitator, you may choose to reward or penalize this behavior. You might 
                decide based on your program&apos;s design. If you don&apos;t wish to leverage 
                this mechanic, make this value the same as &apos;Offer Frequency&apos;
              </span>
            </div>
            <input
              type="number"
              className="form-control"
              name="offers_rejection"
              value={offersRejection}
              disabled={selectedRound==="demo"}
              onChange={(e) => {
                setOffersRejection(e.target.value);
              }}
              placeholder="Offers"
            />
          </div>
          <div className="col-md">
            <label htmlFor="temp-id"><b>Movilennium Value</b></label>
            <div className="tooltips">
              <img 
                src="https://img.icons8.com/6d49f5/80/000000/help.png" 
                alt='question' 
                style={{width: "20px", paddingLeft:"5px"}} />
              <span 
                className="tooltiptext" 
                style={{borderRadius: "5px", width: "400px", textAlign: "left", padding: "5px"}}>
                The crono value of Movilennium in the game. This value can be seen in the 
                market where players can buy Movilennium by paying cash for it.  <br /><br /> 
                <b>Pro Tip: </b>Decrease this number to make the 
                game easier. 
              </span>
            </div>
            <input
              type="number"
              className="form-control"
              name="mov"
              value={mov}
              disabled={selectedRound==="demo"}
              onChange={(e) => {
                setMov(e.target.value);
              }}
              placeholder="Movilennium "
            />
          </div>
        </div>
        </div>
      </form>
      {/* {localStorage.removeItem('gameSettingClicked')} */}
    </div>
  );
};

Setup.propTypes = {
  selectedRound: PropTypes.number,
  credits: PropTypes.number,
  days: PropTypes.number,
  secDays: PropTypes.number,
  offers: PropTypes.number,
  offersRejection: PropTypes.number,
  mov: PropTypes.number,
  setSelectedRound: PropTypes.func,
  setSelectCredits: PropTypes.func,
  setSelectDays: PropTypes.func,
  setSelectSecDays: PropTypes.func,
  setOffers: PropTypes.func,
  setOffersRejection: PropTypes.func,
  setMov: PropTypes.func,
  handleSelectedRound: PropTypes.func,
};

Setup.defaultProps = {
  selectedRound: '',
  credits: '',
  days: '',
  secDays: '',
  offers: '',
  offersRejection: '',
  mov: '',

  setSelectedRound: '',
  setSelectCredits: '',
  setSelectDays: '',
  setSelectSecDays: '',
  setOffers: '',
  setOffersRejection: '',
  setMov: '',
  handleSelectedRound: '',
};

export default Setup;
