/* eslint-disable */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setProgramData } from '../../actions/program.action';
import { useHistory } from 'react-router-dom';
import styles from './editDesign.module.scss';
import TitleComponent from '../../components/title/title.component';
import AddProgramDetails from '../../components/addProgramDetails/addProgramDetails.component';
import CardComponent from '../../components/card/card.component';
import CardFooterComponent from '../../components/container-buttons/cardFooter.component';

const EditDesignPage = () => {
  // const dispatch = useDispatch();

  // const programName = useSelector((state) => state.program.name);
  // const programDescription = useSelector((state) => state.program.description);
  // const gameAccess =
  //   useSelector((state) => state.program.gameAccess) === true ? 'FULL DAY' : 'HALF DAY';

  const history = useHistory()

  const [name, setName] = useState('programName');
  const [description, setDescription] = useState('programDescription');

  const onPreviousClick = () => {
    history.push('/design');
  };
  const onNextClick = () => {
    // dispatch(setProgramData({ name, description }));
    history.push('/schedule');
  };
  return (
    <>
      <div className={`w-75 pt-3 ${styles.title}`}>
        <TitleComponent
          title="Home/ Edit Design"
          heading="Edit Design"
          subtitle="Edit details about your program"
        />
      </div>
      <div className={`container ${styles['box-shadow']}`}>
        <CardComponent>
          <>
            <AddProgramDetails
              h5="Program Details"
              h6="Program Name"
              input={
                <div>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              }
              p="Don’t worry, you can change it anytime later"
            />
            <div className="border" />
            <AddProgramDetails
              h6="Program Description"
              input={
                <div>
                  <textarea
                    type="textarea"
                    className="form-control"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    id="exampleFormControlTextarea1"
                    rows="3"
                  />
                </div>
              }
              p="Describe this program in 140 characters, something like: A program designed for
              Deloitte’s senior management with a focus on Dealing with VUCA. Try to be brief
              but relevant."
            />
            <div className="border" />

            <div className="row mt-3">
              <div className="col-2">
                <strong>Game Access</strong>
              </div>
              <div className="col-5">{'gameAccess'}</div>
              <div className="pl-5 col float-right">
                <p style={{ paddingLeft: '60px' }}>
                  You cannot change the game access of this program.
                </p>
              </div>
            </div>
            <CardFooterComponent
              previous="Previous"
              next="Continue"
              onPreviousClick={onPreviousClick}
              onNextClick={onNextClick}
            />
          </>
        </CardComponent>
      </div>
    </>
  );
};
EditDesignPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default EditDesignPage;
