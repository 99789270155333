/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useHistory, Link } from 'react-router-dom';
import { useProgramStore, useCreditStore } from '../../store';
import { useGetCreditsByUserId } from '../../services';
import { GameAccessEnum } from '../../utils';
import styles from './complete.module.scss';
import TitleComponent from '../../components/title/title.component';
import Complete from '../../assets/complete/complete.png';
import CardComponent from '../../components/card/card.component';

const CompletePage = () => {
  const history = useHistory()
  useGetCreditsByUserId();
  const program = useProgramStore((s) => s.program);

  useEffect(() => {
    if (!program.name) {
      history.push('/design/add/new');
    }
  }, [history, program.name]);

  const halfCr = useCreditStore((s) => s.half_cr);
  const fullCr = useCreditStore((s) => s.full_cr);

  const onNextClick = () => {
    history.push('/my-programs');
  };
  return (
    <>
      <div className={`w-75 pt-3   ${styles.title}`}>
        <TitleComponent title="Home/ New program" heading="Complete" subtitle="You’re all set!" />
      </div>

      <div className={`container ${styles['box-shadow']}`}>
        <CardComponent>
          <>
            <h5 className="font-weight-bold pl-4"> Program Details </h5>

            <div className="ml-4">
              <h6>
                Program name :<strong>{program.name}</strong>
              </h6>

              <h6>
                Scheduled date and time :
                <strong>{`${dayjs(program.date).format('DD-MMM-YYYY')}, ${program.time}`}</strong>
              </h6>

              <h6>
                Game Access :
                <strong>
                  {program.game_access === GameAccessEnum.HALF ? 'HALF DAY' : 'FULL DAY'}
                </strong>
              </h6>
              <h6>
                Number of Game Accesses :<strong>{program.fac_count}</strong>
              </h6>
              <h6>
                Facilitators:
                <strong>
                  {program.facilitators
                    ? program.facilitators.map((d) => (
                        <React.Fragment key={d.value}> {d.label} </React.Fragment>
                      ))
                    : null}
                </strong>
              </h6>
            </div>

            <div className="border" />

            <h6 style={{ fontSize: 'smaller' }} className="text-center pt-1">
              {`You have used Half-Day Credit. You have ${halfCr} Half-Day Credit and ${fullCr} Full-Day Credits
              remaining.`}
            </h6>

            <div className="row">
              <div className="col-md-4 col-sm-12 mx-auto text-center">
                <img src={Complete} alt="complete-icon" />
                <h2>
                  <strong>You’re all set!</strong>
                </h2>
              </div>
            </div>
            <h6 style={{ fontSize: 'smaller' }} className="text-center">
              You&apos;ll have access to advanced features 30 minutes before your scheduled time.
              <br />You can view and edit your advanced features and game configurations for 
              &apos;Easy&apos;, &apos;Medium&apos; and &apos;Hard&apos; rounds at:  
              <Link to="/settings"><br /><b>Settings &gt; Game Settings.</b><br /></Link>
            </h6>

            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <button
                    type="button"
                    onClick={onNextClick}
                    className="btn btn-secondary"
                    style={{ width: '132px' }}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </>
        </CardComponent>
      </div>
    </>
  );
};
CompletePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CompletePage;
