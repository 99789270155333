/* eslint-disable camelcase */
/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import BuyCreditComponent from '../../components/buyCredits/buyCredits.component';
import CardComponent from '../../components/card/card.component';
import TitleComponent from '../../components/title/title.component';
import { useCreditStore, useProgramStore } from '../../store';
import { DateFunction, GameAccessEnum, isMobile } from '../../utils';
import styles from './confirm.module.scss';
import { useGetCreditsByUserId } from '../../services';
import ShowToast from '../../components/ShowToast';
import GstModal from '../../components/gstModal/gstModal';

const ConfirmPage = () => {
  const history = useHistory();
  const [date, setDate] = useState();
  const [showModal, setShowModal] = useState('');
  const [check, setCheck] = useState(false);
  const [gstNum, setGstNum] = useState('');
  const [stateList, setStateList] = useState('');
  const [radioInput, setRadioInput] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const [showBuyCredit, setBuyCredit] = useState(false);
  const [warning, setWarning] = useState(false);

  const handleClick = () => setBuyCredit(!showBuyCredit);
  const onHeadingClick = () => {
    if (showBuyCredit) {
      setBuyCredit(false);
    }
  };
  const updatedData = useGetCreditsByUserId();
  const program = useProgramStore((s) => s.program);
  const half_cr = useCreditStore((s) => s.half_cr);
  const full_cr = useCreditStore((s) => s.full_cr);
  const Gstuser = useCreditStore((g) => g.isGst);
  const Gstdate = useCreditStore((g) => g.gstDate);
  const currentDate = new Date(Date.now()).toString();
  console.log('confirm page', half_cr, full_cr);

  useEffect(() => {}, [showBuyCredit, updatedData.isLoading]);

  useEffect(() => {
    if (!program.name) {
      history.push('/design/add/new');
    }
  }, [history, program.name]);

  const onPreviousClick = () => {
    history.push('/schedule/null/null');
  };

  const onNextClick = () => {
    if (program.game_access === GameAccessEnum.FULL) {
      if (full_cr === 0 || full_cr < program.facilitators.length) {
        ShowToast('Insufficient credits, please buy.', 'error');
      } else {
        history.push('/invite/new');
      }
    } else if (half_cr === 0 || half_cr < program.facilitators.length) {
      ShowToast('Insufficient credits, please buy.', 'error');
    } else {
      history.push('/invite/new');
    }
  };
  const diff = DateFunction(currentDate, Gstdate);
  useEffect(() => {
    if (diff > 30) {
      setCheck(false);
      setShowModal(true);
      setDate('');
    }
  }, [diff]);

  return (
    <>
      <div onClick={onHeadingClick}>
        <div className={`w-75 pt-3 ${styles.title}`}>
          <TitleComponent
            title="Home/ New program"
            heading="Confirm"
            subtitle="Confirm your program’s specifics"
          />
        </div>
        <div className={`container ${styles['box-shadow']}`}>
          <CardComponent>
            <>
              <h5 className="font-weight-bold p-2"> Program Details </h5>

              <div className="ml-4">
                <h6>
                  Program name :<strong>{program.name}</strong>
                </h6>

                <h6>
                  Scheduled date and time :
                  <strong>
                    {`${dayjs(program.date).format('DD-MMM-YYYY')}, ${program.time}`}
                    {program.timezone?.slice(0, 11)}
                  </strong>
                </h6>

                <h6>
                  Game Access :
                  <strong>
                    {program.game_access === GameAccessEnum.HALF ? 'HALF DAY' : 'FULL DAY'}
                  </strong>
                </h6>
                <h6>
                  Number of Game Accesses : <strong>{program.fac_count}</strong>
                </h6>
              </div>

              <div className="border m-3" />

              <strong> How would you like to pay?</strong>

              <div className="container-fluid ">
                <div className="row mt-2 ml-3">
                  <div className="col-8">
                    <div className="row mb-3">
                      You currently have {'\u00A0'}
                      <strong>
                        {half_cr}
                        {'\u00A0'}
                        Half-Day Credits
                      </strong>
                      {'\u00A0'}
                      and
                      {'\u00A0'}
                      <strong>
                        {full_cr}
                        {'\u00A0'}
                        Full-Day Credits
                        {'\u00A0'}
                      </strong>
                      remaining from your previous purchase(s).
                    </div>
                    {warning && (
                      <div className="alert alert-warning" role="alert">
                        Please buy credits according to the game access, if half-day access then buy
                        half-day credits and same goes to full-day
                      </div>
                    )}

                    <div className="border" />

                    <div className="row mt-3">Buy bulk credits and unlock discounts</div>
                  </div>
                  <div className="col-4">
                    <div className="row" id='usecredits'>
                      <button
                        type="button"
                        onClick={onNextClick}
                        disabled={half_cr === 0 && full_cr === 0}
                        className={`btn ${styles['credits-button']}
                         `}>
                        Use Credits
                      </button>
                      <div className="border" />
                    </div>
                    <div className="row mt-5 mb-3">
                      <button
                        onClick={() =>
                          !Gstuser || showModal ? setOpenModal(true) : setBuyCredit(!showBuyCredit)
                        }
                        type="button"
                        className={`btn ${styles['credits-button']}`}>
                        Buy Credits
                      </button>
                      <div
                        style={{
                          width: isMobile ? '90%' : '40%',
                        }}
                        className={`${styles['navigation-bar-menu']}
                        ${showBuyCredit ? styles.active : ''}`}>
                        {showBuyCredit && (
                          <BuyCreditComponent
                            halfCr={half_cr}
                            fullCr={full_cr}
                            radioInput={radioInput}
                            stateList={stateList}
                            gstNum={gstNum}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3">
                <button type="button" className="btn btn-secondary" onClick={onPreviousClick}>
                  Previous
                </button>
              </div>
            </>
          </CardComponent>
        </div>
      </div>
      <GstModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setShowBuyCredit={setBuyCredit}
        showBuyCredit={showBuyCredit}
        radioInput={radioInput}
        setRadioInput={setRadioInput}
        stateList={stateList}
        setStateList={setStateList}
        gstNum={gstNum}
        setGstNum={setGstNum}
        setCheck={setCheck}
        check={check}
        date={date}
        setDate={setDate}
      />
    </>
  );
};

ConfirmPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ConfirmPage;
